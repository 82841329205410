import React, { useContext, useEffect, useRef, useState } from 'react';
import type { FormInstance, InputRef } from 'antd';
import { Form, Input, Table } from 'antd';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { EditableCell, EditableCellRules } from './EditableCell';

const EditableContext = React.createContext<FormInstance<any> | null>(null);


interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};


type EditableTableProps = Parameters<typeof Table>[0];


type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
export type EditableColumnTypes = (
  ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    children?: EditableColumnTypes[];
    rules?: EditableCellRules;
  })

interface EditableTableInterface {
  data: any[];
  columns: EditableColumnTypes[];
  handleSave: Function;
  errorRecords?: any[];
  allowEmptySave?: boolean;
}

const EditableTable: React.FC<EditableTableInterface> = (props) => {
  const [columns, setColumns] = useState<any>([]);
  const [components, setComponents] = useState<any>({
    body: {
      row: EditableRow,
      cell: props?.data?.length > 0 ? EditableCell : undefined,
    },
  });

  useEffect(() => {
    setComponents({
      body: {
        row: EditableRow,
        cell: props?.data?.length > 0 ? EditableCell : undefined,
      },
    });
  }, [props?.data]);

  useEffect(() => {
    if (EditableContext === null) return;
    setColumns(addOnCellToCols(props.columns));
  }, [props.columns]);

  const addOnCellToCols = (record: any[]) => {
    return record.map((col) => {
      if (!col.editable) {
        if (col.children) col.children = addOnCellToCols(col.children);
        return {
          ...col,
          onCell: (record: any) => ({ EditableContext: EditableContext }),
        }
      }
      return {
        ...col,
        onCell: (record: any, index: number) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: (row: any, dataIndex: string) => props.handleSave(row, dataIndex),
          EditableContext: EditableContext,
          rules: col.rules,
          errorRecord: props.errorRecords && props.errorRecords[index],
          allowEmptySave: props.allowEmptySave
        }),
      };
    });
  }


  return (
    <div>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={props?.data}
        columns={columns as ColumnTypes}
        pagination={false}
      />
    </div>
  );
};

export default EditableTable;