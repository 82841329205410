import * as React from 'react';
import { BEDrawer } from '../../../Components/BEDrawer';
import { Form } from 'antd';
import { BEButton } from '../../../Components/BEFormItems/BEButton';
import { BEInput } from '../../../Components/BEFormItems/BEInput';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { BETextArea } from '../../../Components/BEFormItems/BETextArea';
import { addBusinessGroup, deleteBusinessGroup } from '../../Actions/BusinessGroupActions';
import { LableRequired } from '../../../Components/BEFormItems/LableRequired';
import { editBusinessGroup } from '../../Actions/BusinessGroupActions';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import { BETable } from '../../../Components/BETable';
import { getAllUsers } from '../../Actions/UsersManagementActions';
import useTreeSelectStyle from 'antd/es/tree-select/style';
import BEMultipleSelect from '../../../Components/BEMultipleSelect';
import DeleteIcon from '../../../Components/BEIcons/DeleteIcon';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { AddbusinessUnitToGroup } from './AddBusinessUnitToGroup';
import { postBusinessUnitRef, deleteBusinessUnit, deleteBusinessUnitRef } from '../../Actions/BusinessUnitActions';
import { BEMessage } from '../../../Components/BEMessage';
import BEConfirmModal from '../../../Components/BEConfirmModal';

export interface IAddBusinessGroupProps {
  open: boolean;
  setOpen: Function;
  isEdit?: boolean;
  businessGroup?: any;
}

export function AddBusinessGroup(props: IAddBusinessGroupProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);
  const allUsers = useTypedSelector(state => state.userMgmt);
  const [openAddBusinessUnit, setOpenAddBusinessUnit] = React.useState(false);
  const [selectedBusinessUnits, setSelectedBusinessUnits] = React.useState<any>([]);
  const businessUnitRefs = useTypedSelector(state => state.entity.BusinessUnitRefs);
  const businessGroups = useTypedSelector(state => state.entity.BusinessGroups?.data);
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);

  React.useEffect(() => {
    if (props.isEdit) {
      setSelectedBusinessUnits(businessUnitRefs?.data.filter((item: any) => item.business_group === props.businessGroup.id).map((item: any) => item.business_unit))
    }
  }, [props.open]);

  const uniqueBGNameValidator = async (rule: any, value: any) => {
    if (props.isEdit && value?.toLowerCase() === props.businessGroup.name?.toLowerCase()) return;
    if (businessGroups?.find((item: any) => item.name?.toLowerCase() === value?.toLowerCase())) {
      throw new Error('Business Group Name already exists');
    }
  }

  const onFinish = async (values: any) => {
    if (props.isEdit) {
      setLoading(true);
      // let busToDelete = businessUnitRefs?.data.filter((item: any) => item.business_group === props.businessGroup.id && !selectedBusinessUnits.includes(item.business_unit));
      let busToAdd = selectedBusinessUnits.filter((item: any) => !businessUnitRefs?.data?.find((item2: any) => item2.business_group === props.businessGroup.id && item2.business_unit === item))
      await Promise.all(
        [
          //   ...busToDelete.map(async (item: any) => {
          //   await dispatch(deleteBusinessUnit(item.id));
          // }),
          await dispatch(editBusinessGroup(props.businessGroup.id, values)),
          busToAdd.map(async (item: any) => {
            await dispatch(postBusinessUnitRef({
              business_group: props.businessGroup.id,
              business_unit: item
            }))
          })
        ]
      )
      BEMessage.success('Business Group Updated Successfully');
      setLoading(false);
      form.resetFields();
      setSelectedBusinessUnits([]);
      props.setOpen(false);
      return;
    }
    setLoading(true);
    let res = await dispatch(addBusinessGroup(values));
    if (res) {
      let dataArrey: any = []
      selectedBusinessUnits.map((item: any) => {
        dataArrey.push({
          business_group: res.id,
          business_unit: item
        })
      })
      await Promise.all(
        [
          ...dataArrey.map(async (item: any) => {
            await dispatch(postBusinessUnitRef(item));
          })
        ]
      )
      BEMessage.success('Business Group Added Successfully');
      setLoading(false);
      form.resetFields();
      setSelectedBusinessUnits([]);
      props.setOpen(false);
    }
  }

  React.useEffect(() => {
    if (props.isEdit) {
      form.setFieldsValue({
        name: props.businessGroup.name,
        description: props.businessGroup.description,
        owner: props.businessGroup.owner
      })
    }
  }, [props.isEdit])

  const handleDelete = async (id: any) => {
    if (props.isEdit) {
      if (businessUnitRefs?.data?.find((item: any) => item.business_group === props.businessGroup.id && item.business_unit === id)?.id)
        await dispatch(deleteBusinessUnitRef(
          businessUnitRefs?.data?.find((item: any) => item.business_group === props.businessGroup.id && item.business_unit === id)?.id
        ));
    }
    setSelectedBusinessUnits(selectedBusinessUnits.filter((item: any) => item !== id));
  }

  const handleDeleteGroup = async () => {
    setDeleteLoading(true);
    if (!businessUnitRefs?.data.filter((item) => item.business_group === props.businessGroup.id)?.length) {
      await dispatch(deleteBusinessGroup(props.businessGroup.id));
    } else {
      BEMessage.error("Business units must be deleted before deleting a business group.");
    }
    setDeleteLoading(false);
  }

  return (
    <div>
      <BEDrawer
        heading={props.isEdit ? 'Edit Business Group' : 'Add Business Group'}
        open={props.open}
        setOpen={props.setOpen}
        width='400'
        footer={
          <div>
            <BEButton size='large' className='primary' loading={loading}
              onClick={() => {
                form.submit();
              }}
            >
              Save
            </BEButton>
            <BEButton
              style={{ marginLeft: '0.5rem' }} size='large' onClick={() => { props.setOpen(false) }}>
              Cancel
            </BEButton>
            {
              props.isEdit &&
              <BEButton
                style={{ marginLeft: '0.5rem' }} size='large' // loading={deleteLoading}
                onClick={async () => {
                  setOpenConfirmDelete(true);
                }}
                danger={true}
              >
                Delete
              </BEButton>
            }

          </div>
        }
      >
        <Form form={form} onFinish={onFinish} >
          <LableRequired>Name</LableRequired>
          <Form.Item name='name' rules={[
            { required: true, message: 'Please enter business group name with minimum 3 characters.' , min: 3},
            { validator: uniqueBGNameValidator }
          ]}>
            <BEInput placeholder='Business Group Name' />
          </Form.Item>

          <p>Description</p>
          <Form.Item name='description' >
            <BETextArea rows={4} form={form} placeholder='Business Group Description' />
          </Form.Item>

          <LableRequired>Owner Name</LableRequired>
          <BEMultipleSelect loading={allUsers?.status === 'loading'}
            form={form} name='owner' rules={[{ required: true, message: 'Please select owner' }]}
            data={allUsers?.data.filter((item: any) => item.role !== 'CONTRIBUTOR').
              map((item: any) => {
                return ({
                  id: item.id,
                  name: item.first_name + ' ' + item.last_name
                })
              })
            }
            labelFeild='name' valueFeild='id' single />

        </Form>
        <p style={{ fontSize: '0.9rem' }}>Business Units</p>
        <BEButton style={{float: "right", marginBottom: "1rem"}} onClick={() => setOpenAddBusinessUnit(true)} className='primary'>+ Add Business Unit</BEButton>
        <BETable
          data={
            businessUnits?.data.filter((item: any) => selectedBusinessUnits.includes(item.id))
          }
          columns={[
            {
              title: 'S.No',
              render: (item: any, item2: any, index: number) => index + 1
            },
            {
              title: 'Name',
              dataIndex: 'name'
            },
            {
              title: 'Action',
              render: (text: any, item2: any, index: number) => (
                <div style={{ height: '1rem' }}
                  onClick={() => {
                    handleDelete(item2.id);
                  }}
                >
                  <DeleteIcon inheritSize stroke={PrimaryTheme.primaryGray} />
                </div>
              )
            }
          ]}
        />
        <AddbusinessUnitToGroup
          isEdit={props?.isEdit ? true : false}
          open={openAddBusinessUnit}
          setOpen={setOpenAddBusinessUnit}
          businessGroup={props.businessGroup}
          setCheckedBusinessUnits={setSelectedBusinessUnits}
          checkedBusinessUnits={selectedBusinessUnits}
        />
        < BEConfirmModal
          visible={openConfirmDelete}
          setVisible={setOpenConfirmDelete}
          title='Delete Business Group'
          message='Are you sure you want to delete this Business Group?'
          onConfirm={async () => {
            await handleDeleteGroup();
          }}
          type='error'
        />

      </BEDrawer>

    </div>
  );
}
