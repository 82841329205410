import API from "../../API";

export const GET_SCOPE_1_CONFIGURATIONS = async () => {
    return await API.GET("/cleopatra/scope1-configurations/");
}

export const GET_SCOPE_2_CONFIGURATIONS = async () => {
    return await API.GET("/cleopatra/scope2-configurations/");
}

export const EDIT_SCOPE_1_CONFIGURATION = async (id: number, data: any) => {
    return await API.PATCH(`/cleopatra/scope1-configurations/${id}/`, data);
}

export const EDIT_SCOPE_2_CONFIGURATION = async (id: number, data: any) => {
    return await API.PATCH(`/cleopatra/scope2-configurations/${id}`, data);
}


export const GET_SCOPE_1_CALCULATIONS = async (startDate:string, endDate:string) => {
    return await API.GET(
      `/cleopatra/scope1-emissions-calculations/?start_date=${startDate}&end_date=${endDate}`
    );
}

export const GET_SCOPE_2_CALCULATIONS = async (startDate:string, endDate:string) => {
    return await API.GET(
      `/cleopatra/scope2-emission-calculations/?start_date=${startDate}&end_date=${endDate}`
    );
}

export const GET_SCOPE_1_CALCULATIONS_BY_BU = async (id:number, startDate:string, endDate:string) => {
    return await API.GET(
      `/cleopatra/scope1-emissions-calculations/${id}/?start_date=${startDate}&end_date=${endDate}`
    );
}

export const GET_SCOPE_2_CALCULATIONS_BY_BU = async (id: number, startDate:string, endDate:string) => {
  return await API.GET(
    `/cleopatra/scope2-emission-calculations/${id}/?start_date=${startDate}&end_date=${endDate}`
  );
};

export const GET_SCOPE_1_CALCULATIONS_BY_BG = async (startDate:string, endDate:string) => {
    return await API.GET(`/cleopatra/scope1-emissions-calculations-by-group/?start_date=${startDate}&end_date=${endDate}`);
}

export const GET_SCOPE_2_SPECIFICATIONS = async () => {
    return await API.GET("/cleopatra/scope2-emission-factors/");
}

export const GET_SCOPE_1_CALCULATIONS_FOR_MONTHS = async (startDate:string, endDate:string) => {
    return await API.GET(`/cleopatra/scope1-emissions-calculations-by-month/?start_date=${startDate}&end_date=${endDate}`);
}

export const GET_SCOPE_2_CALCULATIONS_FOR_MONTHS = async (startDate:string, endDate:string) => {
    return await API.GET(`/cleopatra/scope2-emissions-calculations-by-month/?start_date=${startDate}&end_date=${endDate}`);
}

export const RESET_SCOPE_1_CONFIGURATIONS_BY_ID = async (id:number) => {
    return await API.PATCH("/cleopatra/set-scope1-emission-to-default/"+id+"/");
}

export const GET_SCOPE_1_AND_2_EMISSION_CALCULATIONS = async (year:number) => {
    return await API.GET(`/cleopatra/emission-energy-data/?year=${year}`);
}
  
export const GET_SCOPE_3_EMISSION_CALCULATIONS = async (year:number) => {
    return await API.GET(`/zero/zero-emission/${year}/`);
}

export const GET_SCOPE_2_CUSTOM_EMISION_FACTORS = async () => {
    return await API.GET("/cleopatra/scope-2-custom-emission-factors/");
}

export const POST_SCOPE_2_CUSTOM_EMISION_FACTORS = async (data:any) => {
    return await API.POST("/cleopatra/scope-2-custom-emission-factors/", data);
}

export const DELETE_SCOPE_2_CUSTOM_EMISION_FACTORS = async (id:number) => {
    return await API.DELETE(`/cleopatra/scope-2-custom-emission-factors/${id}/`);
}

export const PATCH_SCOPE_2_CUSTOM_EMISION_FACTORS = async (id:number, data:any) => {
    return await API.PATCH(`/cleopatra/scope-2-custom-emission-factors/${id}/`, data);
}

export const GET_SCOPE_2_EMISION_CONFIGURATIONS = async () => {
    return await API.GET("/cleopatra/scope-2-configurations/");
}

export const PATCH_SCOPE_2_EMISION_CONFIGURATIONS = async (id:number, data:any) => {
    return await API.PATCH(`/cleopatra/scope-2-configurations/${id}/`, data);
}
    