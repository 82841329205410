import * as React from 'react';
import './styles.scss';
interface ITotalMetricsSummaryProps {
	totalValue: number;
	unit: string;
	approvedValue: number;
}

export function TotalMetricsSummary (props: ITotalMetricsSummaryProps) {
	return (
	  <div className='mob-total-metric-summary'>
		<div className='total-value'>
			<h2>{props.totalValue} {props.unit}</h2> 
			<p>Total Computed value</p>
		</div>
		<div className='approved-value'>
			<h2>{props.approvedValue} {props.unit}</h2> 
			<p>Approved Value</p>
		</div>
	  </div>
	);
}
