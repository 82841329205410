import { useEffect, useState } from "react";
import DonutCharts from "../../../../../../../../../../../Components/BECharts/DonutCharts";
import { Tooltip } from "antd";
import { Legend } from "recharts";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { AddCommaToNumber } from "../../../../../../../../../../../Config/Functions/UsefullFunctions";
import { graphColors } from "../../../../../../../../../../../Config/Theme/graphColors";
import { PrimaryTheme } from "../../../../../../../../../../../Config/Theme/theames";

interface BulkUploadDonutChartProps {
	acceptedDataCount: number;
	rejectedDataCount: number;
}

const BulkUploadDonutChart = (props: BulkUploadDonutChartProps) => {
	const [data,setData]=useState<any[]>([
		{ name: 'ACCEPTED', value: {data:0,total:0} },
		{ name: 'REJECTED', value: {data:0,total:0} }
	])
	const [totalCount,setTotalCount]=useState<number>(0);
	useEffect(()=>{
		const totalCount=props.acceptedDataCount+props.rejectedDataCount
		setData([
			{ name: 'ACCEPTED', value:props.acceptedDataCount },
			{ name: 'REJECTED', value: props.rejectedDataCount }
		])
		setTotalCount(totalCount)
	},[props.acceptedDataCount,props.rejectedDataCount])
	return (
		// <div style={{width:'20%'}}>
		// 	<DonutCharts colorTheme={3} data={[{name:'ACCEPTED',value:75},{name:'REJECTED',value:25}]}/>
		// </div>
		<div style={{ width: '400px', position: 'relative', height: '250px', border: `2px solid ${PrimaryTheme.primaryGreyLight}`, marginTop: '1rem', borderRadius: '8px' }}>
			<h3 style={{ position: 'absolute', left: '1rem', color: PrimaryTheme.primaryGray, margin: '1rem 0rem' }}>Total no of rows: {props.acceptedDataCount+props.rejectedDataCount}</h3>
			<ResponsiveContainer
				width="100%"
				height="100%" >
				<PieChart width={200} height={200} >
					<Pie
						data={data}
						innerRadius={30}
						outerRadius={60}
						fill="#8884d8"
						dataKey="value"
					>
						{data.map((entry: any, index: number) => (
							<Cell key={`cell-${index}`} fill={graphColors[index % graphColors?.length]} />
						))}
					</Pie>
					<Tooltip />
					<Legend layout="vertical" verticalAlign="middle" align="right"
						payload={
							data.map((item: any, index: any) => {
								return {
									value: `${item?.name} : ${item?.value && (item?.value/totalCount)*100}%`,
									type: 'square',
									color: graphColors[index % graphColors?.length]
								}
							})

						}
					/>
				</PieChart>
			</ResponsiveContainer>
		</div>
	)
}

export default BulkUploadDonutChart;