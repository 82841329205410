import { clearStore } from "../ExtraReducrActions";
import { SettingsState } from "../Types/settingsTypes";

import { Reducer, createSlice } from "@reduxjs/toolkit";

const initialState: SettingsState = {
  activeTab: 0,
  notifications: {
    data: [],
    status: "idle",
  },
  logs: {
    data: [],
    status: "idle",
  },
  derivedMetricsRepresentationGraph: {
    data: [],
    status: "idle",
  },
  childDerivedMetricsTobeAdded: [],
  childSelectedMetricsTobeAdded: [],
  integrations: {
    data: [],
    status: "idle",
  },
  systemMapping: {
    data: [],
    status: "idle",
  },
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
  reducers: {
    setSettingsActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setLogs: (state, action) => {
      state.logs = action.payload;
    },
    setDerivedMetricsRepresentationGraph: (state, action) => {
      state.derivedMetricsRepresentationGraph = action.payload;
    },
    setChildDerivedMetricsTobeAdded: (state, action) => {
      state.childDerivedMetricsTobeAdded = action.payload;
    },
    setChildSelectedMetricsTobeAdded: (state, action) => {
      state.childSelectedMetricsTobeAdded = action.payload;
    },
    setIntegrations: (state, action) => {
      state.integrations = action.payload;
    },
    setSystemMapping: (state, action) => {
      state.systemMapping = action.payload;
    }
  },
});

export const {
  setSettingsActiveTab,
  setNotifications,
  setLogs,
  setDerivedMetricsRepresentationGraph,
  setChildDerivedMetricsTobeAdded,
  setChildSelectedMetricsTobeAdded,
  setIntegrations,
  setSystemMapping,
} = userSlice.actions;

const companiesReducer: Reducer<SettingsState> = userSlice.reducer;

export default companiesReducer;
