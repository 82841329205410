import * as React from 'react';
import { BEDrawer } from '../../../../../../../../../Components/BEDrawer';
import CancleIcon from '../../../../../../../../../Components/BEIcons/CancleIcon';
import WarningIcon from '../../../../../../../../../Components/BEIcons/WarningIcon';
import { PrimaryTheme } from '../../../../../../../../../Config/Theme/theames';
import { LableRequired } from '../../../../../../../../../Components/BEFormItems/LableRequired';
import { useForm } from 'antd/es/form/Form';
import { Form, Select } from 'antd';
import { BEButton } from '../../../../../../../../../Components/BEFormItems/BEButton';
import { BETextArea } from '../../../../../../../../../Components/BEFormItems/BETextArea';
import { useAppDispatch } from '../../../../../../../../../Config/Hooks/useAppDispatch';
import { getMyMetricsData, getMyMetricsDataBulkUploadFiles, myMetricDataBulkUpload } from '../../../../../../../Actions';
import { BEMessage } from '../../../../../../../../../Components/BEMessage';


interface IEditFileProps {
	open: boolean;
	setOpen: Function;
	fileId: number;
	my_metric_id: number;
}

export function EditFileDrawer (props: IEditFileProps) {
	const [closeButton,setCloseButton] = React.useState<boolean>(true)
	const [loading, setLoading] = React.useState<boolean>(false);
	const [form] = useForm();
	const dispatch = useAppDispatch();

	const onFinish =async () => {
		setLoading(true);
		const values = form.getFieldsValue();
		console.log(values)
		if(values.status === false){
			const res = await dispatch(myMetricDataBulkUpload(props.my_metric_id, 4, null, null, props.fileId as number, values.reason)).then(() => {
				dispatch(getMyMetricsDataBulkUploadFiles());
				dispatch(getMyMetricsData());
				BEMessage.success('Data saved successfully.')
				props.setOpen(false);
				setLoading(false);
			})
		}
		else{
			setLoading(false);
			props.setOpen(false);
		}
	}

	React.useEffect(()=>{
		form.resetFields();
	},[props.open])
	return (
	  <BEDrawer width='550px' heading='Change Status' setOpen={props.setOpen} open={props.open}
	  footer={
		<>
		  <BEButton className='primary' size='large' loading={loading} onClick={() => form.submit()}
		  >Save</BEButton>
		  <BEButton size='large' onClick={() => props.setOpen(false)}>Cancel</BEButton>
		</>
	  }
	  >
		<>
			<>
			{closeButton && <div style={{display:'flex',alignItems:'start',padding:'0.5rem',backgroundColor:'#FFFAEC',borderRadius:'6px',marginBottom:'1rem'}}>
				<div style={{marginRight:'0.5rem',height:'1.5rem', width:'3.5rem'}}><WarningIcon inheritSize fill={PrimaryTheme.amber}/></div>
				<div>
					<p style={{fontWeight:500,fontSize:'14px',margin:'3px 0px'}} >Warning</p>
					<p style={{fontSize:'12px',fontStyle:'italic',fontWeight:300}}>Once a file is set as inactive, it cannot be reactivated, and all data will be permanently deleted.</p>
				</div>
				<div style={{height:'1rem',cursor:'pointer'}} onClick={()=>setCloseButton(false)}>
					<CancleIcon  inheritSize />
				</div>
			</div>
			}	
			</>
			<Form form={form} layout='vertical' onFinish={onFinish}>
				<LableRequired >
					<span>File Status</span>
				</LableRequired>
				<Form.Item name='status' rules={[{ required: true, message: 'Please select a status' }]}>
					<Select >
						<Select.Option value={false}>Inactive</Select.Option>
					</Select>
				</Form.Item>
				<LableRequired>
					<span>Give Reason</span>
				</LableRequired>
				<Form.Item name='reason' rules={[{ required: true, message: 'Please enter a reason' }]}>
					<BETextArea
								value={form.getFieldValue('reason')}
								placeholder={'Type here'}
								form={form}
								name={'reason'}
								showCount
								maxLength={1000}
								rows={6}
								onChange={(e: any) => {
									form.setFieldValue('reason', e.target.value);
								}}
						/>
				</Form.Item>
			</Form>
		</>
	  </BEDrawer>
	);
}
