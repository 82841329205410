import * as React from 'react';
import CompanyLogoCollapsed from "../../assets/images/Company/Logo.png";
import './index.scss';
import { BuFyDrawer } from '../Drawers/BuFyDrawer';
import { LogoutDrawer } from '../Drawers/LogOutDrawer';
import MobileUserIcon from '../../Components/BEIcons/MobileUserIcon';
import MobileFilterIcon from '../../Components/BEIcons/MobileFilterIcon';

export interface IHeaderProps {
    heading: string;
}

export function Header(props: IHeaderProps) {
    const [openBuFyDrawer, setOpenBuFyDrawer] = React.useState(false);
    const [openLogoutDrawer, setOpenLogoutDrawer] = React.useState(false);
    return (
        <>
            <div className='mobile-header' >
                <img
                    src={CompanyLogoCollapsed}
                    height={"22px"}
                />
                <h2>{props.heading}</h2>
                <div className='mobile-header-icons'>
                    <span onClick={() => setOpenBuFyDrawer(true)}>
                        <MobileFilterIcon />
                    </span>
                    <span onClick={() => setOpenLogoutDrawer(true)} style={{ marginTop: '2px' }}>
                        <MobileUserIcon />
                    </span>
                </div>
            </div>
            <BuFyDrawer open={openBuFyDrawer} setOpen={setOpenBuFyDrawer} />
            <LogoutDrawer open={openLogoutDrawer} setOpen={setOpenLogoutDrawer} />
        </>
    );
}
