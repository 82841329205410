import * as React from 'react';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import ChevronFilled from '../../../../Components/BEIcons/ChevronFilled';
import { ArrowDownIcon } from '../../../../stories/Icon.stories';
import FilledArrow from '../../../../Components/BEIcons/FilledArrowIcon';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import './styles.scss';

interface IDataStatusMessageProps {
	message: string;
	status: boolean;
	resolve?: boolean;
}

export function DataStatusMessage (props: IDataStatusMessageProps) {
	return <div className='datastatus-message-container'>
				<div className='icon-container'>
					<span className='icon' style={{transform: props.status ?'rotate(180deg)':''}}>
						<FilledArrow  fill={props.status ? PrimaryTheme.primaryGreen : PrimaryTheme.primaryRedError}/>
					</span>
				</div>
			<div>
				<p>{props.message}</p>
				{props.resolve && <BEButton size='small' style={{marginTop:'0.5rem'}} className='secondary-green'>Resolve</BEButton>}
			</div>
		</div>
}
