import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import BEMultipleSelect from '../../../../../Components/BEMultipleSelect';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { BETable } from '../../../../../Components/BETable';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { ColumnsType } from 'antd/es/table';
import CrossIcon from '../../../../../Components/BEIcons/CrossIcon';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import BEConfirmModal from '../../../../../Components/BEConfirmModal';
import { deleteContributorsByMetrics, postContributorsByMetrics } from '../../../Actions';

export interface IAssignMetricToContributorProps {
    open: boolean;
    setOpen: Function;
    myMetricId: number;
}

export function AssignMetricToContributor(props: IAssignMetricToContributorProps) {
    const [loading, setLoading] = React.useState(false);
    const [tableData, setTableData] = React.useState<any[]>([]);
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const currentGroup = useTypedSelector(state => state.onBoarding.metrics.currentGroup);
    const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
    const assignedDepartmentByCategory = useTypedSelector(state => state.dataManager.assignedDepartmentByCategory);
    const businessUnitContributors = useTypedSelector(state => state.entity.BusinessUnitContributors);
    const contributorsByMetrics = useTypedSelector(state => state.dataManager.contributorsByMetrics);
    const users = useTypedSelector((state) => state.userMgmt);
    const [unassignedContributors, setUnassignedContributors] = React.useState<any[]>([]);
    const [assignedContributors, setAssignedContributors] = React.useState<any[]>([]);
    const [selectedContributor, setSelectedContributor] = React.useState<any>({}); // for delete
    const dispatch = useAppDispatch();

    const columns: ColumnsType<any> = [
        {
            title: 'Contributor Name',
            dataIndex: 'contributor'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => <>
                <div
                    onMouseEnter={() => setSelectedContributor(record)}
                    style={{ color: PrimaryTheme.primaryGray, cursor: "pointer", height: "1rem", width: "1rem" }}
                    onClick={() => setOpenDelete(true)}
                ><CrossIcon inheritSize stroke={PrimaryTheme.primaryGray} />
                </div>
            </>
        }
    ];

    const handleAddContributor = async (value: any) => {
        setLoading(true);
        await dispatch(postContributorsByMetrics([{ my_metric: props.myMetricId, user: value, business_unit: currentBusinessUnit }]));
        setLoading(false);
    };

    React.useEffect(() => {
        const assignedDepartments = assignedDepartmentByCategory.data.filter((item) => item.category === currentGroup).map((met) => met.department);
        const contributorIds = contributorsByMetrics.data.filter((item) => item.my_metric === props.myMetricId && item.business_unit === currentBusinessUnit).map((item) => item.user);
        const unassignedContributors = users.data.filter((user) => assignedDepartments.includes(user.department)
        && (user.role === "CONTRIBUTOR" && Number(businessUnitContributors.data.find((item) => item.user === user.id)?.business_unit) === currentBusinessUnit )
        && !contributorIds.includes(user.id));
        const assignedContributors = users.data.filter((user) => contributorIds.includes(user.id));
        const tableData = assignedContributors.map((item) => ({
            id: contributorsByMetrics.data.find((contributor) => contributor.user === item.id && contributor.business_unit === currentBusinessUnit)?.id,
            contributor: item.first_name + " " + item.last_name,
            email: item.email,
        }));
        setTableData(tableData);
        setUnassignedContributors(unassignedContributors);
        setAssignedContributors(assignedContributors);
    }, [assignedDepartmentByCategory, contributorsByMetrics, users, props.myMetricId, currentGroup, props.open, currentBusinessUnit]);


    return (
        <BEDrawer
            open={props.open}
            setOpen={props.setOpen}
            heading={"Assign metrics"}
            width='450'
            footer={
                <div>
                    <BEButton
                        size="large"
                        className="primary"
                        onClick={() => {
                            props.setOpen(false);
                        }}
                    >
                        Save and close
                    </BEButton>
                    <BEButton
                        style={{ marginLeft: "0.5rem" }}
                        size="large"
                        onClick={() => {
                            props.setOpen(false);
                        }}
                    >
                        Cancel
                    </BEButton>
                </div>
            }
        >
            <div style={{ fontSize: "0.8rem" }}>

                <LableRequired>
                    Select contributor
                </LableRequired>
                <BEMultipleSelect
                    defaultValue={[]}
                    single
                    disabled={loading || assignedContributors?.length !== 0}
                    isDataStrArray
                    data={unassignedContributors}
                    name="contributor"
                    onChange={(value: any) => {
                        handleAddContributor(value);
                    }}
                    labelFeild='email'
                    valueFeild='id'
                />
                {loading ?
                    <GlobalLoader height='20vh' />
                    :
                    <BETable
                        data={tableData}
                        columns={columns}
                    />
                }
            </div>

            <BEConfirmModal
                title="Confirm Delete"
                message="Are you sure you want to remove the contributor?"
                visible={openDelete}
                setVisible={setOpenDelete}
                onConfirm={async () => {
                    setLoading(true);
                    await dispatch(deleteContributorsByMetrics(selectedContributor.id));
                    setLoading(false);
                }}
            />

        </BEDrawer>
    );
}
