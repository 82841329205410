import React, { useState } from 'react'
import './style.scss';
import { BETable } from '../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { deleteReport, exportReport, getAllReports, getReportData, getReportPDF, getTemplateTopics } from '../../Actions/template';
import { getAllUsers } from '../../../../views/Actions/UsersManagementActions';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import BEStatusTag from '../../../../Components/BEStatusTag';
import { ChangeDateFormat, isDateInFY, isDateRangeInFY } from '../../../../Config/Functions/UsefullFunctions';
import EditIcon from '../../../../Components/BEIcons/EditIcon';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { setEditReportStep, setCurrentReport, setSelectedTemplateTopics, setCurrentGRESBSection, setCurrentEntity } from '../../../../Redux/ReportingReducer';
import { message, Spin } from 'antd';
import AssignTemplate from '../Drawers/AssignTemplate';
import ExportExcelIcon from '../../../../Components/BEIcons/ExportIcons/ExportExcelIcon';
import { EditReport } from '../Drawers/NewEditReport';
import DeleteIcon from '../../../../Components/BEIcons/DeleteIcon';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import BEConfirmModal from '../../../../Components/BEConfirmModal';
import { GRESBEntityDataTypes, ReportingReportType } from '../../../../Redux/Types/reportingTypes';
import ToolTip from '../../../../Components/BEToolTip';
import { BEEyeButton } from '../../../../Components/BEEyeButton';
import ExportPDFIcon from '../../../../Components/BEIcons/ExportIcons/ExportPDFIcon';
import ApproveIcon from '../../../../Components/BEIcons/ApproveIcon';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import { ChooseAssessment } from '../Drawers/ChooseAssessment';
import { calculateAssetProgress, getGresbEntityEvidences, getGresbReportProgress, onGresbAuthorization } from '../../Actions/gresbActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChooseAssets } from '../Drawers/ChooseAsset';
import AddNewReport from '../Drawers/AddReport';
import { BEMessage } from '../../../../Components/BEMessage';
import ExportWordDocIcon from '../../../../Components/BEIcons/ExportIcons/ExportWordDocIcon';
import { ReportDetails } from '../Drawers/ReportDetails';

interface IRerportStatusMapProps {
  stage: number;
}
const ReportStatusMap = (props: IRerportStatusMapProps) => {
  const { stage } = props;
  if (stage === 1) {
    return <BEStatusTag status='error'>Not Started</BEStatusTag>
  } else if (stage === 2) {
    return <BEStatusTag status='warning'>In Progress</BEStatusTag>
  } else if (stage === 3) {
    return <BEStatusTag status='success'>Completed</BEStatusTag>
  }
  else if (stage === 4) {
    return <BEStatusTag status='warning'>Sent for Review</BEStatusTag>
  }
  else if (stage === 5) {
    return <BEStatusTag status='success'>Approved</BEStatusTag>
  }
  else {
    return <BEStatusTag status='error'>Rejected</BEStatusTag>
  }
}

const AllReports = () => {
  const dispatch = useAppDispatch();
  const [selectedRow, setSelectedRow] = React.useState<any>(null);
  const allReports = useTypedSelector(state => state.reporting.allReports);
  const templates = useTypedSelector(state => state.reporting.TemplateDrafts);
  const gresbEntities = useTypedSelector(state => state.reporting.gresbEntityData);
  const assets = useTypedSelector(state => state.reporting.gresbAssetData);
  const [open, setOpen] = React.useState(false);
  const allUsers = useTypedSelector(state => state.userMgmt);
  const selectedYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const [loading, setLoading] = React.useState<boolean>(false)
  const yearEnd = useTypedSelector(state => state.companies.companyDetails?.data?.reporting_year_end);
  const [reportData, setReportData] = React.useState<any[]>([]);
  const [openAddNewReport, setOpenAddNewReport] = React.useState(false);
  const [openReportDetails, setOpenReportDetails] = React.useState(false);
  const [filteredReportData, setFilteredReportData] = React.useState<ReportingReportType[]>([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [loadingDownloadPDF, setLoadingDownloadPDF] = useState<boolean>(false);
  const [loadingDownloadWord, setLoadingDownloadWord] = useState<boolean>(false);
  const [openChooseAsset, setOpenChooseAsset] = React.useState(false);
  const [openAssessmentSelection, setOpenAssessmentSelection] = React.useState(false);
  const userID = Number(JSON.parse(localStorage.getItem('userInfo') || '{}')?.id);
  const location = useLocation();
  const navigate = useNavigate();

  const getGresbReportProgress = (report_id: number) => {
    const entity = gresbEntities?.data?.find((entity) => entity.report === report_id);
    const entityProgress = entity?.real_estate_assessment_progress || 0;
    const reportAssets = assets?.data.filter((asset) => asset.report === report_id);
    let assetProgress = 0;
    reportAssets.forEach((asset) => {
      assetProgress += asset.progress;
    });
    const totalProgress = (entityProgress + assetProgress) / (reportAssets?.length + 1);
    return totalProgress;
  }

  React.useEffect(() => {
    if (allReports?.status === 'idle') dispatch(getAllReports())
    if (allUsers?.status === 'idle') dispatch(getAllUsers())
  }, []);

  const handleExportPDF = async () => {
    setLoadingDownloadPDF(true);
    let pdf: any = await dispatch(getReportPDF(selectedRow.id));
    if (pdf) {
      let url = pdf?.data;
      let a = document.createElement('a');
      a.href = url;
      a.download = `${selectedRow.name}.pdf`;
      a.target = '_blank';
      a.click();
    }
    else message.error('Failed to download PDF');
    setLoadingDownloadPDF(false);
  }

  const handleExportWord = async () => {
    setLoadingDownloadWord(true);
    let res = await dispatch(getReportPDF(selectedRow.id, true));
    if (res) BEMessage.success("Your report will be in your inbox shortly.");
    else BEMessage.error("Failed to download Word file");
    setLoadingDownloadWord(false);
  }


  const columns: ColumnsType<any> = [
    {
      title: "Report Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => (a < b ? -1 : 1),
      render: (text: any, data: any) =>
        <span
          onMouseEnter={async () => {
            setSelectedRow(data);
            dispatch(setCurrentReport(data));
            const framework = getFrameworkFromTemplate(data.template);
            if (framework === 'GRESB') {
              const currentGresbEntity = gresbEntities?.data?.find((item: GRESBEntityDataTypes) => item.report === data.id);
              dispatch(setCurrentEntity(currentGresbEntity));
              await dispatch(getGresbEntityEvidences(data.id));
            }
          }}
          style={{
            fontWeight: 'bold',
            textDecoration: 'underline',
            cursor: 'pointer',
            display: "flex",
            flexDirection: "row"
          }}
          onClick={() => {
            const framework = getFrameworkFromTemplate(data.template);
            if (framework === 'GRESB') {
              setOpenAssessmentSelection(true);
            } else {
              setOpen(true);
              dispatch(setCurrentGRESBSection(null));
            }
          }}
        >{text}<BEEyeButton
            title={
              <div>
                <p>FROM: {ChangeDateFormat(data.from_date)}</p>
                <p>TO: {ChangeDateFormat(data.to_date)}</p>
              </div>
            }
            discription={undefined} /></span>
    },
    {
      title: "Status",
      dataIndex: "stage",
      key: "status",
      filters: [{ text: 'Not Started', value: 1 }, { text: 'In Progress', value: 2 }, { text: 'Completed', value: 3 }, { text: 'Sent for Review', value: 4 }, { text: 'Approved', value: 5 }, { text: 'Rejected', value: 6 }],
      onFilter: (value, record) => {
        return record.stage === value || (record.stage === 3 && value === 2)
      },
      render: (value, data: any) => {
        return (
          <>
            <ReportStatusMap stage={data?.stage} />
          </>
        );
      },
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      render: (value, data: any) => {
        const framework = getFrameworkFromTemplate(data.template);
        let progress = framework === 'GRESB' && data.stage !== 1 ? getGresbReportProgress(data.id) : data.progress;
        const status = progress < 30 ? 'error' : progress < 100 ? 'warning' : 'success';
        return <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* <Progress
            showInfo={false}
            percent={
              Math.round((
                filled
                / myMetrics?.data?.length
              ) * 100)
            } strokeColor={PrimaryTheme.primaryGreen} /> */}
          <BEStatusTag status={status}>
            <p style={{ padding: "0 8px" }}>{
              (progress)?.toFixed(2)
            }
              {" %"}
            </p>
          </BEStatusTag>
        </div>
      },
    },
    {
      title: "Framework",
      dataIndex: "framework",
      key: "framework",
      render: (value, data: any) => {
        return <p>{getFrameworkFromTemplate(data.template)}</p>
      }
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      render: (value, data: any) => {
        const user = allUsers?.data?.find((item: any) => item.id === data.reviewer)
        return <p>{user?.first_name + " " + user?.last_name}</p>
      }
    },
    // {
    //   title: "End Date",
    //   dataIndex: "to_date",
    //   key: "to_date",
    //   render: (value, data: any) => {
    //     return <p>{ChangeDateFormat(data.to_date)}</p>;
    //   },
    // },
    // {
    //   title: 'Created Date',
    //   dataIndex: 'createdDate',
    //   key: 'createdDate',
    // },
    {
      title: 'Published',
      dataIndex: 'published_on',
      render: (value, data: any) => {
        return <p>{data.published_on && data.stage >= 3
          ? ChangeDateFormat(data.published_on) : '-'}</p>
      },
    },
    {
      title: "Download",
      dataIndex: "download",
      key: "download",
      render: (value, data: any) => {
        const framework = getFrameworkFromTemplate(data.template);
        if (data.stage >= 2 && framework !== 'GRESB')
          return (
            <div
              style={{ cursor: 'pointer', display: 'flex', gap: '0.5rem', alignItems: 'center' }}
              onMouseEnter={() => {
                if (!loading && !loadingDownloadPDF)
                  setSelectedRow(data)
              }}
            >
              {loading && selectedRow?.id === data.id
                ? (
                  <Spin size="small" />
                ) : (
                  <div onClick={async () => {
                    setLoading(true)
                    await dispatch(exportReport(selectedRow))
                    setLoading(false)
                  }}
                  ><ExportExcelIcon fill={PrimaryTheme.primaryGreen} /></div>
                )}
              {
                (loadingDownloadPDF && selectedRow?.id === data.id)
                  ? (
                    <Spin size="small" />
                  ) : (
                    <div onClick={handleExportPDF}
                    ><ExportPDFIcon fill={PrimaryTheme.primaryGreen} /></div>
                  )
              }
              {
                (loadingDownloadWord && selectedRow?.id === data.id)
                  ? (
                    <Spin size="small" />
                  ) : (
                    <ToolTip title="Word document will be sent to your email">
                      <div onClick={handleExportWord}
                      ><ExportWordDocIcon fill={PrimaryTheme.primaryGreen} /></div>
                    </ToolTip>
                  )
              }

            </div>
          );
      },
    },
    // {
    //   title: "Edit Units Assigned",
    //   render: (value, data: any) => {
    //     return (
    //       <div
    //         style={{ height: "1rem" }}
    //         onMouseEnter={() => {
    //           setCurrentReport(data);
    //         }}
    //         onClick={() => {
    //           setOpenAssignTemplate(true);
    //         }}
    //       >
    //         <EditIcon inheritSize stroke={PrimaryTheme.primaryGray} />
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Action",
      render: (value, data: any) => {
        return (
          <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <div
              style={{ height: "1rem", cursor:'pointer' }}
              onClick={async () => setOpenDelete(true)}
              onMouseEnter={() => {
                setSelectedRow(data);
                dispatch(setCurrentReport(data));
              }}
            >
              <DeleteIcon inheritSize stroke={PrimaryTheme.primaryGray} />
            </div>
            <div
              style={{ height: "1rem", cursor: 'pointer' }}
              onMouseEnter={() => {
                setSelectedRow(data);
                dispatch(setCurrentReport(data));
              }}
              onClick={() => {
                console.log(data);
                const framework = getFrameworkFromTemplate(data.template);
                if (framework === 'GRESB') {
                  setOpenAssessmentSelection(true);
                } else {
                  setOpenReportDetails(true);
                  dispatch(setCurrentGRESBSection(null));
                }
              }}
            >
              <EditIcon inheritSize stroke={PrimaryTheme.primaryGray} />

            </div>
            {
              data.stage >= 4 && data.reviewer === userID &&
              <div
                onClick={() => navigateTo('approve-report/' + data.id)}
              >
                <ToolTip title='Review Report'>
                  <div
                    style={{ height: "1rem" }}>
                    <ApproveIcon inheritSize fill={PrimaryTheme.primaryGray} />
                  </div>
                </ToolTip>
              </div>
            }
          </div>
        );
      },
    },
  ];

  const checkGresbAuthorization = async (location: any) => {
    const openSpecificGresbReport = await dispatch(onGresbAuthorization(location));
    if (openSpecificGresbReport && allReports?.data?.length > 0) {
      const reportId = parseInt(localStorage.getItem('gresb_report_id') as string);
      if (reportId === 0) {
        localStorage.removeItem('gresb_report_id');
        navigate('/home/reporting/reports');
        setOpenAddNewReport(true);
      } else {
        const data = allReports?.data?.find((item: any) => item.id === reportId);
        dispatch(setCurrentReport(data));
        setSelectedRow(data);
        const currentGresbEntity = gresbEntities?.data?.find((item: GRESBEntityDataTypes) => item.report === reportId);
        dispatch(setCurrentEntity(currentGresbEntity));
        await dispatch(getGresbEntityEvidences(reportId));
        dispatch(setCurrentGRESBSection('entity'));
        setOpenAssessmentSelection(true);
      }
    } else {
      localStorage.removeItem('gresb_report_id');
    }
  }

  const getFrameworkFromTemplate = (id: number) => {
    return templates?.data?.find((item: any) => item.id === id)?.framework
  }

  React.useEffect(() => {
    if (allReports?.status === 'success') {
      // filter for from_date or to_date
      setReportData(allReports?.data.filter((item: any) => {
        return isDateRangeInFY(selectedYear, item.from_date, item.to_date, yearEnd || '')
        // return (isDateInFY(selectedYear, item.from_date, yearEnd || '') || isDateInFY(selectedYear, item.to_date, yearEnd || ''))
      }))
    }
  }, [allReports, selectedYear, yearEnd]);

  React.useEffect(() => {
    setFilteredReportData(reportData);
  }, [reportData]);

  React.useEffect(() => {
    if (allReports?.status === 'success')
      checkGresbAuthorization(location);
  }, [allReports]);


  return (
    <div className='all-reports'>
      <BEInput
        style={{ width: "313px" }}
        search
        placeholder="Search for a report"
        size="large"
        onChange={(e) => {
          const value = e.target.value?.toLowerCase();
          const filteredData = reportData?.filter((item: any) => {
            return item.name?.toLowerCase().includes(value);
          });
          setFilteredReportData(filteredData);
        }}
      />
      <BETable
        pagination
        columns={columns}
        loading={allReports?.status === 'loading'}
        data={filteredReportData.sort((a, b) => a.published_on > b.published_on ? -1 : 1)}
        rowKey='id'
      />
      <BEConfirmModal
        visible={openDelete}
        setVisible={setOpenDelete}
        onConfirm={async () => await dispatch(deleteReport(selectedRow?.id))}
        title='Confirm Delete'
        message='Are you sure you want to delete the report?'
      />
      <ChooseAssessment open={openAssessmentSelection} setOpen={setOpenAssessmentSelection} />
      <EditReport open={open} setOpen={setOpen} />
      <ChooseAssets open={openChooseAsset} setOpen={setOpenChooseAsset} />
      <AddNewReport open={openAddNewReport} setOpen={setOpenAddNewReport} />
      <ReportDetails open={openReportDetails} setOpen={setOpenReportDetails} />
      {/* <div style={{
        position: 'absolute',
        top: '-9999px',
        left: '-9999px',
      }}>
        <ApproveReport isPDFTemplate />
      </div> */}
    </div>
  )
}

export default AllReports