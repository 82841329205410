import { useState } from 'react';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { generatePDF } from '../../Actions';
import './style.scss';  
import { AnalyticsGraphMetrics } from '../Dashboard/AnalyticsGraphMetrics';
import { AnalyticsGraphBUs } from '../Dashboard/AnalyticsGraphBusinessUnits';
import { InsightsEmissions } from '../Dashboard/InsightsEmissions';
import { InsightsEnergy } from '../Dashboard/InsightsEnergy';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
export interface IAnalyticsDashboardProps {
}

export function AnalyticsDashboard(props: IAnalyticsDashboardProps) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);


  return (
    <div>
      <br />
      <div style={{display:'flex',justifyContent:'flex-end'}}>
        <BEButton className='primary' size='large'
          loading={loading}
          onClick={async () => {
              await dispatch(generatePDF(setLoading, 'analytics-container'));
            }
        }>
          Download PDF
        </BEButton>
      </div>
      <div id='analytics-container'>
        <br />
        <InsightsEmissions />
        <br />
        <InsightsEnergy />
        <br />
        <h2>Analytics Overview- Metric comparison</h2>
        <br />
        <AnalyticsGraphMetrics />
        <br />
        <h2>Analytics Overview- Business unit comparison</h2>
        <br />
        <AnalyticsGraphBUs />
      </div>
    </div>
  );
}
