import * as React from 'react';
import DataMappingDrawer from './DataMappingDrawer';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import BusinessUnitMappingDrawer from './BusinessUnitMappingDrawer';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { getSystemMapping } from '../../../Actions';

interface IMappingDrawerProps {
	integrationId: number;
	open: boolean;
	setOpen: Function;
}

export function MappingDrawer (props: IMappingDrawerProps) {
	const [step, setStep] = React.useState<number>(1);
	const step1Ref = React.useRef<any>({onButtonClick: ()=>{}});
	const step2Ref = React.useRef<any>({onButtonClick: ()=>{}});

	React.useEffect(() => {
		setStep(1);
	}, [props.open]);

	return (

	<BEDrawer
		width='100%'
		heading={step ===1 ? 'Data Mapping' : step === 2 ? 'Business Unit Mapping' : ''}
		open={props.open} 
		setOpen={props.setOpen}
		footer={
			<div style={{display:'flex', gap:'1rem'}}>
				<BEButton 
					onClick={()=> {
						if(step === 1)  step1Ref.current.onButtonClick();
						if(step === 2)  step2Ref.current.onButtonClick();
					}} 
				className='primary' size='large'>
					{step ===1 ? 'Save & next' : step === 2 ? 'Save & close' : ''}
				</BEButton>
				<BEButton onClick={()=> props.setOpen(false)} size='large'>
					Cancel
				</BEButton>
			</div>
		}
		>
		{step === 1 && <DataMappingDrawer ref={step1Ref} integrationId={props.integrationId} setStep={setStep} />}
		{step === 2 && <BusinessUnitMappingDrawer ref={step2Ref} setOpen={props.setOpen} />}
	</BEDrawer>
	);
}
