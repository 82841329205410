import * as React from 'react';
import '../../style.scss';

export interface IZeroStateChartProps {
  subject: string;
}

export function ZeroStateChart (props: IZeroStateChartProps) {
  return (
    <div className='zero-state-chart'>
      Please select a {props?.subject?.slice(0, props.subject?.length - 1)} to view the data
    </div>
  );
}
