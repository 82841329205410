import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import {
  GET_SCOPE_1_CONFIGURATIONS,
  EDIT_SCOPE_1_CONFIGURATION,
  GET_SCOPE_2_CONFIGURATIONS,
  GET_SCOPE_2_SPECIFICATIONS, EDIT_SCOPE_2_CONFIGURATION,
  RESET_SCOPE_1_CONFIGURATIONS_BY_ID,
  GET_SCOPE_1_AND_2_EMISSION_CALCULATIONS,
  GET_SCOPE_3_EMISSION_CALCULATIONS,
  GET_SCOPE_2_CUSTOM_EMISION_FACTORS,
  POST_SCOPE_2_CUSTOM_EMISION_FACTORS,
  PATCH_SCOPE_2_CUSTOM_EMISION_FACTORS,
  DELETE_SCOPE_2_CUSTOM_EMISION_FACTORS,
  GET_SCOPE_2_EMISION_CONFIGURATIONS,
  PATCH_SCOPE_2_EMISION_CONFIGURATIONS,
} from "../../../Utils/Routes/EmissionsRoutes";
import {
  setScope1Configurations,
  setScope2Configurations,
  setScope2Specifications,
  setScope1Calculations,
  setScope2Calculations,
  setScope3Calculations,
  setScope1FilteredCalculations,
  setScope2FilteredCalculations,
  setScope3FilteredCalculations,
  setScope2EmissionConfigurations,
  setScope2EmissionFactors,
} from "../../../Redux/EmmisionsReducer";
import { BEMessage } from "../../../Components/BEMessage";
import { StatusType } from "../../../Redux/Types/userTypes";
import { RootState } from "../../../Redux/store";
import { shortMonths } from "../../../Config/Data/genralData";
import dayjs from "dayjs";
import { getMonthsByQuarter, getStartDateAndEndDate } from "../../../Config/Functions/UsefullFunctions";
import { namesToShow, TypeData } from "../Data/data";

export const getScope2TypeName = (scope2EmissionType:number) => {
  if(scope2EmissionType === 1)  return '(Location-based)'
  else return '(Market-based)'
}

export const resetScope1ConfigurationsById = (id: number) => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(RESET_SCOPE_1_CONFIGURATIONS_BY_ID(id));
    if (res) {
      dispatch(getScope1Configurations());
      BEMessage.success("Configuration reset successfully");
    }
    else {
      BEMessage.error("Couldn't reset configuration");
      console.log(error);
    }
  }
}

export const getAllEmissionCalculations = (year: number) => {
  return async (dispatch: any) => {
    dispatch(setScope1Calculations({ status: "loading", data: [] }));
    dispatch(setScope2Calculations({ status: "loading", data: [] }));
    dispatch(setScope1FilteredCalculations({ status: "loading", data: [] }));
    dispatch(setScope2FilteredCalculations({ status: "loading", data: [] }));
    const [res, error] = await handleAPICall(GET_SCOPE_1_AND_2_EMISSION_CALCULATIONS(year));
    if (res) {
      let data:any = res.data.map((item: any) => {
        return {
          ...item,
          emission_category: namesToShow[item.emission_category] ? namesToShow[item.emission_category] : item.category,
        }
      })
      const scope1Data = data.filter((item: any) => item.scope === 'Scope 1');
      const scope2Data = data.filter((item: any) => item.scope === 'Scope 2');
      dispatch(setScope1Calculations({ status: "success", data: scope1Data }));
      dispatch(setScope2Calculations({ status: "success", data: scope2Data }));
    }
    else {
      dispatch(setScope1Calculations({ status: "error", data: [] }));
      dispatch(setScope2Calculations({ status: "error", data: [] }));
    }
  }
}

export const getScope3EmissionCalculations = (year: number) => {
  return async (dispatch: any) => {
    dispatch(setScope3Calculations({ status: "loading", data: [] }));
    dispatch(setScope3FilteredCalculations({ status: "loading", data: [] }));
    const [res, error] = await handleAPICall(GET_SCOPE_3_EMISSION_CALCULATIONS(year));
    if (res) {
      let feild_name_map:any = {}
      Object.keys(TypeData).map((key) => {
        feild_name_map[TypeData[key].feildName] = key
      })
      const data = res.data.map((item: any) => {
          let temp = item; 
          if(item.emission_category === item.category){
            temp['emission_category'] = feild_name_map[item.category]
          }
          temp['category'] = feild_name_map[item.category]
          return temp
      })
      dispatch(setScope3Calculations({ status: "success", data: data }));
    }
    else {
      dispatch(setScope3Calculations({ status: "error", data: [] }));
    }
  }
}


export const getScope1Configurations = () => {
  return async (dispatch: any) => {
    dispatch(setScope1Configurations({
      status: "loading",
      data: []
    }));
    const [data, error] = await handleAPICall(GET_SCOPE_1_CONFIGURATIONS());
    if (data) {
      dispatch(setScope1Configurations({
        status: "success",
        data: data?.data
      }));
    }
    else {
      console.log(error);
      dispatch(setScope1Configurations({
        status: "error",
        data: []
      }));
    }
  }
}

export const getScope2Configurations = () => {
  return async (dispatch: any) => {
    dispatch(setScope2Configurations({ status: 'loading', data: [] }))
    const [res, error] = await handleAPICall(GET_SCOPE_2_CONFIGURATIONS())
    if (res) {
      dispatch(setScope2Configurations({ status: 'success', data: res?.data }))
    }
    else {
      dispatch(setScope2Configurations({ status: 'error', data: [] }))
      console.log(error)
    }
  }
}

export const editScope1Configuration = (id: number, data: any) => {
  return async (dispatch: any) => {

    const [res, error] = await handleAPICall(EDIT_SCOPE_1_CONFIGURATION(id, data));
    if (res) {
      dispatch(getScope1Configurations());
      BEMessage.success("Metric conversion factors updated successfully");
    }
    else {
      BEMessage.error(res?.msg);
      console.log(error);
    }
  }
}

export const editScope2Configuration = (id: number, data: any) => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(
      EDIT_SCOPE_2_CONFIGURATION(id, data)
    );
    if (res) {
      dispatch(getScope2Configurations());
      BEMessage.success('Metric conversion factors updated successfully')
    } else {
      BEMessage.error("Couldn't update metric conversion factors");
      console.log(error);
    }
  };
};



export const filterDataByMonthOrBU = (filterByMonth: boolean, filterByBU: boolean) => {
  return async (dispatch: any, getState: () => RootState) => {
    const state = getState();
    const yearEnd = state.companies.yearEnd;
    const businessUnitRefs = state.entity.BusinessUnitRefs;
    const currentMonth = state.dataManager.metrics.dateSlicer.currentMonth;
    const currentBU = state.common.homePage.currentBusinessUnit;
    const currentBG = state.common.homePage.currentBusinessGroup;
    const scope1CalculationsByMonth = state.emissions.emissionsCalculations.scope1Calculations
    const scope2CalculationsByMonth = state.emissions.emissionsCalculations.scope2Calculations
    const scope3CalculationsByMonth = state.emissions.emissionsCalculations.scope3Calculations

    let filteredScope1Data: any = []
    let filteredScope2Data: any = []
    let filteredScope3Data: any = []

    if (filterByMonth) {
      let filteredDataByMonth: any = []
      let filteredDataByMonth2: any = []
      let filteredDataByMonth3: any = []
      if (currentMonth === 0) {
        filteredDataByMonth = scope1CalculationsByMonth?.data
        filteredDataByMonth2 = scope2CalculationsByMonth?.data
        filteredDataByMonth3 = scope3CalculationsByMonth?.data
      }
      else if (currentMonth < 13) {
        filteredDataByMonth = scope1CalculationsByMonth?.data.filter((obj: any) => obj.month === currentMonth)
        filteredDataByMonth2 = scope2CalculationsByMonth?.data.filter((obj: any) => obj.month === currentMonth)
        filteredDataByMonth3 = scope3CalculationsByMonth?.data.filter((obj: any) => obj.month === currentMonth)
      }
      else {
        const monthsOfQuarter = getMonthsByQuarter(currentMonth, dayjs(yearEnd).month())
        filteredDataByMonth = scope1CalculationsByMonth?.data.filter((obj: any) => monthsOfQuarter.includes(obj.month))
        filteredDataByMonth2 = scope2CalculationsByMonth?.data.filter((obj: any) => monthsOfQuarter.includes(obj.month))
        filteredDataByMonth3 = scope3CalculationsByMonth?.data.filter((obj: any) => monthsOfQuarter.includes(obj.month))
      }
      filteredScope1Data = filteredDataByMonth
      filteredScope2Data = filteredDataByMonth2
      filteredScope3Data = filteredDataByMonth3
    }
    else{
      filteredScope1Data = scope1CalculationsByMonth?.data
      filteredScope2Data = scope2CalculationsByMonth?.data
      filteredScope3Data = scope3CalculationsByMonth?.data
    }


    if(filterByBU){
      let filteredData = [];
      let filteredData2 = [];
      let filteredData3 = [];
      if (currentBU !== 0) {
        filteredData = filteredScope1Data.filter((obj: any) => obj?.business_unit === currentBU)
        filteredData2 = filteredScope2Data.filter((obj: any) => obj?.business_unit === currentBU)
        filteredData3 = filteredScope3Data.filter((obj: any) => obj?.business_unit === currentBU)
      }
      else if (currentBG !== 0) {
        let BUs = businessUnitRefs?.data.filter((obj: any) => obj.business_group === currentBG).map((obj2: any) => obj2.business_unit)
        filteredData = filteredScope1Data.filter((obj: any) => BUs.includes(obj?.business_unit))
        filteredData2 = filteredScope2Data.filter((obj: any) => BUs.includes(obj?.business_unit))
        filteredData3 = filteredScope3Data.filter((obj: any) => BUs.includes(obj?.business_unit))
      }
      else {
        filteredData = filteredScope1Data
        filteredData2 = filteredScope2Data
        filteredData3 = filteredScope3Data
      }
      filteredScope1Data = filteredData
      filteredScope2Data = filteredData2
      filteredScope3Data = filteredData3
    }
    return {
      'scope1Data': filteredScope1Data,
      'scope2Data': filteredScope2Data,
      'scope3Data': filteredScope3Data
    }
  }
}


export const getScope2Specifications = () => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(GET_SCOPE_2_SPECIFICATIONS());
    if (res) {
      console.log(res)
      dispatch(setScope2Specifications(res?.data))
    }
    else {
      console.log(error)
    }
  }
}

export const getScope2EmissionFactors = () => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(GET_SCOPE_2_CUSTOM_EMISION_FACTORS());
    if (res) {
      dispatch(setScope2EmissionFactors({ status: 'success', data: res?.data }))
    }
    else {
      dispatch(setScope2EmissionFactors({ status: 'error', data: [] }))
      console.log(error)
    }
  }
}

export const postScope2EmissionFactors = (data: any) => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(POST_SCOPE_2_CUSTOM_EMISION_FACTORS(data));
    if (res) {
      dispatch(getScope2EmissionFactors());
      BEMessage.success('Emission factor added successfully')
    }
    else {
      BEMessage.error('Couldn\'t add emission factor')
      console.log(error)
    }
  }
}

export const patchScope2EmissionFactors = (id: number, data: any) => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(PATCH_SCOPE_2_CUSTOM_EMISION_FACTORS(id, data));
    if (res) {
      dispatch(getScope2EmissionFactors());
      BEMessage.success('Emission factor updated successfully')
    }
    else {
      BEMessage.error('Couldn\'t update emission factor')
      console.log(error)
    }
  }
}

export const deleteScope2EmissionFactors = (id: number) => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(DELETE_SCOPE_2_CUSTOM_EMISION_FACTORS(id));
    if (res) {
      dispatch(getScope2EmissionFactors());
      BEMessage.success('Emission factor deleted successfully')
    }
    else {
      BEMessage.error(error?.msg)
      console.log(error)
    }
  }
}

export const getScope2EmissionConfigurations = () => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(GET_SCOPE_2_EMISION_CONFIGURATIONS());
    if (res) {
      dispatch(setScope2EmissionConfigurations({ status: 'success', data: res?.data }))
    }
    else {
      dispatch(setScope2EmissionConfigurations({ status: 'error', data: [] }))
      console.log(error)
    }
  }
}

export const patchScope2EmissionConfigurations = (id: number, data: any) => {
  return async (dispatch: any) => {
    const [res, error] = await handleAPICall(PATCH_SCOPE_2_EMISION_CONFIGURATIONS(id, data));
    if (res) {
      dispatch(getScope2EmissionConfigurations());
      BEMessage.success('Emission configuration updated successfully')
    }
    else {
      BEMessage.error('Couldn\'t update emission configuration')
      console.log(error)
    }
  }
}