import * as React from 'react';
import './style.scss';
import { SupplierTopNav } from './SupplierTopNav';
import { LeftSection } from './LeftSection';
import { RightSection } from './RightSection';
import {
  getSupplierSelectedQuestions,
  getSupplierAssessmentAnswers
} from '../../../Actions/supplierFormActions';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useSearchParams } from 'react-router-dom';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { setCurrentSection } from '../../../../../Redux/SupplierReducer';
import { SupplierQuestionsTypes } from '../../../../../Redux/Types/suppliersTypes';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import CheckFilledIcon from '../../../../../Components/BEIcons/CheckFilledIcon';

export interface IMainSupplierFormProps {
  isFillSection?: boolean;
}

export function MainSupplierForm(props: IMainSupplierFormProps) {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const assessmentStatus = useTypedSelector(state => state.supplier.AssessmentStatus);
  const questions = useTypedSelector(state => state.supplier.SupplierQuestions);
  const supplierAssessmentQuestions = useTypedSelector(state => state.supplier.SupplierQuestions);
  const [sections, setSections] = React.useState<any>([]);

  React.useEffect(() => {
    if (supplierAssessmentQuestions?.status === 'success') {
      // let EnviromentalSection = supplierAssessmentQuestions?.data.filter((item:SupplierQuestionsTypes)=>item.pillar === 'Enviromental');
      // let SocialSection = supplierAssessmentQuestions?.data.filter((item:SupplierQuestionsTypes)=>item.pillar === 'Social');
      // let GovernanceSection = supplierAssessmentQuestions?.data.filter((item:SupplierQuestionsTypes)=>item.pillar === 'Governance ');
      // let tempSections:any = [];
      // if(EnviromentalSection?.length > 0) tempSections.push('Enviromental');
      // if(SocialSection?.length > 0) tempSections.push('Social');
      // if(GovernanceSection?.length > 0) tempSections.push('Governance ');

      let tempSections: any = Array.from(new Set(supplierAssessmentQuestions?.data.map((item: SupplierQuestionsTypes) => item.attribute)));
      let section = tempSections[0];
      dispatch(setCurrentSection(section));
      setSections(tempSections);
    }
  }, [supplierAssessmentQuestions?.data]);

  React.useEffect(() => {
    dispatch(getSupplierAssessmentAnswers(String(searchParams.get('secret'))));
    dispatch(getSupplierSelectedQuestions(String(searchParams.get('secret'))))
  }, []);

  if (assessmentStatus !== null && assessmentStatus > 2) {
    //this page needs to be made
    return (
      <>
      <SupplierTopNav />
      <div
          style={{
            fontWeight: 600,
            fontSize: "1.2rem",
            margin: "0 auto",
            width: "50%",
            height: '80vh',
            color: PrimaryTheme.primaryGreen,
            display: "flex",
            justifyContent: "center",
            alignItems: 'center'
          }}
        >
          <CheckFilledIcon fill={PrimaryTheme.primaryGreen} />
          <span style={{ marginLeft: "1rem" }}>You have filled out the survey</span>
        </div>
        </>

    )
  }

  if (!props.isFillSection && (assessmentStatus === null || questions?.status === 'loading')) {
    return (
      <GlobalLoader />
    )
  }
  return (
    <div>
      <SupplierTopNav />
      <div className='supplier-form'>
          <LeftSection sections={sections} isFillSection={props.isFillSection} />
          <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            <RightSection sections={sections} isFillSection={props.isFillSection} />
          </div>
      </div>
    </div>
  );
}
