import * as React from 'react';
import EditableTable, { EditableColumnTypes } from '../../../../../../../../../../Components/BETable/ExcelLikeTable';
import { BEButton } from '../../../../../../../../../../Components/BEFormItems/BEButton';
import { useTypedSelector } from '../../../../../../../../../../Config/Hooks/useTypedSelector';
import { TemplateSelectedTopicTypes } from '../../../../../../../../../../Redux/Types/reportingTypes';
import { useAppDispatch } from '../../../../../../../../../../Config/Hooks/useAppDispatch';
import { fillReportData } from '../../../../../../../../Actions/template';
import { getDropDownOptionsForGRESB, postGresbEntityDataToPortal, updateAssetDetails } from '../../../../../../../../Actions/gresbActions';

export interface IType1TopicProps {
  uniqueCode: string;
  nonEditable?: boolean;
  data?: any[];
  fixedRows?: number;
}

export function Type1Topic(props: IType1TopicProps) {
  const dispatch = useAppDispatch();
  const gresbSlugData = useTypedSelector(state => state.reporting.gresbSlugData?.data);
  const selectedTopics = useTypedSelector(state => state.reporting.allSelectedTemplateTopics);
  const currentReport = useTypedSelector(state => state.reporting.currentReport);
  const currentGresbSection = useTypedSelector(state => state.reporting.currentGRESBSection);
  const reportData = useTypedSelector(state => state.reporting.reportData?.data);
  const [dataSource, setDataSource] = React.useState<any>([]);
  const [e, setE] = React.useState<any>([]);

  // for fixed rows
  React.useEffect(() => {
    props?.data && console.log(props?.data);
    if (props.fixedRows) {
      let tempData: any = [];
      for (let i = 0; i < props.fixedRows; i++) {
        let tempObj: any = {};
        tempObj['S. No.'] = i + 1;
        tempData.push(tempObj);
      }
      setDataSource(tempData);
    }
  }, [props.fixedRows, props.uniqueCode, props?.data])

  const columns: EditableColumnTypes[] = [
    {
      title: '',
      dataIndex: 'S. No.',
    },
    ...Array.from(new Set(

      props?.data ? props?.data.map((item: any) => item.accounting_metric) : // For Gresb only

        selectedTopics?.data.filter(
          (item1: TemplateSelectedTopicTypes) => item1.unique_code === props.uniqueCode)
          .map((item2: TemplateSelectedTopicTypes) => item2.accounting_metric)

    ))
      // .sort((a: string, b: string) => a.localeCompare(b))
      .map((item3: string) => {
        return {
          title: <p className='table-title'>{item3 + (props?.data ?
            (props?.data?.filter((item4: any) => item4.accounting_metric === item3)[0]?.unit !== 'nan' ?
              ' (' + props?.data.filter((item4: any) => item4.accounting_metric === item3)[0]?.unit + ')' : '') :
            ' (' +
            selectedTopics?.data.filter((item4: TemplateSelectedTopicTypes) => item4.unique_code === props.uniqueCode && item4.accounting_metric === item3)[0!]?.unit
            + ')'
          )}</p>,
          dataIndex: item3,
          rules: {
            isDropDown: props?.data ? props?.data.filter((item4: any) => item4.accounting_metric === item3)[0]?.response_type?.includes('<') ? true : false : false, // For Gresb only,
            isNumber: selectedTopics?.data.filter((item4: TemplateSelectedTopicTypes) => item4.unique_code === props.uniqueCode && item4.accounting_metric === item3)[0!]?.unit !== 'Description' ? true : false,
            dropDownOptions: props?.data ? getDropDownOptionsForGRESB(props?.data?.find((item4: any) => item4.accounting_metric === item3), gresbSlugData) : [],
            // isRequired: true
          },
          editable: props.nonEditable ? false : true,
        }
      })
  ]

  React.useEffect(() => {
    let allUniqueAccountingMetric = Array.from(new Set(

      props?.data ? props?.data.map((item: any) => item.accounting_metric) : // For Gresb only
        selectedTopics?.data.filter((item1: TemplateSelectedTopicTypes) => item1.unique_code === props.uniqueCode).map((item2: TemplateSelectedTopicTypes) => item2.accounting_metric)

    ))
    // .sort((a: string, b: string) => a.localeCompare(b));
    let tempData: any = [];
    let errorData: any = [];
    let maxData = props.fixedRows ? props.fixedRows : 0;
    allUniqueAccountingMetric.forEach((accountingMetric: string) => {
      let topicID = null;

      // for gresb data
      if (props?.data) {
        topicID = props?.data.filter((item1: any) => item1.accounting_metric === accountingMetric)[0]?.id;
      } else {
        topicID = selectedTopics?.data.filter((item1: TemplateSelectedTopicTypes) => item1.accounting_metric === accountingMetric && props.uniqueCode === item1.unique_code)[0].id;
      }

      if (reportData[topicID] && reportData[topicID]?.length > maxData) maxData = reportData[topicID]?.length;
    })
    for (let i = 0; i < maxData; i++) {
      let tempObj: any = {};
      let errorObj: any = {};
      allUniqueAccountingMetric.forEach((accountingMetric: string) => {
        let topicID = null;

        // for gresb data
        if (props?.data) {
          topicID = props?.data.filter((item1: any) => item1.accounting_metric === accountingMetric)[0]?.id;
        } else {
          topicID = selectedTopics?.data.filter((item1: TemplateSelectedTopicTypes) => item1.accounting_metric === accountingMetric && props.uniqueCode === item1.unique_code)[0].id;
        }

        if (reportData[topicID] && reportData[topicID][i]) {
          tempObj[accountingMetric] = reportData[topicID][i]?.data;
          if (props?.data) {
            errorObj[accountingMetric] = reportData[topicID][i]?.additional_information;
          }
        }
        else {
          tempObj[accountingMetric] = '';
          if (props?.data) {
            errorObj[accountingMetric] = '';
          }
        }
        tempObj['S. No.'] = i + 1;
        errorObj['S. No.'] = i + 1;
      });
      tempData.push(tempObj);
      errorData.push(errorObj);
    }
    setDataSource(tempData);
    setE(errorData);
  }, [selectedTopics, reportData, props.uniqueCode]);

  const handleSave = (row: any, dataIndex: string) => {
    // update the reportData
    let topicID = selectedTopics?.data.filter((item1: TemplateSelectedTopicTypes) => item1.accounting_metric === dataIndex && props.uniqueCode === item1.unique_code)[0]?.id;

    // for gresb data
    if (props?.data) {
      topicID = props?.data.filter((item1: any) => item1.accounting_metric === dataIndex)[0]?.id;
    }

    console.log('row', row)
    dispatch(fillReportData({
      data: row[dataIndex],
      topic: topicID,
      esg_report: currentReport?.id
    }, row['S. No.'] - 1)).then(async () => {
      if (currentGresbSection === 'entity' && props?.data) {
        const variableCode = props?.data.filter((item1) => item1.accounting_metric === dataIndex)[0]?.variable_code;
        if (!variableCode) {
          return;
        }
        const portalDataUpdated = await dispatch(postGresbEntityDataToPortal(variableCode, String(row[dataIndex])));
      }
      if (currentGresbSection === 'asset' && props?.data) {
        await dispatch(updateAssetDetails(topicID, row[dataIndex]));
      }
    })


    return true;
  }
  const AddRow = () => {
    // push a new row to the dataSource
    setDataSource([...dataSource, { 'S. No.': dataSource?.length + 1 }]);
  }

  return (
    <div>
      <EditableTable
        data={dataSource}
        columns={columns}
        handleSave={handleSave}
        errorRecords={props?.data ? e : []}
      />
      {
        !props.nonEditable && !props.fixedRows &&
        <BEButton size='large' style={{ width: '100%', marginTop: '1rem' }}
          onClick={AddRow}
        >
          Add Row +
        </BEButton>
      }
    </div>
  );
}
