import React from 'react'
import IconProps from './Interface/IconProps'

const FilledArrow = (props: IconProps) => {
    return (
        <svg
            width={props.inheritSize ? "100%" : 10}
            height={props.inheritSize ? "100%" : 10}
            fill={props.fill ? props.fill : "none"}
            viewBox="0 0 10 10"  xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.27003 5.7871L8.98253 9.4996H0.498535V1.0156L4.21104 4.7281L8.45304 0.485352L9.51428 1.54585L5.27003 5.7871Z"
				fill={props.fill ? props.fill : "black"}
			/>
		</svg>

    );
}

export default FilledArrow