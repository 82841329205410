import { decryptRole } from "../../views/Actions/LoginActions";
import { clearStore } from "../ExtraReducrActions";
import type { StatusType,UserState } from "../Types/userTypes";

import {
    Reducer,
    createSlice,
  } from "@reduxjs/toolkit";


  const initialState: UserState = {
    isLoggedIn: false,
    data: {},
    otp:{
      status: 'idle',
      data: null,
      resendStatus: 'idle',
    },
    permissions:{
      status:'idle',
      data:[],
    },
    userProfile: {
      status: 'idle',
      data: null
    },
    userRole: null,
    encryptedRole: null
  };
  
  export const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
    reducers: {
      signIn: (state, action) => {
        state.data = action.payload;
        localStorage.setItem("access", action.payload.token.access);
        localStorage.setItem("refresh", action.payload.token.refresh);
        localStorage.setItem("userInfo", JSON.stringify(action.payload.userInfo));
        state.encryptedRole = action.payload.userInfo.role;
        const decryptedRole = decryptRole(action.payload.userInfo.role) as any;
        state.userRole = decryptedRole;
        let encryptedRole = action.payload.userInfo.role;
        encryptedRole = encryptedRole?.replaceAll(' ', '+'); // Temp Fix for space issue in encrypted string
        localStorage.setItem('role', encryptedRole);
        state.isLoggedIn = true;
      },
      signInFailed: (state) => {
        state.isLoggedIn = false;
      },
      signOut: (state) => {
        state.data = {};
        state.isLoggedIn = false;
        localStorage.clear();
      },
      setOtp: (state, action) => {
        state.otp.status = action.payload;
      },
      setResendOtp: (state, action) => {
        state.otp.resendStatus = action.payload;
      },
      setPermissions: (state, action) => {
        state.permissions = action.payload;
      },
      setIsLoggedIn: (state, action) => {
        state.isLoggedIn = action.payload;
      },
      setUserProfile: (state, action) => {
        state.userProfile = action.payload
      },
      setRole: (state, action) => {
        state.userRole = action.payload;
      }
    },
  });
  
  export const {
    signIn,
    signOut,
    setOtp,
    signInFailed,
    setResendOtp,
    setPermissions,
    setIsLoggedIn,
    setUserProfile,
    setRole
  } = userSlice.actions;
  
  const userReducer: Reducer<UserState> = userSlice.reducer;
  
  export default userReducer;
  
