import * as React from 'react';
import { Header } from '../Header';
import { BEInput } from '../../Components/BEFormItems/BEInput';
import './index.scss';
import { DataManagerListItem } from '../MobileComponents/DataManagerListItem';
import { useTypedSelector } from '../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../Config/Hooks/useAppDispatch';
import { getMetrics, getMyMetrics } from '../../views/Actions/OnboardingStep4Actions';
import { setMyMetricsPerYear } from '../../Redux/OnboardingReducer';
import { DataEntryDrawer } from '../Drawers/DataEntryDrawer';
import { MyMetric } from '../../Redux/Types/dataManagerTypes';
import { getMetricsStatus, getMyMetricsData } from '../../Features/DataManager/Actions';
import { GlobalLoader } from '../../Components/GlobalLoader';
import { MetricDataDrawer } from '../Drawers/MetricDataDrawer';

export interface IDataManagerProps {
}

export function DataManager(props: IDataManagerProps) {
    const [searchValue, setSearchValue] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [openMetricDataDrawer, setOpenMetricDataDrawer] = React.useState<MyMetric | null>(null);
    const [searchedMetrics, setSearchedMetrics] = React.useState<MyMetric[] | null>([]);
    const myMetrics = useTypedSelector(state => state.onBoarding.metrics.myMetrics);
    const myMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.myMetricsPerYear);
    const directMetrics = useTypedSelector(state => state.onBoarding.metrics.directMetrics);
    const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const metricsStatus = useTypedSelector(state => state.dataManager.metrics.metricStatus);
    const myMetricData = useTypedSelector(state => state.onBoarding.metrics.myMetricsData);
    const dispatch = useAppDispatch();

    React.useEffect (() => {
        if (directMetrics.status !== 'success') {
            dispatch(getMetrics());
        }
        if (myMetrics.status !== 'success') {
            dispatch(getMyMetrics());
        }
        if(metricsStatus.status !== 'success'){
            dispatch(getMetricsStatus());
        }
        if(myMetricData.status !== 'success'){
            dispatch(getMyMetricsData());
        }
    }, []);

    React.useEffect(() => {
        if (myMetrics.status === 'success') {
            dispatch(setMyMetricsPerYear(
                {
                    status: 'success',
                    data: myMetrics.data.filter((item) => item.year === currentYear)
                }
            ));
        }
        setLoading(myMetrics.status === 'loading');
    }, [currentYear, myMetrics]);


    // handle search
    React.useEffect(() => {
        if (searchValue === '') {
            setSearchedMetrics(myMetricsPerYear.data);
        } else {
            setSearchedMetrics(myMetricsPerYear.data.filter((item) => item.title.toLowerCase().includes(searchValue.toLowerCase())));
        }
    }, [searchValue, myMetricsPerYear]);



    return (
      <>
        <div className='mobile-dm'>
            <Header heading="Data Entry" />
            <BEInput
                className='mobile-search-dm'
                search
                placeholder="Search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
            />
            <hr className='hr' />
            {   loading ? <GlobalLoader height={'calc(100vh - 100)'} /> :
                <div>
                {
                    searchedMetrics?.map((item, index) => {
                        return (
                          <div onClick={()=>{
                            setOpenMetricDataDrawer(item)
                          }}>
                            <DataManagerListItem
                                key={index}
                                sno={index + 1}
                                title={item.title}
                            />
                          </div>
                        );
                    })
                }
            </div>}
        </div>
       
        <MetricDataDrawer
            metric={openMetricDataDrawer}
            setOpen={setOpenMetricDataDrawer}
        />
        </>    
    );
}
