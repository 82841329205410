import * as React from 'react';
import { BEDrawer } from '../../../../Components/BEDrawer';
import { Scope2EmissionConfigurationType } from '../../../../Redux/Types/emisionsTypes';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { BEInfoBox } from '../../../../Components/BEInfoBox';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { Select } from 'antd';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { patchScope2EmissionConfigurations } from '../../Actions';

export interface IEditConfigDrawerProps {
  open: boolean;
  setOpen: Function;
  currentConfig: Scope2EmissionConfigurationType | null;
}

export function EditConfigDrawer (props: IEditConfigDrawerProps) {
  const dispatch = useAppDispatch();
  const businessUnits:any = useTypedSelector(state => state.entity.BusinessUnits)
  const myMetrics = useTypedSelector((state) => state.onBoarding.metrics.myMetrics);
  const scope2EmissionFactors = useTypedSelector(state => state.emissions.emissionFactors.scope2EmissionFactors)
  const [currentEmmisionFactorIndex, setCurrentEmmisionFactorIndex] = React.useState<any>(null)
  const [loading, setLoading] = React.useState(false)

  
  const onSave = async() => {
    let dataToUpdate:any = {
      id: props.currentConfig?.id,
    }
    if(currentEmmisionFactorIndex === null) dataToUpdate['emission_factor'] = null
    else{
      const ef = scope2EmissionFactors.data.filter((item:any) => item.bcode === myMetrics?.data?.find((item:any) => item?.id === props.currentConfig?.my_metric)?.bcode)[currentEmmisionFactorIndex]
      console.log('ef', ef)
      if(ef.type === 'Custom') dataToUpdate['emission_factor'] = ef.id
      else dataToUpdate['default_emission_factor'] = ef.id
    }
    setLoading(true)
    await dispatch(patchScope2EmissionConfigurations(props.currentConfig?.my_metric as number, [dataToUpdate]))
    setLoading(false)
    props.setOpen(false)
  }

  React.useEffect(() => {
    if (props.currentConfig) {
      if((props.currentConfig.emission_factor || props.currentConfig.default_emission_factor)){
        let index:any = null
        const ef_field = props.currentConfig.emission_factor ? 'emission_factor' : 'default_emission_factor' ;
        const ef_type = props.currentConfig.emission_factor ? 'Custom' : 'Default' ;
        scope2EmissionFactors.data.filter((item:any) => item.bcode === myMetrics?.data?.find((item1:any) => item1?.id === props.currentConfig?.my_metric)?.bcode)
        .forEach((item2:any, i:any) => {
          if (item2.id === props?.currentConfig?.[ef_field] && item2.type === ef_type) {  
            index = i
          }
        })
        setCurrentEmmisionFactorIndex(index)
      }
    }
    else setCurrentEmmisionFactorIndex(null)
  }, [props.currentConfig])

  return (
    <BEDrawer
      heading={'Edit Configuration'}
      open={props.open}
      setOpen={props.setOpen}
      footer={
        <>
          <BEButton size='large' className='primary' onClick={onSave} loading={loading}>Save</BEButton>
          <BEButton size='large' onClick={() => props.setOpen(false)}>Cancel</BEButton>
        </>
      }
    >
      <BEInfoBox title="Configuration Details" 
        description={`Edit configuration for business unit 
          ${businessUnits?.data?.find((item:any) => item?.id === props.currentConfig?.business_unit)?.name}
          and metric ${myMetrics?.data?.find((item:any) => item?.id === props.currentConfig?.my_metric)?.title}`}
      />
      <br/>

      <Select size='large'
        value={currentEmmisionFactorIndex}
        onChange={(value) => setCurrentEmmisionFactorIndex(value)}
        style={{width:'100%'}}
      >
        {
          scope2EmissionFactors.data?.filter((item:any) => item?.bcode === myMetrics?.data?.find((item:any) => item?.id === props.currentConfig?.my_metric)?.bcode)
          .map((item:any, i:any) => (
            <Select.Option key={i} value={i}>
              {item.ef_name}
            </Select.Option>
          ))
        }
      </Select>

    </BEDrawer>
  );
}
