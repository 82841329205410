import { Col, Row } from 'antd';
import * as React from 'react';
import '../InsightsEmissions/style.scss';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { ColumnsType } from 'antd/es/table';
import { BETable } from '../../../../../Components/BETable';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { filterDataByMonthOrBU } from '../../../../Emissions/Actions';
import { graphFillColorArray } from '../../../../../Config/Data/dashboardData';

export interface IInsightsEnergyProps {
}

export function InsightsEnergy(props: IInsightsEnergyProps) {
    const dispatch = useAppDispatch();
    const [chartData, setChartData] = React.useState<any>([]);
    const [chartDataPerPage, setChartDataPerPage] = React.useState<any>([]);

    const columns: ColumnsType<any> = [
        {
            title: 'Sr. No.',
            key: 'sno',
            render: (text, object, index) => index + 1
        },
        {
            title: 'METRIC NAME',
            dataIndex: 'name',
        },
        {
            title: 'TOTAL ENERGY (Gj)',
            dataIndex: 'value',
        },
        {
            title: '% OF TOTAL ENERGY',
            dataIndex: 'percentage',
        }
    ];

    const getEmissionValue = async () => {
        let res = await dispatch(filterDataByMonthOrBU(true, true));
        const scope1Data = res?.scope1Data;
        const scope2Data = res?.scope2Data;
        const data = [...scope1Data, ...scope2Data];

        // group the data by metric name
        let metric_id_metric_name: any = {};
        const groupedData = data.reduce((acc: any, item: any) => {
            if (!acc[item.my_metric]) {
                acc[item.my_metric] = 0;
            }
            metric_id_metric_name[item.my_metric] = item.title;
            acc[item.my_metric] += item.tj;
            return acc;
        }, {});

        let chartData = Object.keys(groupedData).map((key) => {
            return {
                name: metric_id_metric_name[key],
                value: Number(groupedData[key]?.toFixed(2)) * 1000
            }
        }).filter((item) => item.value !== 0).sort((a: any, b: any) => b.value - a.value);


        let totalEnergy = chartData.reduce((acc: any, item: any) => acc + item.value, 0);

        if (chartData?.length > 5) {
            const leftData = chartData.slice(0, 4);

            let total = 0;
            for (let i = 0; i < chartData?.length; i++) {
                total += chartData[i].value;
            }

            chartData = [...leftData, { name: 'Others', value: Number(total.toFixed(2)) }];
        }

        chartData = chartData.map((item: any) => {
            return {
                ...item,
                percentage: ((item.value / totalEnergy) * 100).toFixed(2)
            }
        });

        setChartData(chartData);
        setChartDataPerPage(chartData.slice(0, 5));

    }

    React.useEffect(() => {
        getEmissionValue();
    }, []);

    return (
        <div className='insights-container'>
            <Row>
                <h1 className='title'>
                    Energy
                </h1>
            </Row>
            <hr style={{ opacity: 0.1 }} />
            <br />
            <Row>
                <Col span={8} className='chart-container'>
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                            <Pie
                                data={chartDataPerPage}
                                cx="50%"
                                cy="50%"
                                innerRadius={50}
                                outerRadius={120}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {chartData.map((entry: any, index: number) => (
                                    <Cell key={`cell-${index}`} fill={graphFillColorArray[index % graphFillColorArray?.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend />
                        </PieChart>
                    </ResponsiveContainer>
                </Col>
                <Col offset={1} span={15} className='table-container'>
                    <BETable
                        columns={columns}
                        data={chartData}
                        // pagination
                        pageSize={5}
                        formatTitle={true}
                        // handleChangePage={(page: number) => setChartDataPerPage(chartData.slice((page - 1) * 5, page * 5))}
                    />
                </Col>
            </Row>

        </div>
    );
}