import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { Step1 } from '../Steps/Step1';
import { Step2 } from '../Steps/Step2';
import { Step3 } from '../Steps/Step3';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { getMaterialitySurveys } from '../../../Actions/matirialityActions';
import { setCreateSurveyStep } from '../../../../../Redux/MatirialityReducer';
import './style.scss';
import { BEMessage } from '../../../../../Components/BEMessage';
import { setSelectedTopics, setStakeholders, setStakeholdersToAssessment, setTopics } from '../../../../../Redux/MatirialityReducer';

export interface IAddMatirialityAssessMentProps {
  open:boolean;
  setOpen:Function
}

export function AddMatirialityAssessMent (props: IAddMatirialityAssessMentProps) {
  const dispatch = useAppDispatch();
  const currentSurveyStep = useTypedSelector((state)=>state.matiriality.createSurveyStep);
  const stakeholders = useTypedSelector((state) => state.matiriality.stakeholders);
  const onCancle = () => {
    dispatch(getMaterialitySurveys())
    props.setOpen(false)
  }

  // clean up
  React.useEffect(() => {
    if (!props.open) {
      dispatch(setSelectedTopics([]));
      dispatch(setTopics([]));
      dispatch(setStakeholders({ status: "idle", data: [] }));
      dispatch(setStakeholdersToAssessment({ status: "idle", data: [] }));
    }
  }, [props.open])
  return (
    <BEDrawer
    open={props.open}
    setOpen={props.setOpen}
    heading='Add Materiality Assessment'
    width='100vw'
    footer={
      <>
      <BEButton className='primary' size='large'
      disabled={currentSurveyStep !== 3 || stakeholders?.data?.length === 0}
      onClick={()=>{
        BEMessage.success('Assessment Added Successfully')
        onCancle()
      }}
      >Add Assessment
      </BEButton>
      <BEButton  size='large'
      onClick={onCancle}
      >Cancel</BEButton>
      </>
    }
    >
      <div className='add-materiality-assessment'>
        <Step1
         open={currentSurveyStep === 1}
         onCancle={onCancle} 
         setOpen={()=>
          dispatch(setCreateSurveyStep(currentSurveyStep===1?0:1))
         }/>
        <Step2 open={currentSurveyStep === 2}
        setOpen={()=>
          dispatch(setCreateSurveyStep(currentSurveyStep===2?0:2))
         }
        onCancle={onCancle}/>
        <Step3 open={currentSurveyStep === 3}
        setOpen={()=>
          dispatch(setCreateSurveyStep(currentSurveyStep===3?0:3))
         }
        onCancle={onCancle}/>
      </div>
    </BEDrawer>
  );
}
