import * as React from 'react';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { Progress, ProgressProps } from 'antd';
import './styles.scss'
import { AddCommaToNumber } from '../../../../Config/Functions/UsefullFunctions';

interface IFrameworkCardProps {
	icon: string;
	name?: string;
	percent: number;
	reportName: string;
}

export function FrameworkCard (props: IFrameworkCardProps) {
	const conicColors: ProgressProps['strokeColor'] = {
        '0%': props.percent > 85 ? PrimaryTheme.primaryGreen : props.percent > 40 ? PrimaryTheme.amber : PrimaryTheme.primaryRedError,
        '40%': props.percent > 85 ? PrimaryTheme.primaryGreen : props.percent > 40 ? PrimaryTheme.amber : PrimaryTheme.primaryRedError,
        '85%': props.percent > 85 ? PrimaryTheme.primaryGreen : props.percent > 40 ? PrimaryTheme.amber : PrimaryTheme.primaryRedError,
        '100%': PrimaryTheme.primaryGreen,
    };
  return (
	<div className='framework-card-container' style={{backgroundColor:!props.name? PrimaryTheme.primaryGreyLight:''}}>
		{props.name &&
		<div className='framework-card'>
			<div className='heading'>
				<img className='icon-container' src={props.icon}/>
				<h3>{props.name}</h3>
			</div>
			<div style={{margin:'0.3rem 0'}}>
				<p>
				{props.reportName}
				</p>
			</div>
			<div className='body'>
				<Progress showInfo={false} percent={props.percent} strokeColor={conicColors} />
				<p>{AddCommaToNumber(props.percent)}% completed</p>
			</div>
		</div> }
	</div>
  )
}
