import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AddCommaToNumber } from '../../../../Config/Functions/UsefullFunctions';
import { graphStrokeColorArray } from '../../../../Config/Data/dashboardData';
export interface IBEFilterableBarGraphProps {
    data: any[];
}

export function BEFilterableBarGraph(props: IBEFilterableBarGraphProps) {
    const [keys, setKeys] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (!props.data || props.data?.length === 0) {
            return;
        }
        const dataUnit = props.data[0];
        let keys = [];
        for (let key in dataUnit) {
            if (key !== 'name') {
                keys.push(key);
            }
        }
        setKeys(keys);
    }, [props.data]);

    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart
                width={500}
                height={300}
                data={props.data}
                margin={{
                    right: 10
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={(value) => {
                    return AddCommaToNumber(value, false, true, true, 0);
                }} />
                <Tooltip />
                <Legend />
                {keys.map((key, index) => {
                    return (
                        <Bar
                            stackId={"a"}
                            dataKey={key}
                            fill={graphStrokeColorArray[index % graphStrokeColorArray?.length]}
                            key={index}
                        />
                    );
                })}
            </BarChart>
        </ResponsiveContainer>
    );
}
