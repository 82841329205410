import * as React from 'react';
import { useEffect,useState } from 'react';
import './style.scss';

interface SurveyCustomGraphProps {
  TableData:Array<TableDataTypes>
}
interface  TableDataTypes{
   stakeholder_priority:number,
   business_priority:number,
   topic_id:number,
   topic_name:string
}
export default function CustomGraph (props: SurveyCustomGraphProps) {
  const [bothHighData,setBothHighData]=useState<Array<TableDataTypes>>([]);
  const [bothLowData,setBothLowData]=useState<Array<TableDataTypes>>([]);
  const [stakeHighData,setStakeHighData]=useState<Array<TableDataTypes>>([]);
  const [businessHighData,setBusinessHighData]=useState<Array<TableDataTypes>>([]);

  useEffect(()=>{
      let tempBothHighData:Array<TableDataTypes>=[];
      let tempBothLowData:Array<TableDataTypes>=[];
      let tempStakeHighData:Array<TableDataTypes>=[];
      let tempBusinessHighData:Array<TableDataTypes>=[];
      props.TableData.map((item,index)=>{
          if(item.stakeholder_priority<props.TableData?.length/2 && item.business_priority>=props.TableData?.length/2){
              tempStakeHighData.push(item);
          }
          else if(item.stakeholder_priority<props.TableData?.length/2 && item.business_priority<props.TableData?.length/2){
              tempBothHighData.push(item);
          }
          else if(item.stakeholder_priority>=props.TableData?.length/2 && item.business_priority>=props.TableData?.length/2){
              tempBothLowData.push(item);
          }
          else if(item.stakeholder_priority>=props.TableData?.length/2 && item.business_priority<props.TableData?.length/2){
              tempBusinessHighData.push(item);
          }
      })
      setBothHighData(tempBothHighData);
      setBothLowData(tempBothLowData);
      setStakeHighData(tempStakeHighData);
      setBusinessHighData(tempBusinessHighData);
  },[props.TableData])

  return (
    <div className='survey-graph'>
        
    <div className='graph'>
    <p className='imp-sh'>Importance To Stakeholders</p>
    <p className='imp-b'>Importance To Business</p>
    <p className='hp-sh'>High Priority</p>
    <p className='hp-b'>High Priority</p>
    <p className='lp-b'>Low Priority</p>
    <p className='lp-sh'>Low Priority</p>
        <div className='container'>
            
            <div  className={'box '} >
                <div>
                    {
                        stakeHighData.map((item,index)=>{
                            return(
                                <div className='graph-item'>
                                    <p>{item.topic_name}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={'box '}>
                <div >
                {
                        bothHighData.map((item,index)=>{
                            return(
                                <div className='graph-item'>
                                    <p>{item.topic_name}</p>
                                </div>
                            )
                        })      
                    }
                </div>
            </div>
            <div  className={'box '}>
                <div >
                {
                        bothLowData.map((item,index)=>{
                            return(
                                <div  className='graph-item'>
                                    <p>{item.topic_name}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div  className={'box '} >
                <div   >
                {
                         businessHighData.map((item,index)=>{
                            return(
                                <div  className='graph-item'>
                                    <p>{item.topic_name}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>
</div>
  );
}
