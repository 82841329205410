import * as React from 'react';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { BETable } from '../../../../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import { BEButton } from '../../../../../../../Components/BEFormItems/BEButton';
import { CalculatedMetricsDrawer } from '../../../../../../../views/Onboarding/Step4/Drawers/CalculatedMetricDrawer';
import { NewDataEntry } from '../../../Drawers/NewDataEntry';
import { findBECodesOfAllChildren, getMetricsChildrenFromBEcode } from '../../../../../../../views/Actions/calculatedMetricsActions';
import { AddCommaToNumber } from '../../../../../../../Config/Functions/UsefullFunctions';
import BEStatusTag from '../../../../../../../Components/BEStatusTag';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import {  MyDerivedMetric } from '../../../../../../../Redux/Types/dataManagerTypes';
import { filterDataByMonthOrBU } from '../../../../../../Emissions/Actions';
import { PrimaryTheme } from '../../../../../../../Config/Theme/theames';
import './style.scss';
import { getTotalMetricValueDM } from '../../../../../Actions';

export interface IDerivedMetricDataTableProps {
  metric: MyDerivedMetric;
}

export function DerivedMetricDataTable(props: IDerivedMetricDataTableProps) {
  const tableDerivedMetricsDataDM = useTypedSelector(state => state.dataManager.metrics.tableDerivedMetricsDataDM);
  const myDerivedMetrics = useTypedSelector(state => state.onBoarding.metrics.myDerivedMetricsPerYear);
  const [openCalculatedMetricsDrawer, setOpenCalculatedMetricsDrawer] = React.useState(false);
  const [openNewDataEntry, setOpenNewDataEntry] = React.useState(false);
  const derivedMetricsRepresentationGraph = useTypedSelector((state) => state.settings.derivedMetricsRepresentationGraph);
  const selectedMetrics = useTypedSelector((state) => state.onBoarding.metrics.myMetricsPerYear);
  const [childMetrics, setChildMetrics] = React.useState<any[]>([]);
  const [tableData, setTableData] = React.useState<any[]>([]);
  const tableDataDM = useTypedSelector(state => state.dataManager.metrics.tableDataDM);
  const [totalComputedValue, setTotalComputedValue] = React.useState('');
  const metrics = useTypedSelector(state => state.onBoarding.metrics.directMetrics);
  const [containsEmission, setContainsEmission] = React.useState(false);
  const [emissionValue, setEmissionValue] = React.useState(0);
  const dispatch = useAppDispatch();

  const columns: ColumnsType<any> = [
    {
      title: 'S.No',
      dataIndex: '',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Metric Name',
      dataIndex: 'title',
      render: (text, record) => <>
        {text + " "}
        {record.type === "parent" && <BEStatusTag status='success'>C</BEStatusTag>}
      </>,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text, record) => AddCommaToNumber(Number(text), false, true, true) + ' ' + record.unit,
      sorter: (a, b) => a.value - b.value,
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
    },
    // {
    //   title: 'Unit',
    //   dataIndex: 'unit',
    //   key: 'unit',
    // },
  ];

  const calculateData = async () => {
    const BEcodes: string[] = findBECodesOfAllChildren(derivedMetricsRepresentationGraph?.data, [props.metric.bcode]);
    const parentBEcodes = BEcodes.filter((code) => derivedMetricsRepresentationGraph?.data?.find((item) => item.derived_metric === code));
    const childBEcodes = BEcodes.filter((code) => !parentBEcodes.includes(code));
    const children = childBEcodes.map((code) => selectedMetrics?.data
      ?.find((item: any) => item.bcode === code)).filter((item: any) => item);
    setChildMetrics(children);
    const immediateChildren = getMetricsChildrenFromBEcode(derivedMetricsRepresentationGraph?.data, props.metric.bcode);
    const parentImmediateChildren = Array.from(new Set(derivedMetricsRepresentationGraph?.data.filter((item) => immediateChildren.includes(item.derived_metric)).map((item) => item.derived_metric)))
      .map((code) => myDerivedMetrics?.data?.find((item: any) => item.bcode === code));
    const childImmediateChildren = immediateChildren.filter((code) => !parentBEcodes.includes(code)).map((code) => selectedMetrics?.data?.find((item: any) => item.bcode === code));
    let tempData: any[] = [];
    childImmediateChildren.forEach(async (child: any) => {
      if (child) {
        const data = tableDataDM[child?.id];
        const unit = metrics?.data?.find((item) => child?.bcode === item.bcode)?.unit;
        let newData = {
          type: 'child',
          title: child?.title,
          value: 0,
          cost: 0,
          unit: unit as string
        };
        let metric = metrics?.data?.find((item) => item.bcode === child?.bcode);
        const val = await dispatch(getTotalMetricValueDM({ ...metric, id: child?.id }));
        if (data) {
          newData = {
            type: 'child',
            title: child?.title,
            value: val,
            cost: data.reduce((acc: number, item: any) => acc + Number(item.cost), 0),
            unit: unit as string
          }
        }
        tempData.push(newData);
      }
    });
    parentImmediateChildren.forEach((child: any) => {
      if (child) {
        const data = tableDerivedMetricsDataDM?.data[child?.id];
        let newData = {
          type: 'parent',
          title: child?.title,
          value: 0,
          cost: 0,
          unit: child?.unit
        };
        if (data) {
          newData = {
            type: 'parent',
            title: child?.title,
            value: data?.reduce((acc: number, item: any) => acc + item.value, 0),
            cost: data?.reduce((acc: number, item: any) => acc + item?.cost ? item.cost : 0, 0),
            unit: child?.unit
          };
        }
        tempData.push(newData);
      }
    });
    setTableData(tempData);
  }

  const getEmissionValue = async () => {
    let res = await dispatch(filterDataByMonthOrBU(true, true));
    const scope1Data = res?.scope1Data;
    const scope2Data = res?.scope2Data;
    const scope3Data = res?.scope3Data;
    const bcode = props?.metric?.bcode;
    const data = [...scope1Data, ...scope2Data, ...scope3Data];
    const filteredData = data.filter((item) => item.bcode === bcode)
    if (filteredData?.length === 0) {
      setContainsEmission(false);
      setEmissionValue(0);
      return;
    }
    const value = filteredData.reduce((acc, item) => acc + item.tco2e, 0);
    if (value !== undefined || value !== null) {
      setContainsEmission(true);
      const emissionValue = AddCommaToNumber(Number(value), false, true, true, 3);
      setEmissionValue(Number(emissionValue));
    } else {
      setContainsEmission(false);
      setEmissionValue(0);
    }
  }

  React.useEffect(() => {
    calculateData();
    getEmissionValue();
  }, [props.metric, tableDataDM, tableDerivedMetricsDataDM]);

  React.useEffect(() => {
    if (tableDerivedMetricsDataDM?.status === 'success') {
      const id = myDerivedMetrics?.data?.find((item) => item.bcode === props.metric.bcode)?.id as number;
      const data = tableDerivedMetricsDataDM?.data[id];
      let tempValue = data?.reduce((acc: number, item: any) => acc + item.value, 0)
      setTotalComputedValue(AddCommaToNumber(!isNaN(tempValue) ? tempValue : 0, false, true, true, 2, true));
    }
  }, [tableDerivedMetricsDataDM]);

  /* ***************************************************** */
  // Don't remove the below code - (Rahul Jagwani, 26-04-2024) 

  // const getCalculatedMetricsdata = async () => {
  //   dispatch(setTableDerivedMetricsDataDM({
  //     status: 'loading',
  //     data: tableDerivedMetricsDataDM?.data
  //   }));
  //   let tempTableData: any = {};

  //   let buBgs = businessUnitsRefs.filter((element: any) => element.business_group === currentBusinessGroup).map((element: any) => element.business_unit);
  //   const BEcodes: string[] = findBECodesOfAllChildren(derivedMetricsRepresentationGraph?.data, [props.metric.bcode]);
  //   const parentBEcodes = BEcodes.filter((code) => derivedMetricsRepresentationGraph?.data?.find((item) => item.derived_metric === code));
  //   const parentIds: number[] = parentBEcodes.map((code) => myDerivedMetrics?.data?.find((item: any) => item.bcode === code)?.ref_id).filter((item) => item !== undefined) as number[];

  //   await Promise.all([...parentIds.map(async (id) => {
  //     let data = await dispatch(getDerivedMetricDataById(currentYear, id));
  //     if (currentMonth !== 0 && currentMonth < 13) {
  //       tempTableData = { ...tempTableData, [id]: data.filter((item: any) => item.month === currentMonth) };
  //     } else if (currentMonth === 0) {
  //       tempTableData = { ...tempTableData, [id]: data };
  //     } else {
  //       const monthsOfQuarter = getMonthsByQuarter(currentMonth, yearEndMonth);
  //       tempTableData = { ...tempTableData, [id]: data.filter((item: any) => monthsOfQuarter.includes(item.month))};
  //     }

  //     if (currentBusinessGroup !== 0) {
  //       tempTableData[id] = tempTableData[id]?.filter((element: any) => buBgs.includes(element?.business_unit));
  //     } else if (currentBusinessUnit !== 0) {
  //       tempTableData[id] = tempTableData[id]?.filter((element: any) => element?.business_unit === currentBusinessUnit);
  //     }
  //   })])

  //filter out common data



  //   dispatch(setTableDerivedMetricsDataDM({
  //     status: 'success',
  //     data: { ...tableDerivedMetricsDataDM?.data, ...tempTableData}
  //   }));
  // }
  /* ***************************************************** */


  // React.useEffect(() => {
  //   if (currentYear === 0 && myDerivedMetrics?.status !== "success") return;
  //   getCalculatedMetricsdata();
  // }, [props.metric, tableDataDM]);

  return (
    <div style={{ padding: '1rem' }}>
      <div style={{ display: "flex", gap: "1rem", paddingBottom: "1rem" }}>
        <div style={{ flexGrow: 1 }} />
        <BEButton className='primary' size='large' onClick={() => setOpenNewDataEntry(true)}>+ New Data Entry</BEButton>
        <BEButton
          size='large'
          onClick={() => setOpenCalculatedMetricsDrawer(true)}
        >
          View Breakdown
        </BEButton>
      </div>
      <BETable
        loading={tableDerivedMetricsDataDM?.status === 'loading'}
        data={tableData}
        columns={columns}
      />
      {
        tableDerivedMetricsDataDM?.status === 'success' &&
        <div className='value-box'>
          <div className='values'>
            <p><b>Total Computed Value</b></p>
            <p>{totalComputedValue} {props.metric.unit}</p>
          </div>
          <div className='values' style={{ borderLeft: `1px solid ${PrimaryTheme.primaryGray}` }}>
            <p><b>Approved Value</b></p>
            <p>{totalComputedValue} {props.metric.unit}</p>
          </div>
          {containsEmission &&
            <div className='values' style={{ borderLeft: `1px solid ${PrimaryTheme.primaryGray}` }}>
              <p><b>Total Emissions</b></p>
              <p>{emissionValue} {' tCO2e'}</p>
            </div>
          }
        </div>
      }
      <CalculatedMetricsDrawer open={openCalculatedMetricsDrawer} setOpen={setOpenCalculatedMetricsDrawer} data={props.metric} forDataManager={true} />
      <NewDataEntry open={openNewDataEntry} setOpen={setOpenNewDataEntry} my_metrics={props.metric.id} metric={props.metric} isDerivedMetricsEntry={true} childMetricsData={childMetrics} />
    </div>
  );
}