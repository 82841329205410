import { Row, Col } from 'antd'
import React from 'react'
import { BEInput } from '../../../../Components/BEFormItems/BEInput'
import BEMultipleSelect from '../../../../Components/BEMultipleSelect'
import IntegrationCard from '../Components/IntegrationCard'
import { Outlet, useParams } from 'react-router-dom'
import IntegrationConfig from '../IntegrationConfig'
import '../styles.scss'
import { IntegrationType } from '..'


interface ConnectedIntegrationsProps{
	integrations: IntegrationType[]
	selectedIntegration: IntegrationType | null
	setSelectedIntegration: Function
}

const ConnectedIntegrations = (props: ConnectedIntegrationsProps) => {
  
  return (
	!props.selectedIntegration ? <div style={{ margin: "2rem" }}>
	<Row>
	  <Col span={8}>
		<BEInput search placeholder="Search for integrations" size="large" />
	  </Col>
	</Row>
	<div className='integration-containter'>
	  {
		props.integrations?.map((integration, index) => {
		  return <IntegrationCard onClick={()=> props.setSelectedIntegration(integration)} {...integration} key={integration.id} />
		})
	  }
	</div>
	</div> : <IntegrationConfig setSelectedIntegration={props.setSelectedIntegration} integration={props.selectedIntegration}/>
  )
}

export default ConnectedIntegrations