import * as React from 'react';
import "./style.scss";
import { BEButton } from '../../../../../../../../Components/BEFormItems/BEButton';
import { BECollapse } from '../../../../../../../../Components/BECollapse';
import { Type0Topic } from './Tables/Type0';
import { Type1Topic } from './Tables/Type1';
import { Type2Topic } from './Tables/Type2';
import { Type3Topic } from './Tables/Type3';
import { Type4Topic } from './Tables/Type4';
import { useTypedSelector } from '../../../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../../../Config/Hooks/useAppDispatch';
import type { TemplateSelectedTopicTypes } from '../../../../../../../../Redux/Types/reportingTypes';
import { TopicEvidanceTable } from './EvidenceTable';
import BEStatusTag from '../../../../../../../../Components/BEStatusTag';

import { AssignDepartmentByReportDrawer } from '../../../../Assign Department';
import { deleteOmittedReportTopics, getAssignedDepartmentByReportTopics, getReportActivityActions, getReportChats, patchReportTemplate, postOmittedReportTopics } from '../../../../../../Actions/template';
import { Checkbox, Col, Modal, Row } from 'antd';
import { GresbMain } from '../../../../../../Components/GresbComponents/GresbMain';
import { allEntityAccordianStatus } from '../../../../../../Actions/gresbActions';
import BEChat from '../../../../../../../../Components/BEChat';
import UsersIcon from '../../../../../../../../Components/BEIcons/UsersIcon';
import { BETabs } from '../../../../../../../../Components/BETabs';
import ExpandIcon from '../../../../../../../../Components/BEIcons/ExpandIcon';
import SwitchIcon from '../../../../../../../../Components/BEIcons/SwitchIcon';
import { PrimaryTheme } from '../../../../../../../../Config/Theme/theames';
import BECollab from '../../../../../../../../Components/BECollab';
import EyeIcon from '../../../../../../../../Components/BEIcons/EyeIcon';
import { InviteCollaborator } from '../../../../InviteCollaborator';
import InfoIcon from '../../../../../../../../Components/BEIcons/InfoIcon';
import { TopicDescriptionDrawer } from '../../../../TopicDescription';
import QuestionMark from '../../../../../../../../Components/BEIcons/QuestionMarkIcon';
import store from '../../../../../../../../Redux/store';
import { current } from '@reduxjs/toolkit';
import { GlobalLoader } from '../../../../../../../../Components/GlobalLoader';


export interface IChildrenRightBodyProps {
}

interface ReportTopicStatusMapType {
    key: number,
    value: string,
    status: 'success' | 'warning' | 'error' | 'info'
}
export const ReportTopicStatusMap: ReportTopicStatusMapType[] = [
    { key: 0, value: 'Pending', status: 'warning' },
    { key: 1, value: 'Completed', status: 'success' },
    { key: 2, value: 'Approved', status: 'success' },
    { key: 3, value: 'Rejected', status: 'error' },
]

export function ChildrenRightTabs(props: IChildrenRightBodyProps) {
    const dispatch = useAppDispatch();
    const loading = useTypedSelector(state => state.reporting.loading);
    const currentCategory = useTypedSelector(state => state.reporting.selectedTemplateCategory);
    const currentSection = useTypedSelector(state => state.reporting.selectedTemplateSection);
    const currentGRESBSection = useTypedSelector(state => state.reporting.currentGRESBSection);
    const reportData = useTypedSelector(state => state.reporting.reportData);
    const users = useTypedSelector(state => state.userMgmt);
    const gresbEntityAccordionStatus = useTypedSelector(state => state.reporting.gresbEntityAccordionStatus);
    const currentDimension = useTypedSelector(state => state.reporting.selectedTemplateDimension);
    const currentReport = useTypedSelector(state => state.reporting.currentReport);
    const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
    const omittedReportTopics = useTypedSelector(state => state.reporting.omittedReportTopics);
    const assignReportToUsers = useTypedSelector(state => state.reporting.assignReportToUsers);
    const templates = useTypedSelector(state => state.reporting.TemplateDrafts);
    const topicStatus = useTypedSelector(state => state.reporting.topicStatus);

    const chats = useTypedSelector(state =>state.reporting.reportChats)
    const [openAssignDepartment, setOpenAssignDepartment] = React.useState<boolean>(false);
    const [showInviteCollaborator, setShowInviteCollaborator] = React.useState<boolean>(false);
    const [currentUniqueCode, setCurrentUniqueCode] = React.useState<string>('');

    const [closeCollapse, setCloseCollapse] = React.useState<boolean>(false);
    const [openActivityModal,SetOpenActivityModal] = React.useState<boolean>(false)
    const [isActivitiesAndEvidencesOpen,setIsActivitiesAndEvidencesOpen] = React.useState<boolean>(false)
    const activityActions = useTypedSelector(state => state.reporting.reportActivityActions);
    const [isDescriptionDrawerOpen, setIsDescriptionDrawerOpen] = React.useState<boolean>(false);
    const [currentDescriptionData, setCurrentDescriptionData] = React.useState<{title:string,description:string}>({title:'',description:''});
    const role = useTypedSelector((state) => state.user.userRole);

    React.useEffect(() => {
        dispatch(getReportChats())
        dispatch(getReportActivityActions())
     },[])

    React.useEffect(() => {
        dispatch(getAssignedDepartmentByReportTopics());
    }, []);

    React.useEffect(() => {
        dispatch(allEntityAccordianStatus());
    }, [reportData, currentReport]);

    React.useEffect(() => {
        setCloseCollapse(true);
    }, [currentCategory, currentSection, currentDimension, currentReport]);

    if(loading) return <GlobalLoader height='100vh' size='large'/>
    return (
        <div className='children-right'>
            <div className='data-table-box'>
                {
                    // uneque topics for the selected category
                    Array.from(new Set(selectedTopics?.data
                        .filter(
                            (item: TemplateSelectedTopicTypes) => (item.category === currentCategory && item.section === currentSection && (item.dimension === currentDimension || !currentDimension)))
                        .sort((a, b) => a.topic_id - b.topic_id)
                        .map((item: TemplateSelectedTopicTypes) => item.unique_code)))
                        .map((unique_code: string) => {
                            let topic = selectedTopics?.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)
                                .sort((a, b) => a.topic_id - b.topic_id)[0]!.topic; // topic up down
                            let disclosureCode = selectedTopics?.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0]!.disclosure_code;
                            let checked = omittedReportTopics?.data?.find((item) => item.topic_id === selectedTopics?.data?.find((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)?.id
                                && item.report === currentReport?.id && item.section === currentSection) ? true : false
                            let gresbStatus: any = gresbEntityAccordionStatus?.data[unique_code!];
                            const description = selectedTopics?.data?.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0]!.guidance;
                            return (
                                <BECollapse
                                    closeCollapse={checked || closeCollapse}
                                    heading={
                                        <div className='topic-heading'>
                                            <p style={{lineHeight:'1.5rem'}}>
                                                {
                                                    disclosureCode !== 'nan' ?
                                                        disclosureCode + ' ' + topic
                                                        : topic
                                                } 
                                                 <span 
                                                style={{display:'inline-block',height:'1rem', width:'1rem',cursor:'pointer',margin:'0 0.5rem',transform:'translate(0px,3px)'}} 
                                                onClick={()=> {
                                                    setCurrentDescriptionData({title:topic,description:description ? description : ''});
                                                    setIsDescriptionDrawerOpen(true)}
                                                }
                                            >
                                                {description && description !== 'nan' ? <QuestionMark  stroke={PrimaryTheme.primaryGray} />: null}
                                            </span>
                                            </p>
                                           
                                        </div>
                                    }
                                    headingRightContent={
                                        <div className='right-heading'>
                                            {checked ?
                                                <BEStatusTag status='info'>
                                                    Omitted
                                                </BEStatusTag> :
                                                // templateDrafts?.data?.find((item) => item.id === currentReport?.template)?.framework !== 'GRESB' &&
                                                currentGRESBSection !== 'entity' ?
                                                    <>
                                                        <BEStatusTag status={
                                                            !topicStatus?.data?.find((item) => item.unique_code === unique_code) ? ReportTopicStatusMap[0!]?.status :
                                                                ReportTopicStatusMap?.find((item) => item.key === topicStatus?.data?.find((item) => item.unique_code === unique_code)!?.status)!?.status
                                                        }>
                                                            {
                                                                !topicStatus?.data?.find((item) => item.unique_code === unique_code) ? ReportTopicStatusMap[0].value :
                                                                    ReportTopicStatusMap?.find((item) => item.key === topicStatus?.data?.find((item) => item.unique_code === unique_code)!?.status)!?.value
                                                            }
                                                        </BEStatusTag>

                                                        <BEStatusTag status='info'>
                                                            {
                                                                selectedTopics?.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0!]?.table_type !== 0 ? 'Table' :
                                                                    selectedTopics?.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0!]?.unit === 'Description' ? 'Description' : 'Number'
                                                            }
                                                        </BEStatusTag>
                                                    </>
                                                    :
                                                    <>
                                                        <BEStatusTag status={gresbStatus ? 'success' : 'warning'}>
                                                            {gresbStatus ? 'Completed' : 'Pending'}
                                                        </BEStatusTag>
                                                    </>
                                            }
                                        </div>
                                    }
                                    headingStyle={{ fontWeight: "600", justifyContent: 'space-between' }}
                                >
                                    {
                                        templates?.data?.find((item) => item.id === currentReport?.template)?.framework !== 'GRESB' ?
                                            <div>
                                                <Row style={{ margin: "1rem" }}>
                                                    <Col span={6} style={{ fontWeight: "500" }}>
                                                        <Checkbox
                                                            checked={checked}
                                                            onChange={
                                                                async (e) => {
                                                                    const topic = selectedTopics?.data?.find((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)?.id;
                                                                    const report = currentReport?.id;
                                                                    const section = currentSection;
                                                                    const data = [{
                                                                        topic_id: topic,
                                                                        report: report,
                                                                        section: section
                                                                    }];
                                                                    if (!checked) {
                                                                        await dispatch(postOmittedReportTopics(data));
                                                                    } else {
                                                                        const omittedReportTopicId = omittedReportTopics?.data
                                                                            ?.find((item) => item.topic_id === topic
                                                                                && item.report === report
                                                                                && item.section === section
                                                                            )?.id;
                                                                        await dispatch(deleteOmittedReportTopics(omittedReportTopicId));
                                                                        //change stage to in-progress if the topic is not omitted
                                                                        if(currentReport && currentReport?.stage >= 3 ){
                                                                            await dispatch(patchReportTemplate(report, { stage: 2 }, true))
                                                                        }
                                                                    }
                                                                }}>
                                                            Topic is not applicable to the business
                                                        </Checkbox>
                                                    </Col>
                                                    <Col span={18}>
                                                        {
                                                            (role === 'SUPER_ADMIN' || role === 'ADMIN' || role === 'ESG_CONSULTANT') &&
                                                            <div
                                                                style={{ float: "right", display: 'flex', alignItems: 'center', gap: "0.5rem" }}
                                                            >
                                                                {
                                                                    // assignedDepartments?.length === 0 ?
                                                                    assignReportToUsers?.data.filter((item) => item.unique_code === unique_code
                                                                        && item.report === currentReport?.id)?.length === 0 ?
                                                                        <span style={{ fontWeight: 'bold' }}>
                                                                            Assign
                                                                        </span>
                                                                        :
                                                                        <BECollab
                                                                            users={
                                                                                assignReportToUsers?.data.filter((item) => item.unique_code === unique_code
                                                                                    && item.report === currentReport?.id)
                                                                                    .map((item, index) => {
                                                                                        const userInfo = users.data.find((user) => user.id === item.user);
                                                                                        return {
                                                                                            id: index,
                                                                                            name: userInfo?.email,
                                                                                            avatarUrl: userInfo?.profile_pic
                                                                                        }
                                                                                    })
                                                                                // assignedDepartments.map((dept, index) => {
                                                                                //     return {
                                                                                //         id: index,
                                                                                //         name: dept
                                                                                //     }
                                                                                // })
                                                                            }
                                                                        />
                                                                }
                                                                <span style={{ color: PrimaryTheme.primaryGreyLight, fontSize: "1.5rem" }} >
                                                                    |
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        backgroundColor: PrimaryTheme.primaryGreyLight,
                                                                        height: "1.75rem",
                                                                        width: "1.75rem",
                                                                        borderRadius: "50%",
                                                                        cursor: "pointer",
                                                                        display: "flex",
                                                                        fontSize: "1.5rem",
                                                                        justifyContent:'center',
                                                                        alignItems:'center'
                                                                    }}
                                                                    onClick={() => {
                                                                        setCurrentUniqueCode(unique_code);
                                                                        setShowInviteCollaborator(true);
                                                                    }}
                                                                >
                                                                    <span>
                                                                        {/* {assignedDepartments?.length === 3 ? <EyeIcon inheritSize stroke={PrimaryTheme.primaryGreyLight} /> : "+"} */}
                                                                        {false
                                                                            ? <EyeIcon inheritSize stroke={PrimaryTheme.primaryGreyLight} /> : "+"}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                                <div className='data-table'>
                                                    {
                                                        // render the table based on the table type
                                                        selectedTopics?.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0]!.table_type === 0 ?
                                                            <Type0Topic uniqueCode={unique_code} nonEditable={role === 'AUDITOR'} />
                                                            : selectedTopics?.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0]!.table_type === 1 ?
                                                                <Type1Topic uniqueCode={unique_code} nonEditable={role === 'AUDITOR'} />
                                                                : selectedTopics?.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0]!.table_type === 3 ?
                                                                    <Type3Topic uniqueCode={unique_code} nonEditable={role === 'AUDITOR'} />
                                                                    : selectedTopics?.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0!]?.unit === 'Checkbox' ?
                                                                        <Type4Topic uniqueCode={unique_code} nonEditable={role === 'AUDITOR'} />
                                                                        : selectedTopics?.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0]!.table_type === 2 ?
                                                                            <Type2Topic uniqueCode={unique_code} nonEditable={role === 'AUDITOR'} />
                                                                            : null

                                                    }
                                                </div>
                                                <div className='activity-toggle-btn' 
                                                 onClick={()=>setIsActivitiesAndEvidencesOpen((prev)=>!prev)}>
                                                    <p>Activities and evidence</p> 
                                                    <div 
                                                        className='icon' 
                                                        style={{transform: `${isActivitiesAndEvidencesOpen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)'}` }}
                                                    > 
                                                        <SwitchIcon inheritSize stroke={PrimaryTheme.primaryBlack}/>
                                                    </div>
                                                </div>
                                                {isActivitiesAndEvidencesOpen &&
                                                <>
                                                    <div style={{padding:'1rem'}}>
                                                        <BETabs 
                                                            tabBarExtraContent={<BEButton style={{padding:'0.3rem'}} onClick={()=>SetOpenActivityModal(true)}><span style={{height:'1rem'}}><ExpandIcon inheritSize/></span></BEButton>}
                                                            items={
                                                            [
                                                                {
                                                                    key:'1',
                                                                    label:'Activity',
                                                                    children: <BEChat
                                                                        actions={activityActions.data.filter((action) => action.unique_code == unique_code
                                                                            && action.report == currentReport?.id
                                                                        )}
                                                                        chats={chats.data.filter(chat => chat.unique_code == unique_code
                                                                            && chat.report == currentReport?.id
                                                                        )}
                                                                        height={'600px'}
                                                                        uniqueCode={unique_code}
                                                                    />
                                                                },
                                                                {
                                                                    key:'2',
                                                                    label:'Evidences',
                                                                    children: <TopicEvidanceTable uniqueCode={unique_code} />
                                                                },

                                                            ]
                                                            }
                                                        />
                                                    
                                                    </div>
                                                    <Modal
                                                        width={'100%'}
                                                        // style={{top:'2rem'}}
                                                        centered
                                                        open={openActivityModal}
                                                        onCancel={()=>SetOpenActivityModal(false)}
                                                    >
                                                    <div style={{padding:'1rem'}}>
                                                        <BETabs 
                                                            items={
                                                            [
                                                                {
                                                                    key:'1',
                                                                    label:'Activity',
                                                                    children: <BEChat
                                                                        actions={activityActions.data.filter((action) => action.unique_code == unique_code
                                                                            && action.report == currentReport?.id
                                                                        )}
                                                                        chats={chats.data.filter(chat => chat.unique_code == unique_code
                                                                            && chat.report == currentReport?.id
                                                                        )}
                                                                        height={'900px'}
                                                                        uniqueCode={unique_code}
                                                                    />
                                                                },
                                                                {
                                                                    key:'2',
                                                                    label:'Evidences',
                                                                    children: <TopicEvidanceTable uniqueCode={unique_code} />
                                                                },

                                                            ]
                                                            }
                                                        />
                                                    
                                                    </div>
                                                    </Modal>
                                                </>
                                                }
                                            </div>
                                            :
                                            <GresbMain uniqueCode={unique_code} />
                                    }
                                </BECollapse>
                            )

                        })
                }
            </div>
            <InviteCollaborator
                open={showInviteCollaborator}
                setOpen={setShowInviteCollaborator}
                uniqueCode={currentUniqueCode}
            />
            <TopicDescriptionDrawer descriptionData={currentDescriptionData} open={isDescriptionDrawerOpen} setOpen={setIsDescriptionDrawerOpen} />
        </div>
    );
}
