import * as React from 'react';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { KPIData } from './KPIData';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import { DerivedMetricData } from './KPIData/DerivedMetricData';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { AssignDepartmentDrawer } from '../Drawers/AssignDepartment';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import EyeIcon from '../../../../../Components/BEIcons/EyeIcon';
import BECollab from '../../../../../Components/BECollab';
import { MetricDescriptionDrawer } from '../Drawers/MetricDescriptionDrawer';
export interface IKPIdataProps {
}

export function KPISdata(props: IKPIdataProps) {
  const currentPillar = useTypedSelector(state => state.onBoarding.metrics.currentPillar)
  const currentCategory = useTypedSelector(state => state.onBoarding.metrics.currentCategory)
  const combinedSelectedMetricsPerYear = useTypedSelector(state => state.onBoarding.metrics.combinedSelectedMetricsPerYear);
  const currentGroup = useTypedSelector(state => state.onBoarding.metrics.currentGroup)
  const searchTabMetrics = useTypedSelector(state => state.dataManager.metrics.searchTabMetrics);
  const assignedDepartmentByCategory = useTypedSelector(state => state.dataManager.assignedDepartmentByCategory);
  const [openAssignDepartment, setOpenAssignDepartment] = React.useState<boolean>(false);
  const [openMetricDescription, setOpenMetricDescription] = React.useState<{title:string,desc:string} | null>(null);
  const [assignedDepartments, setAssignedDepartments] = React.useState<any[]>([]);
  const [filteredMetrics, setFilteredMetrics] = React.useState<any>([]);
  const role = useTypedSelector((state) => state.user.userRole);

  React.useEffect(() => {
    if (currentPillar === searchTabMetrics.tabsValue || searchTabMetrics.tabsValue === "All") {
      setFilteredMetrics([...searchTabMetrics?.data].sort((a: any, b: any) => a?.hasOwnProperty('direct') ? -1 : 1));
    } else {
      setFilteredMetrics([...combinedSelectedMetricsPerYear?.data].sort((a: any, b: any) => a?.hasOwnProperty('direct') ? -1 : 1));
    }
  }, [combinedSelectedMetricsPerYear, searchTabMetrics?.data]);

  React.useEffect(() => {
    if (assignedDepartmentByCategory) {
      setAssignedDepartments(assignedDepartmentByCategory.data.filter((item) => item.category === currentGroup).map((met) => met.department));
    }
  }, [assignedDepartmentByCategory, currentPillar, currentCategory, currentGroup]);



  return (
    <div className='kpis-data'>
      <BEInput placeholder='Search KPIs'
        search
        style={{ width: '313px', margin: '2rem 0 1rem 0' }} size='large'
        onChange={(e: any) => {
          const value = e.target.value;
          setFilteredMetrics(combinedSelectedMetricsPerYear?.data?.filter((item: any) => item.title?.toLowerCase().includes(value?.toLowerCase())))
        }}
      />
      {
        (role === 'SUPER_ADMIN' || role === 'ADMIN' || role === 'ESG_CONSULTANT') &&
        <div
          style={{ float: "right", margin: '2rem 0 1rem 0', display: 'flex', alignItems: 'center', gap: "0.5rem" }}
        >
          {
            assignedDepartments?.length === 0 ?
              <span style={{ fontWeight: 'bold' }}>
                Assign
              </span>
              :
              <BECollab
                forDepartment
                users={
                  assignedDepartments.map((dept, index) => {
                    return {
                      id: index,
                      name: dept
                    }
                  })
                }
              />
          }
          <span style={{ color: PrimaryTheme.primaryGreyLight, fontSize: "1.5rem" }} >
            |
          </span>
          <span
            style={{
              backgroundColor: PrimaryTheme.primaryGreyLight,
              height: "1.75rem",
              width: "1.75rem",
              borderRadius: "50%",
              cursor: "pointer",
              display: "flex",
              fontSize: "1.5rem",
              position: "relative"
            }}
            onClick={() => setOpenAssignDepartment(true)}
          >
            <span style={{ height: "1.5rem", position: "absolute", bottom: "0.6rem", right: "0.35rem" }}>
              {assignedDepartments?.length === 3 ? <EyeIcon inheritSize stroke={PrimaryTheme.primaryGreyLight} /> : "+"}
            </span>
          </span>
        </div>
      }
      {
        (currentCategory !== 'Energy' && currentCategory !== 'Waste' && currentCategory !== 'Water') ?
          filteredMetrics?.filter((item: any) => item.pillars === currentPillar && item.category === currentCategory && item.group === currentGroup)
            .map((item: any, index: any) => {
              // if (item.applicable_to_all === true || currentBusinessUnit === 0 || businessUnits?.data?.find((unit: any) => unit.id === currentBusinessUnit)?.is_headquarter === true) {
              if (item?.hasOwnProperty('direct'))
                return (
                  <DerivedMetricData key={index} metric={item} setOpenMetricDescription={setOpenMetricDescription} />
                )
              else
                return (
                  <KPIData key={index} kpi={item} setOpenMetricDescription={setOpenMetricDescription} />
                )
              // }
            })
          :
          filteredMetrics?.filter((item: any) => item.pillars === currentPillar && item.category === currentCategory && item.tab === currentGroup)
            .map((item: any, index: any) => {
              // if (item.applicable_to_all === true || currentBusinessUnit === 0 || businessUnits?.data?.find((unit: any) => unit.id === currentBusinessUnit)?.is_headquarter === true) {
              if (item?.hasOwnProperty('direct'))
                return (
                  <DerivedMetricData key={index} metric={item} setOpenMetricDescription={setOpenMetricDescription} />
                )
              else
                return (
                  <KPIData key={index} kpi={item}  setOpenMetricDescription={setOpenMetricDescription}/>
                )
              // }
            })
      }
      <AssignDepartmentDrawer
        open={openAssignDepartment}
        setOpen={setOpenAssignDepartment}
      />
      <MetricDescriptionDrawer
        data={openMetricDescription}
        setData={setOpenMetricDescription}
      />
    </div>
  );
}
