import React, { useRef, useState } from "react";
import UploadIcon from "../BEIcons/UploadIcon";
import UploadedIcon from "../BEIcons/UploadedIcon";
import { PrimaryTheme } from "../../Config/Theme/theames";
import "./styles.scss";
import DeleteIcon from "../BEIcons/DeleteIcon";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from "antd";

interface FileUploadProps {
  text?: string; // text to be shown
  hint?: string; // hint on file format/size
  handleFileChange: Function;
  required?: boolean;
  fileType?: string | string[]; // file format
  fileSize?: number; // max file size in bytes
  children?: React.ReactNode
  loading?: boolean;
  refreshUpload?: boolean;
  width?: string;
  height?: string;
  maxImageWidth?: number; // Maximum width for image compression
  imageQuality?: number; // Image compression quality (0-1)
}

// Utility function to compress image
const compressImage = async (file: File, maxWidth: number, quality: number): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        // Calculate new dimensions maintaining aspect ratio
        if (width > maxWidth) {
          height = (height * maxWidth) / width;
          width = maxWidth;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);

        // Convert canvas to blob
        canvas.toBlob(
          (blob) => {
            if (blob) {
              // Create new file from blob
              const compressedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              resolve(compressedFile);
            } else {
              reject(new Error('Canvas to Blob conversion failed'));
            }
          },
          file.type,
          quality
        );
      };
      img.onerror = reject;
    };
    reader.onerror = reject;
  });
};

const FileUpload = (props: FileUploadProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploaded, setUploaded] = useState(false); // track if a file was uploaded
  const [success, setSuccess] = useState(true); // track if upload was a success
  const [message, setMessage] = useState(""); // validation error message
  const [fileName, setFileName] = useState(""); // uploaded file name
  const [drag, setDrag] = useState(false); // trach drag event
  const [compressing, setCompressing] = useState(false); // track image compression

  const handleCompression = async (file: File): Promise<File> => {
    if (!file) return file;

    // Handle image compression
    if (file.type.startsWith('image/')) {
      try {
        setCompressing(true);
        const compressedFile = await compressImage(
          file,
          props.maxImageWidth || 1920, // Default max width of 1920px
          props.imageQuality || 0.8 // Default quality of 0.8
        );
        setCompressing(false);
        return compressedFile;
      } catch (error) {
        console.error('Image compression failed:', error);
        setCompressing(false);
        return file; // Return original file if compression fails
      }
    }

    // Return original file for non-image types
    return file;
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleFileChange(null);
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      try {
        const compressedFile = await handleCompression(file);
        if (!validateFile(compressedFile)) return;
        props.handleFileChange(compressedFile);
        setUploaded(true);
        setSuccess(true);
        setFileName(compressedFile.name);

        // Log compression results
        console.log('Original size:', (file.size / 1024 / 1024).toFixed(2) + 'MB');
        console.log('Compressed size:', (compressedFile.size / 1024 / 1024).toFixed(2) + 'MB');
      } catch (error) {
        console.error('File processing failed:', error);
        setMessage("File processing failed. Please try again.");
        setSuccess(false);
      }
    }
  };

  const validateFile = (file: any) => {
    if (!file) return false
    if (
      props.fileType === "image" &&
      !(file.type === "image/jpeg" || file.type === "image/png")
    ) {
      setMessage("File format should be in .png, .jpeg, .jpg! ");
      setSuccess(false);
      setDrag(false);
      return false;
    }
    if (props.fileType === "file" && !(file.type === "application/pdf")) {
      setMessage("File format should be in .pdf! ");
      setSuccess(false);
      setDrag(false);
      return false;
    }
    if (props.fileType === "excel") {
      if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        setMessage("File format should be in .xlsx! ");
        setSuccess(false);
        setDrag(false);
        return false;
      }
    }

    if (typeof props.fileType !== 'string') {
      const fileTypeCheck = props.fileType?.find((item: string) => item === file.type)
      if (!fileTypeCheck) {
        setMessage("File should be in excel, image, pdf");
        setSuccess(false);
        setDrag(false);
        return false;
      }
    }

    if (props.fileSize && file.size > props.fileSize) {
      setMessage(`File should be less than ${props.fileSize / 1000000} MB`);
      setSuccess(false);
      setDrag(false);
      return false;
    }
    return true;
  };

  const onBoxClick = (e: any) => {
    if (e.target.parentNode.className === 'delete-btn') return
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    props.handleFileChange(null);

    if (event.dataTransfer.items && event.dataTransfer.items?.length > 0) {
      const fileItem = event.dataTransfer.items[0];
      if (fileItem.kind === "file") {
        const file = fileItem.getAsFile();
        if (file) {
          try {
            const compressedFile = await handleCompression(file);
            if (!validateFile(compressedFile)) return;
            props.handleFileChange(compressedFile);
            setUploaded(true);
            setSuccess(true);
            setFileName(compressedFile.name);
            setDrag(true);
          } catch (error) {
            console.error('File processing failed:', error);
            setMessage("File processing failed. Please try again.");
            setSuccess(false);
          }
        }
      }
      event.dataTransfer.clearData();
    }
  };

  React.useEffect(() => {
    setUploaded(false);
    setMessage("");
    setSuccess(true);
    setFileName("");
    setDrag(false);
  }, [props.refreshUpload])

  return (
    <>
      <div style={{ width: props.width ? props.width : "330px", height: props.height ? props.height : '186px' }}
        className={`${success ? "upload-box" : "upload-box-fail"} ${drag || props.loading ? "drag-box" : ""
          } ${uploaded ? "drag-box" : ""}`}
        onClick={onBoxClick}
        onDrop={onDrop}
        onDragOver={(e) => {
          e.preventDefault();
          setDrag(true);
        }}
        onDragLeave={() => {
          setDrag(false);
        }}
      >
        {
          props.loading ?
            <div className="loading">
              <Spin indicator={<LoadingOutlined style={{ fontSize: '2.5rem', color: PrimaryTheme.primaryGreenDark }} spin />} />
              <strong className="uploading-text">Uploading...</strong>
            </div>
            :
            <>
              {uploaded && (
                <div
                  className="delete-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    props.handleFileChange(null);
                    setSuccess(true);
                    setUploaded(false);
                    setDrag(false);
                  }}
                >
                  <DeleteIcon inheritSize stroke="red" />
                </div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="input"
              />
              <div className="icon">
                {uploaded ? (
                  <UploadedIcon stroke={PrimaryTheme.primaryGreenDark} inheritSize />
                ) : (
                  <UploadIcon stroke={PrimaryTheme.primaryGreenDark} inheritSize />
                )}
              </div>
              {uploaded ? (
                <p className="text">Uploaded: {fileName}</p>
              ) : (
                <p className="text">
                  {props.text}{" "}
                  {props.required && <span style={{ color: "red" }}>*</span>}
                </p>
              )}
              <p className={`${success ? "hint" : "hint-fail"}`}>
                {success ? props.hint : message}
              </p>
              {!success && <div className="browse-file">Browse File</div>}
            </>
        }
      </div>
    </>
  );
};

FileUpload.defaultProps = {
  text: "Click or drag file to this area to upload",
  hint: "Upload a file in the format .png or .jpg under the size of 2 MB",
  required: true,
  fileType: "image",
  fileSize: 2000000,
};

export default FileUpload;
