import React, { useEffect, useState } from 'react'
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { Col, Form, Row, Checkbox } from 'antd';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import BEMultipleSelect from '../../../../../Components/BEMultipleSelect';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { getBusinessGroups } from '../../../../../views/Actions/BusinessGroupActions';
import { getBusinessUnits } from '../../../../../views/Actions/BusinessUnitActions';
import { ColumnsType } from 'antd/es/table';
import { BETable } from '../../../../../Components/BETable';
import BusinessTableItem from '../../../Components/BusinessTableItem';
import SwitchIcon from '../../../../../Components/BEIcons/SwitchIcon';
import { getAllUsers } from '../../../../../views/Actions/UsersManagementActions';
import BEDatePicker from '../../../../../Components/BEDatePicker';
import { createReportTemplate, editReportTemplate, deleteReportBusinessUnit, getReportBusinessUnits, addReportBusinessUnits } from '../../../Actions/template';
import './style.scss'
import { BEMessage } from '../../../../../Components/BEMessage';
import dayjs from 'dayjs';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { createGRESBEntity, getGresbAuthorizationCode, getGresbPortalEntityData } from '../../../Actions/gresbActions';

interface AssignTemplateProps {
  template: any;
  report?: any;
  disabled?: boolean;
}

const AssignTemplate: React.ForwardRefRenderFunction<{ onButtonClick: () => void }, AssignTemplateProps> = (props, ref) => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const businessGroups = useTypedSelector(state => state.entity.BusinessGroups)
  const businessUnits = useTypedSelector(state => state.entity.BusinessUnits)
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const reportingYearEnd = useTypedSelector(state => state.companies.companyDetails?.data?.reporting_year_end);
  const users = useTypedSelector(state => state.userMgmt)
  const [selectedBus, setSelectedBus] = useState<any>([])
  const [openedBgs, setOpenedBgs] = useState<any>([])
  const [loading, setLoading] = useState(false);
  const reportBusinessUnits = useTypedSelector(state => state.reporting.reportBusinessUnits);
  const businessUnitRefs = useTypedSelector(state => state.entity.BusinessUnitRefs);
  const currentTemplate = useTypedSelector(state => state.reporting.currentTemplate);
  const financialYears = useTypedSelector(state => state.common.financialYears) 
  const colSpan = props.report ? 12 : 5;

  const getReportBU = async () => {
    let res = await dispatch(getReportBusinessUnits(props.report.id))
    if (res) setSelectedBus(res.map((bu: any) => bu.business_unit))
  }

  useEffect(() => {
    if (props.report) {
      getReportBU();
      form.setFieldsValue({
        title: props.report?.name,
        metric: props.report?.reviewer,
        'from-date': props.report?.from_date ? dayjs(props.report?.from_date) : null,
        'to-date': props.report?.to_date ? dayjs(props.report?.to_date) : null,
      })
    }
    else form.resetFields();
  }, [props.report]);

  useEffect(() => {
    if (businessGroups?.status !== 'success') dispatch(getBusinessGroups());
    if (businessUnits?.status !== "success") dispatch(getBusinessUnits());
    if (users?.status !== 'success') dispatch(getAllUsers())
  }, []);

  const handleClickBG = (e: any) => {
    let selectedBUSOtherThanThisBG = selectedBus.filter((bu: any) => {
      return businessUnitRefs?.data.filter((ref: any) => ref.business_unit === bu && ref.business_group === e.target.value)?.length === 0
    })
    if (e.target.checked) {
      let busToAdd = businessUnitRefs?.data.filter((ref: any) => ref.business_group === e.target.value).map((ref: any) => ref.business_unit)
      setSelectedBus([...selectedBUSOtherThanThisBG, ...busToAdd])
    } else {
      setSelectedBus(selectedBUSOtherThanThisBG)
    }
  }

  const handleClickBU = (e: any) => {
    if (e.target.checked) {
      setSelectedBus([...selectedBus, e.target.value])
    } else {
      setSelectedBus(selectedBus.filter((bus: any) => bus !== e.target.value))
    }
  }

  const handleClickAll = (e: any) => {
    if (e.target.checked) {
      let allBusIds = businessUnits?.data.map((bu: any) => bu.id)
      setSelectedBus(allBusIds)
    } else {
      setSelectedBus([])
    }
  }

  const handleEdit = async (values: any) => {
    setLoading(true)
    let BUsToDelete: any = reportBusinessUnits?.data.filter((bu: any) => !selectedBus.includes(bu.business_unit))
    let BUsToAdd: any = selectedBus.filter((bu: any) => !reportBusinessUnits?.data.map((bu: any) => bu.business_unit).includes(bu))

    let body = {
      name: values.title,
      reviewer: values.metric,
      // from_date: dayjs(values['from-date']).startOf('month').format('YYYY-MM-DD'),
      // to_date: dayjs(values['to-date']).endOf('month').format('YYYY-MM-DD'),
      // from_date: `${currentYear}-04-01`,
      // to_date: `${currentYear + 1}-03-31`
    }
    let res: any = true
    if (res) {
      let flag: any = true;
      if (BUsToAdd?.length > 0) {
        let busToAdd = BUsToAdd.map((bu: any) => {
          return {
            business_unit: bu,
            esg_report: props.report.id
          }
        })
        flag = await dispatch(addReportBusinessUnits(busToAdd))
      }
      if (BUsToDelete?.length > 0) {
        for (let i = 0; i < BUsToDelete?.length; i++) {
          let res = await dispatch(deleteReportBusinessUnit(BUsToDelete[i].id))
          if (!res) {
            flag = false;
            break;
          }
        }
      }
      if (!flag) res = false;
    }
    if (res) res = await dispatch(editReportTemplate(props.report.id, body));
    if (res) {
      BEMessage.success('Report updated');
    }
    setLoading(false);
  }

  const checkDateBetweenCurrentFYValidator = (rule: any, value: any) => {
    // const selectedYear = dayjs(value).year()
    let month = reportingYearEnd?.split('-')[1];
    let date = reportingYearEnd?.split('-')[2];
    let yearEnd = `${month}-${date}`;
    const minFinYear = Math.min(...financialYears.data);
    const maxFinYear = Math.max(...financialYears.data);
    // const fromdate = form.getFieldValue('from-date')
    // const isDateOutOfYear = (year:number) => {
    //   if (value && dayjs(value).isBefore(dayjs(`${year}-${yearEnd}`)) || dayjs(value).isAfter(dayjs(`${year + 1}-${yearEnd}`))) {
    //     return Promise.reject(`Date should be in current financial year ${year}-${year+1}`);
    //   }
    //   return Promise.resolve();
    // }  
    // if(fromdate){
    //   const fromDateYear = dayjs(fromdate).year()
    //   const fromDateMonth = dayjs(fromdate).month()+1
    //   if(!financialYears.data.includes(fromDateYear)) return Promise.reject('Year should be in financial years')
    //   if(fromDateMonth <= Number(month)) {
    //     return isDateOutOfYear(fromDateYear - 1)
    //   }else{
    //     return isDateOutOfYear(fromDateYear);
    //   }
    // }else{
    //   const isYearInFinancialYears = financialYears.data.includes(selectedYear);
    //   if(isYearInFinancialYears){
    //     return isDateOutOfYear(selectedYear)
    //   }
    // }
    if (value && dayjs(value).isBefore(dayjs(`${minFinYear}-${yearEnd}`)) || dayjs(value).isAfter(dayjs(`${maxFinYear + 1 }-${yearEnd}`))) {
      return Promise.reject(`Date range should include only the months occuring between FY ${minFinYear} to FY ${maxFinYear + 1}.`);
    }
    return Promise.resolve();
  }


  const onFinish = async (values: any) => {
    if (selectedBus?.length === 0 && currentTemplate?.framework !== 'GRESB') {
      BEMessage.error('Please select atleast one business unit');
      return false;
    }
    let entityCreated: any = null;
    if (currentTemplate?.framework === 'GRESB') {
      entityCreated = await dispatch(createGRESBEntity(values.gresb_entity_name));
      if (!entityCreated) return false;
    }
    // if (!values['from-date'] || !values['to-date']) {
    //   BEMessage.error('Please select duration');
    //   return false;
    // }
    if (props.report) return handleEdit(values);
    setLoading(true)
    let body = {
      name: values.title,
      reviewer: values.metric,
      from_date: dayjs(values['from-date']).startOf('month').format('YYYY-MM-DD'),
      to_date: dayjs(values['to-date']).endOf('month').format('YYYY-MM-DD'),
      // from_date: `${currentYear}-04-01`,
      // to_date: `${currentYear + 1}-03-31`,
      template: props.template
    }
    await dispatch(createReportTemplate(body, selectedBus, entityCreated));
    setLoading(false);
    return true
  }

  const handleClickOpen = (id: any) => {
    if (openedBgs.includes(id)) {
      setOpenedBgs(openedBgs.filter((bg: any) => bg !== id))
    } else {
      setOpenedBgs([...openedBgs, id])
    }
  }

  const validateEndDate = (rule: any, value: any) => {
    if (value && form.getFieldValue('from-date') && dayjs(value).isBefore(dayjs(form.getFieldValue('from-date')))) {
      return Promise.reject('End date should be greater than start date');
    }
    return Promise.resolve();
  }

  // handle button click from templates to provoke the next step  *-
  const onButtonClick = async () => {
    try {
      console.log('selectedBus', selectedBus)
      await form.validateFields();
      return await onFinish(form.getFieldsValue());

    } catch (error) {
      BEMessage.error('Please fill all the required fields correctly!');
      return false;
    }
  }
  React.useImperativeHandle(ref, () => ({ onButtonClick }), [selectedBus]);
  return (
    // <BEDrawer
    //   width="45vw"
    //   open={props.open}
    //   setOpen={props.setOpen}
    //   heading="Assign Template"
    //   footer={
    //     <>
    //       <BEButton
    //         className="primary"
    //         size="large"
    //         onClick={() => {
    //           form.submit();
    //         }}
    //         loading={loading}
    //       >
    //         Assign
    //       </BEButton>
    //       <BEButton size="large" onClick={() => props.setOpen(false)}>
    //         Cancel
    //       </BEButton>
    //     </>
    //   }
    // >
    <div style={{ padding: props.report ? 0 : "0 1rem" }} className="assign-template">
      {loading ? <GlobalLoader height='20vh' /> :
        <>
          <div>
            <Form form={form} onFinish={onFinish}>
              <Row gutter={[5, 5]}>
                <Col span={colSpan}>
                  <LableRequired>Title</LableRequired>
                  <Form.Item
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a title for the report",
                      },
                    ]}
                  >
                    <BEInput disabled={props.disabled} size="large" placeholder="Report title" />
                  </Form.Item>
                </Col>
                {currentTemplate?.framework === 'GRESB' &&
                  <Col span={colSpan}>
                    <LableRequired>GRESB entity name</LableRequired>
                    <Form.Item
                      name="gresb_entity_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter name of the entity from GRESB portal",
                        },
                      ]}
                    >
                      <BEInput size="large" placeholder="GRESB entity name" />
                    </Form.Item>
                  </Col>}
                {/* <Col span={11}>
                <LableRequired>Baseline Value</LableRequired>
                <Form.Item name="value" rules={[{ required: true, message: 'Please enter a baseline value' }]}>
                  <BEInput type='number' size="large" placeholder="Enter baseline value here" />
                </Form.Item>
              </Col> */}
              </Row>

              <LableRequired>Duration of the report</LableRequired>
              <Row gutter={[5, 5]}>
                <Col span={colSpan}>
                  <Form.Item name="from-date"
                    rules={[
                      { required: true, message: 'Please select start date' },
                      { validator: checkDateBetweenCurrentFYValidator }
                    ]}
                  >
                    <BEDatePicker
                      placeholder='Select start month'
                      field="from-date"
                      form={form}
                      // format="DD/MM/YYYY"
                      picker={'month'}
                      disabled={props.report}
                    />
                  </Form.Item>
                </Col>
                <Col span={colSpan}>
                  <Form.Item name="to-date"
                    rules={[
                      { required: true, message: 'End date should not be empty and must be greater than start date', validator: validateEndDate },
                      { validator: checkDateBetweenCurrentFYValidator }
                    ]}
                  >
                    <BEDatePicker
                      placeholder='Select end month'
                      field="to-date"
                      form={form}
                      // format="DD/MM/YYYY"
                      picker={'month'}
                      disabled={props.report}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[5, 5]}>
                <Col span={colSpan}>
                  <LableRequired>Reviewer Name</LableRequired>
                  <Form.Item
                    name="metric"
                    rules={[
                      { required: true, message: "Please select reviewer name" },
                    ]}
                  >
                    <BEMultipleSelect
                      disabled={props.disabled}
                      data={users?.data.filter((user: any) => { return user.role !== 'CONTRIBUTOR' }).map((user: any) => {
                        return {
                          label: user.first_name + " " + user.last_name,
                          value: user.id,
                        };
                      })}
                      name="metric"
                      labelFeild="label"
                      valueFeild="value"
                      single
                      placeholder="Select reviewer"
                    />
                  </Form.Item>
                </Col>
                {/* <Col span={5}>
                  <LableRequired>Financial year</LableRequired>
                  <Form.Item>
                    <BEInput
                      disabled
                      size="large"
                      value={`${currentYear}-${currentYear + 1}`}
                    />
                  </Form.Item>
                </Col> */}
              </Row>
            </Form>
          </div>

          {/* business grps and units */}
          {currentTemplate?.framework !== 'GRESB' &&
            <div className="select-bus">
              <div className="business-group-top-heading">
                <div className="check-box">
                  <Checkbox
                    onClick={(e) => handleClickAll(e)}
                    checked={selectedBus.length === businessUnits?.data.length}
                    disabled={props.disabled}
                  />
                  <p>Business Groups</p>
                </div>
              </div>

              {
                businessGroups?.data.map((bg: any) => {
                  return (
                    <div className="business-group" style={{ borderBottom: `1px solid ${PrimaryTheme.primaryGreyLight}` }}>
                      <div className="business-group-heading">
                        <div className="check-box">
                          <Checkbox
                            disabled={
                              businessUnitRefs?.data.filter((ref: any) => ref.business_group === bg.id).length === 0 || props.disabled
                            }
                            value={bg.id}
                            checked={
                              businessUnitRefs?.data.filter((ref: any) => ref.business_group === bg.id).every((ref: any) => selectedBus.includes(ref.business_unit))
                              && businessUnitRefs?.data.filter((ref: any) => ref.business_group === bg.id)?.length > 0
                            }
                            onChange={(e) => handleClickBG(e)}
                          />
                          <p style={{ cursor: 'pointer' }} onClick={() => handleClickOpen(bg.id)}>{bg.name}</p>
                        </div>
                        {
                          businessUnitRefs?.data.filter((ref: any) => ref.business_group === bg.id)?.length > 0 &&
                          (
                            <div
                              className="switchs"
                              onClick={() => handleClickOpen(bg.id)}
                              style={{ cursor: 'pointer' }}
                            >
                              <SwitchIcon
                                inheritSize
                                stroke={PrimaryTheme.primaryGray}
                              />
                            </div>
                          )}
                      </div>
                      <div className="business-units">
                        {openedBgs.includes(bg.id) &&
                          businessUnitRefs?.data.filter((ref: any) => ref.business_group === bg.id).map((ref: any) => ref.business_unit)
                            .map((bu: any) => {
                              return (
                                <div className="check-box">
                                  <Checkbox
                                    checked={selectedBus.includes(bu)}
                                    onChange={(e) => handleClickBU(e)}
                                    value={bu}
                                    disabled={props.disabled}
                                  />
                                  <p>{
                                    businessUnits?.data?.find((unit: any) => unit.id === bu)?.name
                                  }</p>
                                </div>
                              );
                            })}
                      </div>
                    </div>
                  );
                })
              }
            </div>
          }
        </>
      }
    </div>
    // </BEDrawer>
  );
};
export default React.forwardRef(AssignTemplate);