import * as React from 'react';
import Scope2Table from '../Configurations/Scope1Table';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { BECollapse } from '../../../../Components/BECollapse';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { getScope2EmissionConfigurations, getScope2EmissionFactors } from '../../Actions';
import { ColumnsType } from 'antd/es/table';
import { NewBETable } from '../../../../Components/BETable/newBETable';
import { AddCustomEFDrawer } from '../../Components/AddCustomEFDrawer';
import { myMetricType } from '../../../../Redux/Types/dataManagerTypes';
import EditIcon from '../../../../Components/BEIcons/EditIcon';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { ApplyScope2EFDrawer } from '../../Components/ApplyScope2EFDrawer';
import './style.scss';
import { navigateTo } from '../../../../Navigation/NavigationManager';

export interface IScope2ConfigurationsProps {
}

const SCOPE_2_BCODES = ['BE008.14', 'BE008.18', 'BE008.19', 'BE026.9', 'BE026.10', 'BE026.7', 'BE026.8', 'BE008.26', 'BE008.27', 'BE008.28', 'BE008.29']

export function Scope2Configurations (props: IScope2ConfigurationsProps) {
  const dispatch = useAppDispatch();
  const scope2EmissionFactors = useTypedSelector(state => state.emissions.emissionFactors.scope2EmissionFactors)
  const scope2EmissionsConfigurations = useTypedSelector(state => state.emissions.configurations.scope2EmissionConfigurations)
  const myMetrics = useTypedSelector((state) => state.onBoarding.metrics.myMetrics);
  const [currentMetric, setCurrentMetric] = React.useState<myMetricType | null>(null)
  const [currentEF, setCurrentEF] = React.useState<any>(null)
  const [openAddEF, setOpenAddEF] = React.useState(false)
  const [openEditEF, setOpenEditEF] = React.useState(false)
  const [openApplyEF, setOpenApplyEF] = React.useState(false)
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);


  const columns:ColumnsType<any> = [
    {
      title: 'SR. NO.',
      render: (text, record, index) => index + 1,
      key: 'srNo',
    },
    {
      title: 'EMISSION FACTOR NAME',
      dataIndex: 'ef_name',
      key: 'ef_name',
    },
    {
      title: 'LOCATION',
      dataIndex: 'ef_location',
      key: 'ef_location',
    },
    {
      title: 'LOCATION-BASED EMISSION FACTOR (KGCO2E PER UNIT)',
      dataIndex: 'ef_value_lb',
      key: 'ef_value_lb',
    },
    {
      title: 'MARKET-BASED EMISSION FACTOR (KGCO2E PER UNIT)',
      dataIndex: 'ef_value_mb',
      key: 'ef_value_mb',
    },
    {
      title: 'ACTION',
      render: (text, record) => <div style={{display:'flex',gap:'1rem',alignItems:'center',cursor:`${record.type === 'Default' ? 'not-allowed' : 'pointer'}`}}>
        <div style={{ width: "1rem" }} onClick={() => {
          if(record.type === 'Default') return
          setCurrentEF(record)
          setOpenEditEF(true)
        }}>
          <EditIcon inheritSize={true} stroke={PrimaryTheme.primaryGreyDark} />
        </div>
        <BEButton
          size='small' className='secondary-green' 
          onClick={() => setOpenApplyEF(true)}
          onMouseEnter={() => setCurrentEF(record)}
        >
          Apply
        </BEButton>
        </div>
    }
  ]
  React.useEffect(() => {
      dispatch(getScope2EmissionFactors())
      dispatch(getScope2EmissionConfigurations())
  }, [])


  return (
    <div className='scope-2-configurations'>
      <br/>
      <div style={{ display: "flex", justifyContent:"flex-end" ,marginBottom:'1rem'}}>
        <BEButton
          size='large' className='primary'
          onClick={() => navigateTo('home/emissions/scope-2-configurations/summery')}
        >Configuration summary</BEButton>
      </div>
      {
        myMetrics.data.filter(metric => SCOPE_2_BCODES.includes(metric.bcode) && metric.year === currentYear).map((metric:any) => {
          return <div  onMouseEnter={() => setCurrentMetric(metric)} key={metric.bcode}>
                    < BECollapse heading={metric.title} 
                      headingStyle={{display:'flex',justifyContent:'space-between',alignItems:'center',fontWeight:'bold',height:'4rem'}}
                      headingRightContent={<div style={{display:'flex',gap:'1rem'}}>
                        <BEButton disabled>{metric.unit}</BEButton>
                        <BEButton className='secondary-green'
                          onClick={() => setOpenAddEF(true)}
                        >Add emision factor</BEButton>
                      </div>}
                    >
                      <div className='table-box'>
                        <NewBETable
                          rowClassName={(record:any) => record.type === 'Default' ? 'default-row' : ''}
                          loading={scope2EmissionFactors.status === 'loading'}
                          data={scope2EmissionFactors.data.filter(ef => ef.bcode === metric.bcode)}
                          columns={columns}
                        />
                      </div>
                    </BECollapse>
                    <br/>
                </div>
        })
      }
      <AddCustomEFDrawer currentMetric={currentMetric} open={openAddEF} setOpen={setOpenAddEF} />
      <AddCustomEFDrawer isEdit currentEF={currentEF} open={openEditEF} setOpen={setOpenEditEF}  currentMetric={currentMetric} />
      <ApplyScope2EFDrawer open={openApplyEF} setOpen={setOpenApplyEF} currentMetric={currentMetric} currentEF={currentEF} />
    </div>
  );
}
