import * as React from 'react';
import './index.scss';

export interface IDataManagerListItemProps {
    sno: number;
    title: string;
}

export function DataManagerListItem (props: IDataManagerListItemProps) {
  return (
    <div className='mobile-dm-list-item'>
        <span className='mobile-dm-list-item-sn'>
            {props.sno}
        </span>
        <span className='mobile-dm-list-item-title'>
            {props.title}
        </span>
      
    </div>
  );
}
