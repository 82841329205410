import React, { useEffect, useState } from 'react'
import { BETabButton } from '../../../../Components/BETabButton';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import DataEntryIcon from '../../../../Components/BEIcons/DataEntryIcon';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import UsersIcon from '../../../../Components/BEIcons/UsersIcon';
import { useLocation } from 'react-router-dom';
import MonthSlicer from './MonthSlicer';
import TrackerIcon from '../../../../Components/BEIcons/TrackerIcon';

const TopSection = () => {
  const location = useLocation()
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    if (location.pathname === '/home/emissions') setActiveTab(0)
    else if (location.pathname === '/home/emissions/scope-1-configurations') setActiveTab(1)
    else setActiveTab(2)
  }, [location])

  return (
    <div className="global-top-section">
      <BETabButton
        title="OVERVIEW"
        icon={
          <DataEntryIcon
            stroke={
              activeTab === 0
                ? PrimaryTheme.primaryGreen
                : PrimaryTheme.primaryGray
            }
          />
        }
        active={activeTab === 0}
        onClick={() => {
          navigateTo("/home/emissions");
        }}
      />
      <BETabButton
        title="SCOPE 1 CONFIGURATIONS"
        icon={<UsersIcon fill={activeTab === 1 ? PrimaryTheme.primaryGreen : PrimaryTheme.primaryGray} />}
        active={activeTab === 1}
        onClick={() => { navigateTo('/home/emissions/scope-1-configurations') }}
      />
      <BETabButton
        title="SCOPE 2 CONFIGURATIONS"
        icon={<TrackerIcon stroke={activeTab === 2 ? PrimaryTheme.primaryGreen : PrimaryTheme.primaryGray} />}
        active={activeTab === 2}
        onClick={() => { navigateTo('/home/emissions/scope-2-configurations') }}
      />
      <div className='year' style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', gap: '0.5rem' }}>
        <MonthSlicer />
      </div>
    </div>
  );
}

export default TopSection