import * as React from 'react';
import { ResponsiveContainer,Legend,Tooltip, PieChart, Pie, Cell } from 'recharts'
import { graphFillColorArray } from '../../../../../Config/Data/dashboardData'
import { PrimaryTheme } from '../../../../../Config/Theme/theames'
import '../styles.scss';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { getDashoardContributerInsights } from '../../../Actions';
import EmptyEmissions from '../../../../../assets/images/EmptyEmissions.png'

interface IEmissionPieChartProps {
}

export function EmissionPieChart (props: IEmissionPieChartProps) {
	const [chartData,setChartData] = React.useState<any>([])
    const dispatch = useAppDispatch();
    const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    
    const getContributerInsightsData = async (year: number) => {
        const responseData = await dispatch(getDashoardContributerInsights(year));
        if (responseData) {
            const chartData = responseData.data.map((item:any) => 
            ({
                name: item.user_name,
                value: item.score
            }))
            setChartData(chartData)
        }
    }
    
	React.useEffect(()=>{
        getContributerInsightsData(currentYear)
	},[currentYear])

  if(chartData?.length === 0){
    return <div className='emission-pie-chart-container'>
       <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'inherit',flexDirection:'column'}}>
        <div className='circle'>
                <img src={EmptyEmissions} alt="empty emissions" />
            </div>
            <div>
                <p style={{textAlign:'center'}}>
                    Assign metrics to contributors to monitor <br /> and track their contributions 
                </p>
            </div>
       </div>
    </div>
  }
  return (
	<div className='emission-pie-chart-container'>
        <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
                <Pie
                    data={chartData}
                    cx="50%"
                    cy="50%"
                    innerRadius={50}
                    outerRadius={120}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {chartData.map((entry: any, index: number) => (
                        <Cell key={`cell-${index}`} fill={graphFillColorArray[index % graphFillColorArray?.length]} />
                    ))}
                </Pie>
	            <Tooltip />
                <Legend />
            </PieChart>
        </ResponsiveContainer>
	</div>
  )
}
