import { 
  //types
  BUSINESS_STAY, 
  BUSINESS_TRANSPORT, 
  DOWNSTREAM_LOGISTICS, 
  END_OF_LIFE_WASTE_TREATMENT,
  UPSTREAM_LOGISTICS, 
  WASTE_GENERATED,
  ASSET_BASED,
  UPSTREAM_LEASED_ASSET,
  DOWNSTREAM_LEASED_ASSET,
  FUEL_AND_ENERGY_RELATED,
  //basedOns
  PASSENGER_DISTANCE_BASED, 
  PROCUREMENT, QUANTITY_BASED, 
  SPEND_BASED,
  FREIGHT_DISTANCE_BASED,
  ENERGY_QUANTITY_BASED,
  ROOM_NIGHT_BASED, 
  FUEL_BASED,
  HYBRID_BASED,
  FRANCHISES,
  FRANCHISE_BASED
} from "./constants";


export const TypeData: any = {
  [PROCUREMENT]: { 
    code: 1,
    feildName: 'procurement',
   },
  [BUSINESS_TRANSPORT]: { 
    code: 2,
    feildName: 'business_transport',
   },
  [BUSINESS_STAY]: { 
    code: 3,
    feildName: 'business_stay',
   },
  [UPSTREAM_LOGISTICS]: { 
    code: 4,
    feildName: 'upstream_logistics',
   },
  [DOWNSTREAM_LOGISTICS]: { 
    code: 5,
    feildName: 'downstream_logistics',
   },
  [WASTE_GENERATED]:{
    code:6,
    feildName: 'waste_generated',
  },
  [END_OF_LIFE_WASTE_TREATMENT]:{
    code:7,
    feildName: 'end_of_life_waste_treatment',
  },
  [UPSTREAM_LEASED_ASSET]:{
    code:8,
    feildName: 'upstream_leased_assets',
  },
  [DOWNSTREAM_LEASED_ASSET]:{
    code:9,
    feildName: 'downstream_leased_assets',
  },
  [FUEL_AND_ENERGY_RELATED]:{
    code:10,
    feildName: 'fuel_and_energy_related',
  },
  [FRANCHISES]:{
    code:11,
    feildName: 'franchises',
  }
}

export const namesToShow:any = {
  'Electricity': 'Purchased Energy',
}