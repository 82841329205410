import * as React from 'react';
import { Select } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { BEDrawer } from '../../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import SwitchIcon from '../../../../../../Components/BEIcons/SwitchIcon';
import { NewBETable } from '../../../../../../Components/BETable/newBETable';
import { PrimaryTheme } from '../../../../../../Config/Theme/theames';
import { CheckFilledIcon } from '../../../../../../stories/Icon.stories';
import '../../styles.scss';
import { BEInput } from '../../../../../../Components/BEFormItems/BEInput';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { deleteSystemMapping, getAvailableSystems, getSystemMapping, patchSystemMapping, postSystemMapping } from '../../../../Actions';
import { BEMessage } from '../../../../../../Components/BEMessage';
import { LableRequired } from '../../../../../../Components/BEFormItems/LableRequired';
import DeleteIcon from '../../../../../../Components/BEIcons/DeleteIcon';
import { SystemMappingType } from '../../../../../../Redux/Types/settingsTypes';



interface IDataMappingDrawerProps {
	integrationId: number;
	setStep: Function
}
interface InputFieldMap {
	[bcode: string]: {
	  date_of_entry: string;
	  value: string;
	  unit: string | null;
	  business_unit: string;
	  year: number | null;
	  source: string | null;
	  cost: number | null;
	  description: string | null;
	  evidence: string | null;
	  timestamp: string;
	  [key: string]: string | number | null | undefined;
	};
  }

const InputFieldOptions = [
    { key: 1, value: 'month', mandatory: true },
    { key: 2, value: 'value', mandatory: true },
    // { key: 3, value: 'UNIT', mandatory: false },
    { key: 4, value: 'site', mandatory: true },
    // { key: 5, value: 'YEAR', mandatory: false },
    // { key: 6, value: 'SOURCE', mandatory: false },
    // { key: 7, value: 'COST', mandatory: false },
    // { key: 8, value: 'DESCRIPTION', mandatory: false },
    // { key: 9, value: 'EVIDENCE', mandatory: false },
    { key: 10, value: 'ts', mandatory: true }
];

const InputFields =[
	{ key: 1, input_field: 'date_of_entry', mandatory: true }, 
	{ key: 2, input_field: 'value', mandatory: true },         
	// { key: 3, input_field: 'unit', bcode: record.bcode, mandatory: false },         
	{ key: 4, input_field: 'business_unit', mandatory: true }, 
	// { key: 5, input_field: 'year', bcode: record.bcode, mandatory: false },         
	// { key: 6, input_field: 'source', bcode: record.bcode, mandatory: false },       
	// { key: 7, input_field: 'cost', bcode: record.bcode, mandatory: false },         
	// { key: 8, input_field: 'description', bcode: record.bcode, mandatory: false },  
	// { key: 9, input_field: 'evidence', bcode: record.bcode, mandatory: false },     
	{ key: 10, input_field: 'timestamp', mandatory: true }    

]
const DataMappingDrawer: React.ForwardRefRenderFunction<{ onButtonClick: () => void }, IDataMappingDrawerProps> = (props, ref) => {
	const dispatch = useAppDispatch();
	const directMetrics = useTypedSelector(state => state.onBoarding.metrics.directMetrics);
	const systemMapping = useTypedSelector(state => state.settings.systemMapping);
	const [selectedMetricMap, setSelectedMetricMap] = React.useState<any>({});
	const [selectedInputFieldMap, setSelectedInputFieldMap] = React.useState<InputFieldMap>({});
	const [searchValue, setSearchValue] = React.useState('');
	const [metricSearchDropDown, setMetricSearchDropDown] = React.useState(false);
	const [selectedMetric, setSelectedMetric] = React.useState<any>([]);
	const [currentMetric, setCurrentMetric] = React.useState<any>('');
	const [existingBcode, setExistingBcode] = React.useState<any>([]);
	const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
	const [availableSystems, setAvailableSystems] = React.useState<any>([]);

	
	React.useEffect(()=> {
		dispatch(getSystemMapping());
		dispatch(getAvailableSystems()).then((data) => {
			setAvailableSystems(data.body);
		});
	},[])

	React.useEffect(() => {
		if (systemMapping.data) {
			const metricMap: any = {};
			const inputFieldMap: any = {};
			systemMapping.data.forEach((mapping: any) => {
				metricMap[mapping.bcode] = {
					system_metric:mapping.system_metric,
					id: mapping.id,
				};
				inputFieldMap[mapping.bcode] = {
					date_of_entry: mapping.date_of_entry,
					value: mapping.value,
					unit: mapping.unit,
					business_unit: mapping.business_unit,
					year: mapping.year,
					source: mapping.source,
					cost: mapping.cost,
					description: mapping.description,
					evidence: mapping.evidence,
					timestamp: mapping.timestamp
				};
			});
			setSelectedMetricMap(metricMap);
			setSelectedInputFieldMap(inputFieldMap);
			setSelectedMetric(Object.keys(metricMap));
			setExistingBcode(Object.keys(metricMap));
		}
	}, [systemMapping.data]);

	const metricColumns:ColumnsType<any> = [
		{
			title: 'Metric',
			dataIndex: 'metric',
			render: (text:any, record:any) => {
				return <div style={{display:'flex', alignItems:'center'}}>
					{text}
				</div>
			}
		},
		{
			title: 'System',
			dataIndex: 'system',
			render: (text:any, record:any) => (
				<BEInput 
					value={selectedMetricMap?.[record.bcode]?.system_metric}
					onChange={(e) =>{
						setSelectedMetricMap((prev: any) => {
							const newValue = e.target.value;
							if (newValue === '') {
								const { [record.bcode]: _, ...rest } = prev;
								return rest;
							} else {
							return { ...prev, [record.bcode]:{
									system_metric: newValue,
									id: prev[record.bcode]?.id
							} };
							}
						})
					}}
				/>
			)
		},
		{
			title: 'Status',
			dataIndex: 'status',
			render: (text:any, record:any) =>  selectedMetricMap.hasOwnProperty(record.bcode) ? <CheckFilledIcon /> : '---'
		},
		{
			title: 'Actions',
			dataIndex: 'actions',
			width:'1rem',
			render: (text:any, record:any) => (
				<div style={{display:'flex', justifyContent: 'center'}}>
					<div style={{height:'1rem', width:'1rem', cursor:'pointer'}} onClick={async() => {
					const id = selectedMetricMap?.[record.bcode]?.id;
					if (id){
						await dispatch(deleteSystemMapping(id));
					}else{
						setSelectedMetric((prev: string[]) => prev.filter((bcode: string) => bcode !== record.bcode));
					}
					BEMessage.success('Metric deleted successfully');
				}}><DeleteIcon inheritSize stroke='black' /></div>
				</div>
			)
				
		}
	]

	const inputColumns:ColumnsType<any> = [
		{
			title: 'Input field',
			dataIndex: 'input_field',
			render: (text:any, record:any) => (
				record.mandatory ? <LableRequired>{text}</LableRequired> : text
			)
		},
		{
			title: 'Map',
			dataIndex: 'map',
			render: (text:any, record:any) => {
			return	<div style={{width:'100%'}}>
					<Select 
						allowClear
						value={selectedInputFieldMap?.[record.bcode]?.[record.input_field]} 
						style={{minWidth:'max-content'}} 
						onChange={(e)=> {
							setSelectedInputFieldMap((prev: any) => ({
									...prev,
									[record.bcode]: {
										...prev[record.bcode], 
										[record.input_field]: e
								}
								}	
							))
						}
					}
					>
						{InputFieldOptions.filter((option)=> {
							 const selectedOptions = selectedInputFieldMap?.[record.bcode] || {};
							 return !(Object.values(selectedOptions).includes(option.value));
						}).map((option) => (
							<Select.Option key={option.key} value={option.value}>{option.value}</Select.Option>
						))}
					</Select>
				</div>
			}
		},
	]
	
	const onButtonClick = async () => {
		if (!checkAllMetricsMapped()) return BEMessage.error('Please map all metrics');
		if (!checkAllInputFieldsMapped()) return BEMessage.error('Please map all input fields');

		//patch system metrics if changed
		systemMapping.data.forEach(async (mapping: SystemMappingType) => {
			const bcode = mapping.bcode;
			const metric = selectedMetricMap[bcode];
			if (metric.system_metric !== mapping.system_metric) {
				await dispatch(patchSystemMapping(metric.id, { system_metric: metric.system_metric }));
			}
		});

		//patch input fields if changed
		systemMapping.data.forEach(async (mapping: SystemMappingType) => {
			const bcode = mapping.bcode;
			const inputFields = selectedInputFieldMap[bcode];
			const fieldsToCompare: (keyof SystemMappingType)[] = [
				"date_of_entry", "value", "unit", "business_unit", "year", "source", 
				"cost", "description", "evidence", "timestamp"
			];
			fieldsToCompare.forEach(async (field) => {
				if (mapping[field] !== inputFields[field]) {
					await dispatch(patchSystemMapping(mapping.id, { [field]: inputFields[field] }));
				}
			});
		});
		
		// Send System Mapping data
		const dataToSend = Object.keys(selectedMetricMap).filter((key)=> !(existingBcode.includes(key))).map((key) => {
			const inputfields = selectedInputFieldMap[key];
			return {
				bcode: key,
				system_metric: selectedMetricMap?.[key].system_metric,
				integration: props.integrationId,
				...inputfields
			}
		});
		
		if(dataToSend?.length === 0) return props.setStep(2);
		await dispatch(postSystemMapping(dataToSend));
		props.setStep(2);
	}

	const checkAllMetricsMapped = () => {
		// Ensure all selected metrics are mapped
		const mappedMetrics = Object.keys(selectedMetricMap);
		const newAddedMetric = selectedMetric.filter((bcode: string) => !existingBcode.includes(bcode));
		const allNewMetric = newAddedMetric.every((bcode:string) => selectedMetricMap[bcode]?.system_metric);
		if(!allNewMetric) {
			return false;
		}
		return selectedMetric.every((bcode:string) => mappedMetrics.includes(bcode));
	}

	const checkAllInputFieldsMapped = () => {
		// Ensure all mandatory input fields are mapped in all selected metrics
		const mandatoryInputFields = InputFields.filter((field)=> field.mandatory).map((field) => field.input_field);
		const newAddedMetric = Object.keys(selectedMetricMap).filter((key)=> !(existingBcode.includes(key)))
		const areAllNewMetricsMapped = newAddedMetric.every((bcode) => selectedInputFieldMap[bcode]);
		if (!areAllNewMetricsMapped) {
			return false; 
		}
		return Object.values(selectedInputFieldMap).every((mappedFields: any) =>
			mandatoryInputFields.every((field) => field in mappedFields && mappedFields[field])
		);
	}

	const handleSearchBlur = () => {
		if (!currentMetric) {
			setMetricSearchDropDown(false);
		}
	}

	const handleSearchFocus	= () => {
		setMetricSearchDropDown(true);
	}

	const handleExpand = (expanded:boolean, record:any) => {
		if (expanded) {
			setExpandedRowKeys([...expandedRowKeys, record.key]); // Add the row key to expandedRowKeys
		} else {
			setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key)); // Remove the row key
		}
	};

	React.useImperativeHandle(ref, () => ({ onButtonClick }), [selectedInputFieldMap, selectedMetricMap, selectedMetric, systemMapping.data]);
	
	return (
		<>
			<div style={{position:'relative'}}>
				<div style={{width:'30%', display:'flex', gap:'1rem'}}>
					<BEInput 
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
						selectSize='large'
						placeholder='Search metric'
						search
						onBlur={handleSearchBlur}
						onFocus={handleSearchFocus}
					/>	
					<BEButton 
						onClick={()=> {
							if(selectedMetric?.length === 1 && availableSystems.some((system:{title:string})=> system.title === 'Earth Fokus')){ return BEMessage.error('Only one metric can be mapped')}
							setSelectedMetric((prev: string[]) => {
								const metric = directMetrics.data.find((metric) => metric.title === searchValue);
								if(!metric) return prev;
								if (!prev.includes(metric.bcode)) {
									return [...prev, metric.bcode];
								}
							})
							setSearchValue('');
							setMetricSearchDropDown(false);
						}}
						className='primary' 
						size='large'
					>
						Add metric
					</BEButton>
				</div>
				{metricSearchDropDown && <div onMouseOut={()=>setCurrentMetric('')} className='metricSearchDropDown'>
					{directMetrics?.data.filter((directMetrics)=>
						directMetrics.title?.toLowerCase().includes(searchValue.trim().toLowerCase()) && !selectedMetric.includes(directMetrics.bcode))
							.sort((a, b) =>
								a.title.localeCompare(b.title, undefined, { sensitivity: 'base' }))
									.map((directMetric) => 
										<div 
											key={directMetric.bcode}
											className='list-items'
											onMouseEnter={()=> setCurrentMetric(directMetric.bcode)}
											onClick={() => {
												setSearchValue(directMetric.title);
											}}
										>
											{directMetric.title}
										</div>
					)}
				</div>}
			</div>
			<NewBETable 
				formatTitle
				columns={metricColumns}
				loading={systemMapping.status === 'loading'}
				className='data-mapping-table'
				rowClassName={'row-class'}
				data={
					selectedMetric.map((bcode:string) => {
					const directMetric = directMetrics.data.find((metric) => metric.bcode === bcode);
					return { 
						key: directMetric?.bcode,
						bcode: directMetric?.bcode,
						metric: directMetric?.title
					}; 
				}).sort((a: { metric: string }, b: { metric: string }) => (a.metric || "").localeCompare(b.metric || ""))}
				expandable={{
					expandIcon: (props: { expanded: boolean; onExpand: (record: any, e: React.MouseEvent<HTMLElement, MouseEvent>) => void; record: any }) => {
						const selectedSystem = selectedMetricMap[props.record.bcode];
						return (
							<div 
							onClick={(e) => selectedSystem && props.onExpand(props.record, e)} 
							style={{height:'1rem', width:'1rem', transform: props.expanded ? 'rotate(90deg)' : 'rotate(0deg)'}}
							>
							<SwitchIcon stroke="black" />
							</div>
						);
					},
					expandIconColumnIndex: 4,
					expandedRowKeys: expandedRowKeys,
					onExpand: handleExpand,
					expandedRowRender: (record:any, expanded:boolean) => {
							return (
								<div style={{backgroundColor:'white', paddingLeft:'1.5rem',paddingBottom:'1rem'}}>
									<div style={{width:'60%'}}>
										<NewBETable 
											formatTitle
											className='input-mapping-table'
											columns={inputColumns}
											data={InputFields.map((inputField) => {
												return {
													key: inputField.key,
													bcode: record.bcode,
													input_field: inputField.input_field,
													mandatory: inputField.mandatory,
													map: selectedInputFieldMap?.[record.bcode]?.[inputField.input_field]
												}
											}
											)}
										/>
									</div>
									<div style={{borderTop:`1px solid ${PrimaryTheme.primaryGreyLight}`}}>
											<BEButton size='large' onClick={()=>
												{
													if(!checkAllInputFieldsMapped()) return BEMessage.error('Please map all input fields');
													handleExpand(expanded,record)
												}} style={{marginTop:'1rem'}} className='primary'>Save</BEButton>
									</div>
								</div>
							)
					}
				}}
				pagination
			/>
		</>
	);
}

export default React.forwardRef(DataMappingDrawer);
