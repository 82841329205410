import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { BETable } from '../../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import DeleteIcon from '../../../../../Components/BEIcons/DeleteIcon';
import './style.scss';
import AddIcon from '../../../../../Components/BEIcons/AddIcon';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { patchMyDashboardMetrics, patchMyDerivedDashboardMetrics, postMyDashboardMetrics } from '../../../Actions';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { SetMetrics } from '../SelectMetrics';
import BEConfirmModal from '../../../../../Components/BEConfirmModal';

export interface IAddTrendsProps {
  open: boolean;
  setOpen: Function;
  cardMetricsData: any[];
  graphsMetricsData: any[];
  load: boolean;
}

export function AddTrends(props: IAddTrendsProps) {

  const dispatch = useAppDispatch();
  const [cardMetrics, setCardMetrics] = React.useState<any[]>([]);
  const [graphMetrics, setGraphMetrics] = React.useState<any[]>([]);
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const [type, setType] = React.useState<string>("");
  const [load, setLoad] = React.useState<boolean>(props.load);
  const [openSelectMetrics, setOpenSelectMetrics] = React.useState<boolean>(false);
  const [selectedCardMetricsId, setSelectedCardMetricsId] = React.useState<number[]>([]);
  const [selectedGraphMetricsId, setSelectedGraphMetricsId] = React.useState<number[]>([]);
  const [openDelete, setOpenDelete] = React.useState<boolean>(false);
  const [metricToDelete, setMetricToDelete] = React.useState<any>({})
  const columns: ColumnsType<any> = [
    {
      title: 'Sr. No.',
      key: 'sno',
      render: (text, object, index) => index + 1
    },
    {
      title: 'Metric Name',
      dataIndex: 'title'
    },
    {
      title: 'Category',
      dataIndex: 'category'
    },
    {
      title: 'Unit',
      dataIndex: 'unit'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => <>{
        record.title === "Empty" ?
          <div
            style={{ height: "1rem", cursor: "pointer" }}
            onClick={() => {
              setOpenSelectMetrics(true);
              setType(record.type);
            }}
          >
            <AddIcon
              inheritSize
              stroke={PrimaryTheme.primaryGray}
            />
          </div>
          :
          <div
            onMouseEnter={() => setMetricToDelete(record)}
            style={{ height: "1rem", cursor: record.id < 0 ? "not-allowed" : "pointer" }}
            onClick={() => { record.id > 0 && setOpenDelete(true) }}
          >
            <DeleteIcon
              inheritSize
              stroke={PrimaryTheme.primaryGray}
            />
          </div>}
      </>
    }
  ];

  const onCancel = () => {
    props.setOpen(false)
  }

  const onSave = () => {
    props.setOpen(false);
  }

  const removeMetric = async (record: any) => {
    setLoad(true);
    const data = record.type === "card" ? {
      card: false
    } :
      {
        graph: false
      };
    if (record.isDerived)
      await dispatch(patchMyDerivedDashboardMetrics(record.dashboardMetricId, data))
    else
      await dispatch(patchMyDashboardMetrics(record.dashboardMetricId, data))
    setLoad(false);
  }

  React.useEffect(() => {
    if (!load) {
      let cardMetricsData = props.cardMetricsData;
      setSelectedCardMetricsId(cardMetricsData.map((item) => item.selectedMetricId));
      while (cardMetricsData?.length < 4) {
        cardMetricsData.push({
          "title": "Empty",
          "category": "-------",
          "unit": "-------",
          "type": "card"
        });
      }
      setCardMetrics(cardMetricsData);

      setSelectedGraphMetricsId(props.graphsMetricsData.map((item) => item.id));
      let graphMetricsData = props.graphsMetricsData;
      while (graphMetricsData?.length < 8) {
        graphMetricsData.push({
          "title": "Empty",
          "category": "-------",
          "unit": "-------",
          "type": "graph"
        });
      }
      setGraphMetrics(graphMetricsData);
    }
  }, [props.open, props.cardMetricsData, props.graphsMetricsData, currentYear])


  return (
    <BEDrawer
      open={props.open}
      setOpen={props.setOpen}
      heading='Add Trends'
      width='fit-content'
      footer={
        <>
          <BEButton size='large'
            onClick={onSave}
            className='primary'
          >
            Save and Close
          </BEButton>
          {' '}
          <BEButton size='large'
            onClick={onCancel}
          >
            Close
          </BEButton>
        </>
      }
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
        <div style={{ border: "1px solid rgba(243, 243, 243, 1)", padding: "1rem", borderRadius: "8px" }}>
          <div
            style={{ fontSize: "1.25rem", fontWeight: "400", marginBottom: "1rem" }}>
            Cards
          </div>
          <BETable
            loading={load}
            columns={columns}
            data={cardMetrics}
            rowClassName={(record: any, index: any) => record.title === "Empty" ? 'empty-row' : ''}
          />
        </div>

        <div style={{ border: "1px solid rgba(243, 243, 243, 1)", padding: "1rem", borderRadius: "8px" }}>
          <div
            style={{ fontSize: "1.25rem", fontWeight: "400", marginBottom: "1rem" }}>
            Graphs
          </div>
          <BETable
            loading={load}
            columns={columns}
            data={graphMetrics}
            rowClassName={(record: any, index: any) => record.title === "Empty" ? 'empty-row' : ''}
          />
        </div>
      </div>
      <SetMetrics
        open={openSelectMetrics}
        setOpen={setOpenSelectMetrics}
        load={false}
        type={type}
        selectedMetricsId={type === "card" ? selectedCardMetricsId : selectedGraphMetricsId}
      />
      <BEConfirmModal
        title="Confirm Delete"
        message="Are you sure you want to delete the dashboard metrics?"
        visible={openDelete}
        setVisible={setOpenDelete}
        onConfirm={async () => {
          await removeMetric(metricToDelete);
        }}
      />
    </BEDrawer>
  );
}
