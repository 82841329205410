import * as React from 'react';
import { DataManager } from './DataManager';
import { handleMobileBackgroundAPICalls } from './HandleMobileBackgroundAPICalls';
import { useAppDispatch } from '../Config/Hooks/useAppDispatch';
import { getCompanyDetails } from '../views/Actions/OnboardingActions';
import { useTypedSelector } from '../Config/Hooks/useTypedSelector';
import { setCurrentBusinessUnit } from '../Redux/CommonReducer';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { decryptRole, logoutAction } from '../views/Actions/LoginActions';
import { setRole } from '../Redux/UserReducer';
import { AutoLogin } from '../Config/Functions/Autologin';
import { useEffect } from 'react';
import { setNavigateFunction } from '../Navigation/NavigationManager';
import { Login } from '../views/Login';
import PrivateRoute from '../Config/PrivateRoute';
import { setBusinessUnits } from '../Redux/EntityReducer';
import BENoBuModal from '../Components/BENoBuModal';

export interface IMobileComponenetProps {
}

export function MobileComponentRoutes(props: IMobileComponenetProps) {
  const dispatch = useAppDispatch();
  const [showLogoutModal, setShowLogoutModal] = React.useState(false);
  const navigate = useNavigate();
  const isLoggedIn = useTypedSelector(state => state.user.isLoggedIn);
  const location = useLocation();
  const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);
  const contributorBusinessUnitRef = useTypedSelector(state => state.entity.BusinessUnitContributors);
  const user = useTypedSelector(state => state.user.userProfile);
  const users = useTypedSelector(state => state.userMgmt);
  const [modalHeader, setModalHeader] = React.useState('');
  const [modalMessage, setModalMessage] = React.useState('');

  const checkContributorBusinessunit = () => {
    if (!isLoggedIn) {
      return;
    }

    if (users?.status === 'success' && businessUnits?.status === 'success' && contributorBusinessUnitRef?.status === 'success') {
      const loggedinUser: any = users?.data?.filter(u => u.id === user?.data?.user)[0!];
      const contributorBusinessUnits = businessUnits?.data?.filter(bu => contributorBusinessUnitRef?.data?.filter(bur => bur.user === loggedinUser?.id).
        map(bur => bur.business_unit).includes(bu.id));

      if (loggedinUser?.role !== 'CONTRIBUTOR') {
        setShowLogoutModal(true);
        setModalHeader('Access Restricted');
        setModalMessage('At the moment the mobile login is only accessible to CONTRIBUTOR users. It will be rolled out to all user roles soon. Thank you.');
        dispatch(setBusinessUnits([]));
      }

      if (!contributorBusinessUnits?.length && loggedinUser?.role === 'CONTRIBUTOR') {
        setShowLogoutModal(true);
        setModalHeader('Unassigned to a Business Unit');
        setModalMessage(`Welcome ${loggedinUser?.first_name}, Please request your administrator to add you to a Business Unit, to access the platform.`);
        dispatch(setBusinessUnits([]));
      } else {
        if (loggedinUser?.role === 'CONTRIBUTOR') {
          if (contributorBusinessUnits?.length) {
            dispatch(setCurrentBusinessUnit(contributorBusinessUnits[0!]?.id));
            dispatch(setBusinessUnits({
              status: 'success',
              data: contributorBusinessUnits
            }));
          }
        } else {
          if (businessUnits?.data?.length) {
            dispatch(setCurrentBusinessUnit(businessUnits?.data[0!]?.id));
          }
        }
      }
    }
  }

  useEffect(() => {
    const enryptedRole = localStorage.getItem('role');
    if (enryptedRole) {
      let role = decryptRole(enryptedRole as string);
      dispatch(setRole(role));
    }
    dispatch(AutoLogin(navigate, location));
    setNavigateFunction(navigate);
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    dispatch(getCompanyDetails()).then(async () => {
      await dispatch(handleMobileBackgroundAPICalls()).then(() => {
        checkContributorBusinessunit();
      });
    });
  }, [isLoggedIn]);

  useEffect(() => {
    checkContributorBusinessunit();
  }, [contributorBusinessUnitRef]);

  return (
    <div>
      <Routes>
        <Route path="/" />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<PrivateRoute onlyForLoggedIn></PrivateRoute>}>
          <Route path="data-manager" element={<DataManager />} />
        </Route>
      </Routes>
      <BENoBuModal
        title={modalHeader}
        message={modalMessage}
        visible={showLogoutModal}
        setVisible={setShowLogoutModal}
        onConfirm={async () => {
          await dispatch(logoutAction());
        }}
      />
    </div>
  );
}
