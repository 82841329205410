import * as React from 'react';
import { BEDrawer } from '../../../Components/BEDrawer';
import { BEButton } from '../../../Components/BEFormItems/BEButton';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { BEInput } from '../../../Components/BEFormItems/BEInput';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import Logout from '../../../Components/BEIcons/Logout';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { logoutAction } from '../../../views/Actions/LoginActions';

export interface ILogoutDrawerProps {
    open: boolean;
    setOpen: Function;
}

export function LogoutDrawer(props: ILogoutDrawerProps) {
    const users = useTypedSelector(state => state.userMgmt.data);
    const user = useTypedSelector(state => state.user.userProfile);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useAppDispatch();

    return (
        <BEDrawer
            open={props.open}
            setOpen={props.setOpen}
            heading='Account'
            closable={true}
            height={window.innerHeight - 100}
            padding={10}
            isMobile={true}
            footer={null}
        >
            <div style={{ color: PrimaryTheme.primaryGray, display: 'grid', rowGap: '1.5rem' }}>
                <div>
                    <p>Contributor name</p>
                    <BEInput value={
                        users.filter(u => u.id === user?.data?.user)[0]?.first_name + ' ' +
                        users.filter(u => u.id === user?.data?.user)[0]?.last_name
                    } disabled />
                </div>
                <div>
                    <p>Email</p>
                    <BEInput value={
                        users.filter(u => u.id === user?.data?.user)[0]?.email
                    } disabled />
                </div>
                <div>
                    <BEButton
                        loading={loading}
                        onClick={() => {
                            setLoading(true);
                            dispatch(logoutAction()).then(() => {
                                setLoading(false);
                                props.setOpen(false);
                            }).catch(() => {    
                                setLoading(false);
                            });
                        }}
                        className='primary-red'
                        icon={<Logout fill='red'/>}
                        size='large'
                        style={{ width: '100%', display: 'flex', justifyContent: 'center', fontSize: '2rem' }}
                    >
                        Logout
                    </BEButton>
                </div>

            </div>
        </BEDrawer>
    );
}
