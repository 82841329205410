import * as React from 'react';
import { BEDrawer } from '../../../Components/BEDrawer';
import { BEInput } from '../../../Components/BEFormItems/BEInput';
import { BEButton } from '../../../Components/BEFormItems/BEButton';
import { LableRequired } from '../../../Components/BEFormItems/LableRequired';
import { Select } from 'antd';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import './styles.scss';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import { YearSelectOptions } from '../../../Components/BEMultipleSelect/YearSelectOptions';
import { setDateSlicerYearDM } from '../../../Redux/DataManagerReducer';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { setCurrentBusinessUnit } from '../../../Redux/CommonReducer';

interface IBuFyDrawerProps {
	open: boolean;
	setOpen: Function;
}

export function BuFyDrawer(props: IBuFyDrawerProps) {
	const selectedYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
	const businessUnits = useTypedSelector(state => state.entity.BusinessUnits?.data);
	const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
	const dispatch = useAppDispatch();

	return (
		<BEDrawer
			open={props.open}
			setOpen={props.setOpen}
			heading='Select BU & FY'
			closable={true}
			height={window.innerHeight - 100}
			padding={10}
			isMobile={true}
			footer={
				<>
					<BEButton
						onClick={() => props.setOpen(false)}
						className='primary'>
						Apply filter
					</BEButton>
					<BEButton
						onClick={() => props.setOpen(false)}
					>
						Cancel
					</BEButton>
				</>
			}
		>
			<div style={{ color: PrimaryTheme.primaryGray, display: 'grid', rowGap: '1.5rem' }}>
				<div>
					<LableRequired>
						Financial Year
					</LableRequired>
					<YearSelectOptions
						width={'100%'}
						onChange={(value: number) => dispatch(setDateSlicerYearDM(value))}
						value={selectedYear}
					/>
				</div>
				<div>
					<LableRequired>
						Business unit
					</LableRequired>
					<Select
						value={currentBusinessUnit}
						style={{ width: '100%' }}
						onChange={(value) => dispatch(setCurrentBusinessUnit(value))}
					>
						{
							businessUnits?.map((bu, index) => (
								<Select.Option key={index} value={bu.id}>{bu.name}</Select.Option>
							))
						}
					</Select>
				</div>
			</div>
		</BEDrawer>
	);
}
