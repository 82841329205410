import React, { useEffect, useState } from 'react'
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { AddCommaToNumber } from '../../../../Config/Functions/UsefullFunctions';
import { TotalEmissionsByScopeGraph } from './Graphs/EmmisionsByScopeGraph';
import { TotalEmissionsByTypeGraph } from './Graphs/EmissionsBYTypeGraph';
import { GlobalLoader } from '../../../../Components/GlobalLoader';
import './style.scss';
import EmissionCard from '../../../../Components/Cards/EmissionCard';
import { SetFilteredData } from '../../Components/SetFilteredData';
import { getAllEmissionCalculations, getScope2TypeName, getScope3EmissionCalculations } from '../../Actions';
import { Radio } from 'antd';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { setScope2EmissionType } from '../../../../Redux/EmmisionsReducer';
import { ColumnsType } from 'antd/es/table';
import { BETable } from '../../../../Components/BETable';


export interface IOverviewProps {

}

const Overview = () => {
  const dispatch = useAppDispatch()
  const scope1Calculations = useTypedSelector(state => state.emissions.emissionsCalculations.scope1Calculations);
  const filterdScope1Calculation = useTypedSelector(state => state.emissions.filteredEmissionCalculations.scope1Calculations);
  const filteredScope2Calculation = useTypedSelector(state => state.emissions.filteredEmissionCalculations.scope2Calculations);
  const filteredScope3Calculation = useTypedSelector(state => state.emissions.filteredEmissionCalculations.scope3Calculations);
  const [scope1Total, setScope1Total] = useState(0)
  const [scope2Total, setScope2Total] = useState(0)
  const [scope3Total, setScope3Total] = useState(0)
  const [totalEmission, setTotalEmission] = useState(0)
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const scope2EmissionType = useTypedSelector(state => state.emissions.scope2EmissionType);
  const [tableData, setTableData] = useState<any>([])

  useEffect(() => {
    if(filterdScope1Calculation.status === 'success' && filteredScope2Calculation.status === 'success' && filteredScope3Calculation.status === 'success'){
      let dataByKeys:any = {}
      
      const scope12Data:any = [...filterdScope1Calculation.data,...filteredScope2Calculation.data]
      scope12Data.forEach((item:any) => {
        if(!dataByKeys[item.title]) dataByKeys[item.title] = {...item}
        else {
          dataByKeys[item.title].tco2e += item.tco2e
          dataByKeys[item.title].co2 += item.co2
          dataByKeys[item.title].ch4 += item.ch4
          dataByKeys[item.title].n2o += item.n2o
        }
      })

      filteredScope3Calculation.data.forEach((item:any) => {
        if(!dataByKeys[item.title]) dataByKeys[item.category] = {...item,scope:'Scope 3',title:item.category}
        else {
          dataByKeys[item.title].tco2e += item.tco2e
        }
      })

      setTableData(Object.values(dataByKeys))
    }
  }, [filterdScope1Calculation, filteredScope2Calculation, filteredScope3Calculation])

  useEffect(() => {
    let total = filterdScope1Calculation.data.reduce((acc: any, obj: any) => acc + obj.tco2e, 0)
    let total2 = filteredScope2Calculation.data.reduce((acc: any, obj: any) => acc + obj.tco2e, 0)
    let total3 = filteredScope3Calculation.data.reduce((acc: any, obj: any) => acc + obj.tco2e, 0)
    setScope1Total(total)
    setScope2Total(total2)
    setScope3Total(total3)
    setTotalEmission(total + total2 + total3)
  }, [filterdScope1Calculation, filteredScope2Calculation, filteredScope3Calculation])

  useEffect(() => {
    dispatch(getAllEmissionCalculations(currentYear));
    dispatch(getScope3EmissionCalculations(currentYear));
  }, [currentYear])

  const columns:ColumnsType<any> = [
    {
      title: 'SOURCE',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'SCOPE',
      dataIndex: 'scope',
      key: 'scope',
    },
    {
      title:'EMISSIONS (tCO2e)',
      dataIndex:'tco2e',
      key:'tco2e',
    },
    {
      title:'Co2',
      render:(record:any) => record.scope === 1 ? record.co2 : '-',
      key:'co2',
    },
    {
      title:'CH4',
      render:(record:any) => record.scope === 1 ? record.ch4 : '-',
      key:'ch4',
    },
    {
      title:'N2O',
      render:(record:any) => record.scope === 1 ? record.n2o : '-',
      key:'n2o',
    }
  ]

  return (
    <div style={{ padding: "1rem 0" }} className='emissions-overview'>
      <div className='select-scope2'>
        <div>
          <p className='heading'>Select method</p>
          <p className='description'>Choose a method to display the Scope 2 emissions data. Your selection will update the data shown on the dashboard.</p>
        </div>
        <Radio.Group  onChange={(e) => dispatch(setScope2EmissionType(e.target.value))} value={scope2EmissionType}>
          <Radio style={{color:PrimaryTheme.primaryGray}} value={1}>Location-based</Radio>
          <Radio style={{color:PrimaryTheme.primaryGray}} value={2}>Market-based</Radio>
        </Radio.Group>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "90%",
          gap:'1rem'
        }}
      >
        <EmissionCard
          value={AddCommaToNumber(totalEmission, false, true)}
          loading={
            filterdScope1Calculation.status === 'loading' ||
            filteredScope2Calculation.status === 'loading' ||
            filteredScope3Calculation.status === 'loading'
          }
          unit="tCO2e"
          description="Total Emissions"
        />
        <EmissionCard
          value={AddCommaToNumber(scope1Total, false, true)}
          loading={
            filterdScope1Calculation.status === 'loading'
          }
          unit="tCO2e"
          description="Scope 1 Emissions"
        />
        <EmissionCard
          value={AddCommaToNumber(scope2Total, false, true)}
          loading={
            filteredScope2Calculation.status === 'loading'
          }
          unit="tCO2e"
          description={`Scope 2 Emissions ${getScope2TypeName(scope2EmissionType)}`}
        />
        <EmissionCard
          value={AddCommaToNumber(scope3Total, false, true)}
          loading={
            filteredScope3Calculation.status === 'loading'
          }
          unit="tCO2e"
          description="Scope 3 Emissions"
        />
      </div>

      <div style={{ margin: "3rem 0" }}>
        {
          filterdScope1Calculation.status !== 'loading' && filteredScope2Calculation.status !== 'loading'
            ? <TotalEmissionsByScopeGraph /> : <GlobalLoader height='20rem' />
        }

      </div>
      {/* {
        currentBU === 0 &&
        <div style={{ paddingTop: "1rem" }}>
          <EmmisionsDonutChart />
        </div>
      } */}
      <div style={{ margin: "5rem 0" }}>
        {
          filterdScope1Calculation.status !== 'loading' && filteredScope2Calculation.status !== 'loading'
            ? <TotalEmissionsByTypeGraph /> : <GlobalLoader height='20rem' />
        }

      </div>
      <div>
        <p className='chart-title'>Emission Calculations</p>
        <BETable 
          pagination
          columns={columns}
          data={tableData}
          loading={filterdScope1Calculation.status === 'loading' || filteredScope2Calculation.status === 'loading' || filteredScope3Calculation.status === 'loading'}
        />
      </div>
      <SetFilteredData/>
    </div>
  );
}

export default Overview;