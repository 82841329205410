export const SPEND_BASED = 'spend-based' as const;
export const QUANTITY_BASED = 'average-data' as const;
export const PASSENGER_DISTANCE_BASED = 'passenger-distance-based' as const;
export const FREIGHT_DISTANCE_BASED = 'freight-distance-based' as const;
export const ASSET_BASED = 'asset-based' as const;
export const ENERGY_QUANTITY_BASED = 'energy-quantity-based' as const;
export const ROOM_NIGHT_BASED = 'room-night-based' as const;
export const FUEL_BASED = 'fuel-based'
export const HYBRID_BASED = 'hybrid'
export const FRANCHISE_BASED = 'franchise-specific' as const;

export const PROCUREMENT = 'procurement' as const;
export const BUSINESS_TRANSPORT = 'business-transport' as const;
export const BUSINESS_STAY = 'business-accommodation' as const;
export const UPSTREAM_LOGISTICS = 'upstream-transportation' as const;
export const DOWNSTREAM_LOGISTICS = 'downstream-transportation' as const;
export const WASTE_GENERATED = 'waste-generated-in-operations' as const;
export const END_OF_LIFE_WASTE_TREATMENT = 'end-of-life-treatment-of-sold-products' as const;
export const UPSTREAM_LEASED_ASSET = 'upstream-leased-assets' as const;
export const DOWNSTREAM_LEASED_ASSET = 'downstream-leased-assets' as const;
export const FUEL_AND_ENERGY_RELATED = 'fuel- and energy-related activities not included in scope 1 or scope 2' as const;
export const FRANCHISES = 'franchises' as const;

export const PURCHASED_GOODS_AND_SERVICES= 'Purchased goods & services' as const;
export const CAPITAL_GOODS ='Capital goods' as const;