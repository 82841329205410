import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';

interface IMetricDescriptionDrawerProps {
	data: {title: string, desc: string} | null;
	setData: Function;
}

export function MetricDescriptionDrawer (props: IMetricDescriptionDrawerProps) {
	if(props.data === null) return null;
	return (
	  <BEDrawer width='500px' heading={'Metric Guidance'} open={props.data ? true : false} setOpen={(isOpen: boolean)=> props.setData(null)}>
		<p style={{ fontWeight: '600', fontSize: '1rem' }}>
			{props.data.title}
		</p>
		<p style={{fontSize:'0.9rem',marginTop:'1rem'}}>{props.data.desc}</p>
	  </BEDrawer>
	);
}
