import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';

import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { BETextArea } from '../../../../../Components/BEFormItems/BETextArea';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { Form } from 'antd';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { postReportChats } from '../../../Actions/template';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';

interface IRejectTopicDrawerProps {
	open: boolean;
	setOpen: Function;
	onClick: Function;
	viewOnly?: boolean;
	uniqueCode: string;
}

export function RejectTopicDrawer (props: IRejectTopicDrawerProps) {
	const [form] = Form.useForm();
	const [loading, setLoading] = React.useState(false);
	const dispatch = useAppDispatch();
	const userInfo = JSON.parse(localStorage.getItem("userInfo") || '{}');
	const topicStatus = useTypedSelector(state => state.reporting.topicStatus);
	async function onFinish(values: any) {
		setLoading(true);
		let rejectionSummary = '';
		if(values.description){
			rejectionSummary = `//${values.description}//`;
		}else{
			rejectionSummary = '//-//';
		};	
		
		const currentReportId = topicStatus?.data?.find((item) => item.unique_code === props.uniqueCode)?.esg_report;
		const sentMessage = await dispatch(postReportChats({
			unique_code: props.uniqueCode,
			message: rejectionSummary,
			report_id: currentReportId,
			from_user_id: userInfo?.id,
		}))
		props.onClick();
		props.setOpen(false);
		setLoading(false);
	}
	return (
	  <BEDrawer 
	  heading='Reject Topic'
	  footer={
		<>
			{
				!props.viewOnly &&
				<BEButton
					loading={loading}
					size="large"
					className="primary"
					onClick={() => form.submit()}
				>
					Reject and Close
				</BEButton>
			}
			<BEButton
				size="large"
				onClick={() => props.setOpen(false)}
			>
				Cancel
			</BEButton>
		</>
		}
	open={props.open}
	setOpen={props.setOpen}
		>
			<Form
				form={form}
				layout='vertical'
				onFinish={onFinish}
			>
				<LableRequired>
					Details
				</LableRequired>
				{
					props.viewOnly ?
						<p style={{ color: PrimaryTheme.secondaryRedError, fontSize: '0.8rem' }}>
							{/* {myMetricdataBulkUploadFiles.data.find((item) => item.id === props.fileId)?.rejection_reason} */}
						</p>
						:
						<Form.Item name={'description'} rules={[{ required: false,}]}>
							<BETextArea
								value={form.getFieldValue('description')}
								placeholder={'Type here'}
								form={form}
								name={'description'}
								showCount
								maxLength={1000}
								rows={6}
								onChange={(e: any) => {
									form.setFieldValue('description', e.target.value);
								}}
								disabled={props.viewOnly}
							/>
						</Form.Item>
				}
			</Form>
	  </BEDrawer>
	);
}
