import { setCurrentBusinessUnit } from "../../Redux/CommonReducer";
import { AppDispatch, RootState } from "../../Redux/store";
import { getBusinessUnitContributors, getBusinessUnits, getContributorBusinessUnits } from "../../views/Actions/BusinessUnitActions";
import { setCurrentYears } from "../../views/Actions/HandleBackgroundApiCalls";
import { getAppVersionAndDate, getFinancialYears } from "../../views/Actions/HomeActions";
import { getAllUsers, getUserProfile } from "../../views/Actions/UsersManagementActions";


export const handleMobileBackgroundAPICalls = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (state.common.version.number === '') dispatch(getAppVersionAndDate());
    if (state.user.userProfile?.status === 'idle') dispatch(getUserProfile());
    if (state.common.financialYears?.status === 'idle') await  dispatch(getFinancialYears());
    if (state.userMgmt.status === 'idle') await dispatch(getAllUsers());
    if (state.entity.BusinessUnits?.status === 'idle') await dispatch(getBusinessUnits());
    if (state.entity.BusinessUnitContributors?.status === 'idle') await dispatch(getContributorBusinessUnits());
    await dispatch(setCurrentYears());
}