import { handleAPICall } from "../../Config/Functions/HandleAPICall";
import { navigateTo } from "../../Navigation/NavigationManager";
import { GET_STAGE } from "../../Utils/Routes/Onboarding";
import { setOnboardingStage,setNotification,setVersion, setNotificationRead,setFinancialYears } from "../../Redux/CommonReducer";
import { AppDispatch, RootState } from "../../Redux/store";
import { 
  GET_NOTIFICATIONS,
  PATCH_NOTIFICATION,
  GET_APP_VERSION_AND_DATE,
  GET_USER_PERMISSIONS,
  POST_ROLE_PERMISSION,
  PATCH_ROLE_PERMISSION,
  GET_CELERY_TASKS_STATUS,
  GET_FINENCIAL_YEARS
 } from "../../Utils/Routes/UserRouts";
 import { setPermissions } from "../../Redux/UserReducer";
import { BEMessage } from "../../Components/BEMessage";

export const getStage = () => async (dispatch:AppDispatch) => {
    const [data, error] = await handleAPICall(GET_STAGE());
    if (data) {
      console.log(data)
      dispatch(setOnboardingStage({
        status: 'success',
        stage: data?.data.stage
      }))
      if(data?.data.stage<5)navigateTo('/onboarding');
    }
    else {
      console.log(data);
      dispatch(
        setOnboardingStage({
          status: "error",
          stage: 0,
        })
      );
    }
}

export const getNotifications = () => async (dispatch:AppDispatch) => {
  const [data, error] = await handleAPICall(GET_NOTIFICATIONS());
  if (data) {
    dispatch(setNotification({
      status: 'success',
      data: data?.data
    }))
  }
  else {
    dispatch(setNotification({
      status: 'error',
      data: []
    }))
  }
} 

export const patchNotification = (id:any,data:any) => async (dispatch:AppDispatch,getState:()=>RootState) => {
  const state = getState();
  const [res, error] = await handleAPICall(PATCH_NOTIFICATION(id,data));
  if (res) {
    console.log(res);
    dispatch(setNotificationRead({
      id,
      read: data.read
    }))
  }
  else {
    console.log(res);
  }
}

export const getAppVersionAndDate = () => async (dispatch:AppDispatch) => {
  const [data, error] = await handleAPICall(GET_APP_VERSION_AND_DATE());
  if (data) {
    dispatch(setVersion({
      number: data?.data.version,
      date: data?.data.release_date
    }))
  }
  else {
    console.log(data);
  }
}

export const getUserPermissions = (noLoad?:boolean) => async (dispatch:AppDispatch,getState:()=>RootState) => {
  if(!noLoad)dispatch(setPermissions({
    status: 'loading',
    data: []
  }))
  const [data, error] = await handleAPICall(GET_USER_PERMISSIONS());
  if (data) {
    let defaultPermissions:any=[]
    for(let i=1;i<=9;i++){
      defaultPermissions.push({role:'SUPER_ADMIN',section:i,permission:4})
      defaultPermissions.push({role:'AUDITOR',section:i,permission:1})
    }
    dispatch(setPermissions({
      status: 'success',
      data: [...defaultPermissions,...data?.data]
    }))
  }
  else {
    console.log(data);
    dispatch(setPermissions({
      status: 'error',
      data: []
    }))
  }
}

export const postRolePermission = (data:any[]) => async (dispatch:AppDispatch) => {
  const [res, error] = await handleAPICall(POST_ROLE_PERMISSION(data));
  if (res) {
    console.log(res);
    dispatch(getUserPermissions(true));
  }
  else {
    console.log(error);
    dispatch(setPermissions({
      status: 'error',
      data: []
    }))
  }
}

export const patchRolePermission = (id:any,data:any) => async (dispatch:AppDispatch) => {
  const [res, error] = await handleAPICall(PATCH_ROLE_PERMISSION(id,data));
  if (res) {
    console.log(res);
    dispatch(getUserPermissions(true));
  }
  else {
    console.log(error);
    dispatch(setPermissions({
      status: 'error',
      data: []
    })
    )
  }
}


export const getCeleryTaskStatus = (task_id:string) => async (dispatch:AppDispatch) => {
  const [res, error] = await handleAPICall(GET_CELERY_TASKS_STATUS(task_id));
  if (res) {
    return res?.data;
  }
  else {
    console.log(error);
  }
}

export const getCeleryTaskResult = (task_id:string) => async (dispatch:AppDispatch) => {
  const [res, error] = await handleAPICall(GET_CELERY_TASKS_STATUS(task_id));
  if (res) {
    return res?.result;
  }
  else {
    console.log(error);
  }
}

export const checkCeleryTaskStatusPeriodically = (task_ids: string[], interval:number, callback:Function) => async (dispatch:AppDispatch) => {
  const intervalId = setInterval(async () => {
    let allDone = true;
    for(let i=0;i<task_ids?.length;i++){
      const res = await dispatch(getCeleryTaskStatus(task_ids[i]));
      if (res === 'FAILURE') {
        allDone = false;
        continue;
      }
      if(res!=='SUCCESS'){
        allDone = false;
        break;
      } else {
        task_ids.splice(i, 1);
        i--;
      }
    }
    if(allDone){
      clearInterval(intervalId);
      callback();
    }
  }, interval);
}

export const getFinancialYears = (noLoad?:boolean) => async (dispatch:AppDispatch,getState:()=>RootState) => {
  if(!noLoad)dispatch(setFinancialYears({
    status: 'loading',
    data: []
  }))
  const [data, error] = await handleAPICall(GET_FINENCIAL_YEARS());
  const currentYears = getState().common.financialYears?.data
  const newYears = Array.from(new Set(data?.data.map((item:any)=>item.year))).filter((item:any)=>!currentYears.includes(item))

  if (data) {
    dispatch(setFinancialYears({
      status: 'success',
      data: Array.from(new Set([...currentYears,...newYears])).sort((a:any,b:any)=>a-b)
    }))
  }
  else {
    dispatch(setFinancialYears({
      status: 'error',
      data: []
    }))
  }
}
