import * as React from 'react';
import { useTypedSelector } from '../../../../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../../../../Config/Hooks/useAppDispatch';
import { ReportingEvidenceTypes, TemplateSelectedTopicTypes } from '../../../../../../../../../Redux/Types/reportingTypes';
import { ColumnsType } from 'antd/es/table';
import DownloadIcon from '../../../../../../../../../Components/BEIcons/DownloadIcon';
import { DeleteIcon } from '../../../../../../../../../stories/Icon.stories';
import { BETable } from '../../../../../../../../../Components/BETable';
import { deleteReportingEvidence } from '../../../../../../../Actions/template';
import { BEButton } from '../../../../../../../../../Components/BEFormItems/BEButton';
import { AddReportingEvidenceDrawer } from '../../../../../AddEvidence';

export interface ITopicEvidanceTableProps {
  uniqueCode: string;
  review?: boolean;
}

export function TopicEvidanceTable(props: ITopicEvidanceTableProps) {
  const dispatch = useAppDispatch();
  const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
  const currentReport = useTypedSelector(state => state.reporting.currentReport);
  const evidence = useTypedSelector(state => state.reporting.evidence);
  const [tableData, setTableData] = React.useState<any>([]);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);

  const columns: ColumnsType<ReportingEvidenceTypes> = [
    {
      title: 'S No.',
      key: 'sno',
      render: (text: string, record: ReportingEvidenceTypes, index: number) => {
        return index + 1;
      }
    },
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Evidence',
      dataIndex: 'evidence',
      key: 'evidence',
      render: (text: string, record: ReportingEvidenceTypes) => {
        return (
          <a href={record.evidence} target="_blank" rel="noreferrer">
            <DownloadIcon />
          </a>
        );
      }
    }, {
      title: 'Action',
      key: 'action',
      render: (text: string, record: ReportingEvidenceTypes) => {
        return (
          <div onClick={async () => {
            await dispatch(deleteReportingEvidence(record.id));
          }}>
            <DeleteIcon />
          </div>
        );
      }
    }
  ]

  React.useEffect(() => {
    if (selectedTopics?.data?.length > 0 && currentReport !== null) {
      let currentUniqueCode = props.uniqueCode;
      let tempData: ReportingEvidenceTypes[] = [];
      evidence?.data.forEach((evidence: any) => {
        if (evidence.unique_code === currentUniqueCode) {
          tempData.push(evidence);
        }
      });
      setTableData(tempData);
    }
  }, [selectedTopics, currentReport, evidence, props.uniqueCode]);

  return (
    <div style={{ padding: '0 1rem 1rem 1rem' }}>
      {
        !props.review &&
        <BEButton size='large' style={{ marginBottom: '1rem' }} onClick={() => {
          setOpenDrawer(true);
        }}>
          Add Evidence
        </BEButton>
      }
      <BETable data={tableData}
        columns={props.review ? columns.slice(0, 3) : columns}
      />
      <AddReportingEvidenceDrawer
        open={openDrawer}
        setOpen={setOpenDrawer}
        currentUniqueCode={props.uniqueCode}
      />
    </div>
  );
}
