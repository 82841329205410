import * as React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AddCommaToNumber } from '../../../../Config/Functions/UsefullFunctions';
import { graphStrokeColorArray } from '../../../../Config/Data/dashboardData';


export interface IBEFilterableLineChartProps {
    data: any[];
}

export function BEFilterableLineChart(props: IBEFilterableLineChartProps) {
    const [keys, setKeys] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (!props.data || props.data?.length === 0) {
            return;
        }
        const dataUnit = props.data[0];
        let keys = [];
        for (let key in dataUnit) {
            if (key !== 'name') {
                keys.push(key);
            }
        }
        setKeys(keys);
    }, [props.data]);

    return (
        <ResponsiveContainer width="100%" height={400}>
            <LineChart
                width={500}
                height={300}
                data={props.data}
                margin={{
                    right: 10
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={(value) => {
                    return AddCommaToNumber(value, false, true, true, 0);
                }} />
                <Tooltip />
                <Legend />
                {keys.map((key, index) => {
                    return (
                        <Line
                            type="monotone"
                            dataKey={key}
                            stroke={graphStrokeColorArray[index % graphStrokeColorArray?.length]}
                            key={index}
                        />
                    );
                })}
            </LineChart>
        </ResponsiveContainer>
    );
}
