import React, { ReactNode } from 'react'
import './index.scss'
import PDF from '../../../../assets/images/FilesIcons/pdf.png';
import EXCEL from '../../../../assets/images/FilesIcons/excel.png';
import FILE from '../../../../assets/images/FilesIcons/file.png';
import IMAGE from '../../../../assets/images/FilesIcons/image.png';
import MenuIcon from '../../../../Components/BEIcons/MenuIcon';
import { Dropdown, MenuProps, Space } from 'antd';
import { FileType } from '../../../../Redux/Types/vaultTypes';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { deleteVaultFiles } from '../../Actions';
import ToolTip from '../../../../Components/BEToolTip';

export const FileIcons:any = {
    pdf: PDF,
    xlsx: EXCEL,
    xls: EXCEL,
    png: IMAGE,
    jpg: IMAGE,
    jpeg: IMAGE,
    gif: IMAGE, 
     //for every other  file type

}

export interface IFileProps {
    fileName: string;
    file:   FileType,
    setOpenEdit:Function;
    readonly?:boolean
}


export const FileIconWithMenu = ({
    menuItems,
    iconSrc,
    fileName
}: {
    menuItems: MenuProps['items'],
    iconSrc: string;
    fileName: string;
}) => {
    return  <div className='file-container'>
    <div className='menu'>
        <Dropdown menu={{ items: menuItems }}>
            <a onClick={(e) => e.preventDefault()}>
                <MenuIcon />
            </a>
        </Dropdown>
    </div>
    <div className='circle'>
        <span><img src={iconSrc} /></span>
    </div>
    {   
        fileName?.length > 15 ?
        <ToolTip title={fileName} placement='bottom'>
            <div className='file-name'>{fileName}</div>
        </ToolTip>
        :
        <div className='file-name'>{fileName}</div>
    }
</div>
}

export function File(props: IFileProps) {
    const dispatch = useAppDispatch();
    const {readonly=false}=props
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <span onClick={() => 
                    window.open(props.file.file, '_blank')
                }>
                    Download
                </span>
            ),
        },
        ...(!readonly ? [{
            key: '2',
            label: (
                <span onClick={() =>{ 
                    if(props.setOpenEdit){
                    props.setOpenEdit(props.file);
                    }
                }
                }>
                    Edit
                </span>
            ),
        },
        {
            key: '3',
            label: (
                <span onClick={() => 
                    dispatch(deleteVaultFiles(props.file.id))
                }>
                    Delete
                </span>
            ),
        }]: [])
    ];
    return ( 
        <FileIconWithMenu 
        menuItems={items}
    iconSrc={
        FileIcons[props.file.file.split(/[/?]/)[4].split('.')[1] || 'file'] 
        || FILE
    }
    fileName={props.fileName}
        />
    )
}

export default File
