import type { StatusType } from "../Types/userTypes";
import { EmisionsState } from "../Types/emisionsTypes";

import {
  Reducer,
  createSlice,
} from "@reduxjs/toolkit";
import { clearStore } from "../ExtraReducrActions";


const initialState: EmisionsState = {
  configurations: {
    scope1Configurations: {
      status: "idle",
      data: [],
    },
    currentScope1Configuration: null,
    scope2Configurations: {
      status: "idle",
      data: [],
    },
    scope2Specifications: [],
    scope2EmissionConfigurations: {
      status: "idle",
      data: [],
    }
  },
  emissionsCalculations:{
    scope1Calculations:{
        status:'idle',
        data:[]
    },
    scope2Calculations:{
        status:'idle',
        data:[]
    },
    scope3Calculations:{
        status:'idle',
        data:[]
    },
  },
  filteredEmissionCalculations: {
    scope1Calculations:{
        status:'idle',
        data:[]
    },
    scope2Calculations:{
        status:'idle',
        data:[]
    },
    scope3Calculations:{
        status:'idle',
        data:[]
    },
    scope3GraphCalculation:{
        status:'idle',
        data:[]
    }
  },
  scope2EmissionType: 1,
  emissionFactors: {
    scope2EmissionFactors: {
      status: "idle",
      data: [],
    },
  },
  dateSlicer: {
    currentYear: 0,
    currentMonth: 0,
  },
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
  reducers: {
    setScope1Configurations: (state, action) => {
      state.configurations.scope1Configurations = action.payload;
    },
    setScope2Configurations: (state, action) => {
      state.configurations.scope2Configurations = action.payload
    },
    setCurrentScope1Configuration: (state, action) => {
      state.configurations.currentScope1Configuration = action.payload;
    },
    setDateSlicerYear: (state, action) => {
      state.dateSlicer.currentYear = action.payload;
    },
    setDateSlicerMonth: (state, action) => {
      state.dateSlicer.currentMonth = action.payload;
    },
    setScope2Specifications: (state, action) => {
      state.configurations.scope2Specifications = action.payload
    },
    setScope2EmissionConfigurations: (state, action) => {
      state.configurations.scope2EmissionConfigurations = action.payload
    },
    setScope1Calculations: (state, action) => {
      state.emissionsCalculations.scope1Calculations = action.payload;
    },
    setScope2Calculations: (state, action) => {
        state.emissionsCalculations.scope2Calculations = action.payload;
    },
    setScope3Calculations: (state, action) => {
        state.emissionsCalculations.scope3Calculations = action.payload;
    },
    setScope1FilteredCalculations: (state, action) => {
        state.filteredEmissionCalculations.scope1Calculations = action.payload;
    },
    setScope2FilteredCalculations: (state, action) => {
        state.filteredEmissionCalculations.scope2Calculations = action.payload;
    },
    setScope3FilteredCalculations: (state, action) => {
        state.filteredEmissionCalculations.scope3Calculations = action.payload;
    },
    setScope3FilteredGraphCalculation: (state, action) => {
        state.filteredEmissionCalculations.scope3GraphCalculation = action.payload;
    },
    setScope2EmissionType: (state, action) => {
        state.scope2EmissionType = action.payload;
    },
    setScope2EmissionFactors: (state, action) => {
      state.emissionFactors.scope2EmissionFactors = action.payload
    },
  },
});

export const {
  setScope1Configurations,
  setScope2Configurations,
  setCurrentScope1Configuration,
  setDateSlicerYear,
  setDateSlicerMonth,
  setScope2Specifications,
  setScope1Calculations,
  setScope2Calculations,
  setScope3Calculations,
  setScope1FilteredCalculations,
  setScope2FilteredCalculations,
  setScope3FilteredCalculations,
  setScope2EmissionType,
  setScope2EmissionFactors,
  setScope2EmissionConfigurations
} = userSlice.actions;

const EmisionsReducer: Reducer<EmisionsState> = userSlice.reducer;

export default EmisionsReducer;
