import * as React from 'react';
import { BEDrawer } from '../../../../Components/BEDrawer';
import { myMetricType } from '../../../../Redux/Types/dataManagerTypes';
import { Checkbox, Form } from 'antd';
import { LableRequired } from '../../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import GooglePlaces from '../../../../Components/GooglePlaces';
import { BETabs } from '../../../../Components/BETabs';
import './style.scss';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { deleteScope2EmissionFactors, patchScope2EmissionFactors, postScope2EmissionFactors } from '../../Actions';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { BEMessage } from '../../../../Components/BEMessage';
import BEConfirmModal from '../../../../Components/BEConfirmModal';

export interface IAddCustomEFDrawerProps {
  open: boolean;
  setOpen: Function
  isEdit?: boolean
  currentEF?: any
  currentMetric:myMetricType | null
}

export function AddCustomEFDrawer (props: IAddCustomEFDrawerProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [location, setLocation] = React.useState<string>('')
  const [duplicate, setDuplicate] = React.useState(true)
  const [activeTab, setActiveTab] = React.useState('location_based')
  const [loading, setLoading] = React.useState(false)
  const allEFs = useTypedSelector(state => state.emissions.emissionFactors.scope2EmissionFactors)
  const [openDelete, setOpenDelete] = React.useState(false)

  React.useEffect(() => {
    if(!props.open) return
    setActiveTab('location_based')
    if(props.isEdit){
      form.setFieldsValue({
        ef_name: props.currentEF.ef_name,
        ef_value_lb: props.currentEF.ef_value_lb,
        calorific_value_lb: props.currentEF.calorific_value_lb,
        ef_source_lb: props.currentEF.ef_source_lb,
        ef_version_lb: props.currentEF.ef_version_lb,
        ef_value_mb: props.currentEF.ef_value_mb,
        ef_source_mb: props.currentEF.ef_source_mb,
        ef_version_mb: props.currentEF.ef_version_mb,
      })
      setLocation(props.currentEF.ef_location)
      if(
        props.currentEF.ef_value_mb === props.currentEF.ef_value_lb &&
        props.currentEF.ef_source_mb === props.currentEF.ef_source_lb &&
        props.currentEF.ef_version_mb === props.currentEF.ef_version_lb
      )
      setDuplicate(true)
      else setDuplicate(false)
    }
    else{
      form.resetFields()
      setLocation('')
      setDuplicate(true)
    }
  }, [props.open])

  const onSubmit = async(values:any) => {
    // check if name already exists
    if(allEFs.data.some((ef:any) => ef.ef_name === values.ef_name
      // && ef.bcode === props.currentMetric?.bcode
      && (
        !props.isEdit || props.currentEF.id !== ef.id
      ))){
        return BEMessage.error('Emission Factor with this name already exists')
      }

    if(duplicate){
      values.ef_value_mb = values.ef_value_lb
      values.ef_source_mb = values.ef_source_lb
      values.ef_version_mb = values.ef_version_lb
    }
    const dataToSend = {
      ...values,
      ef_location: location,
      my_metric: props.currentMetric?.id,
      bcode: props.currentMetric?.bcode,
    }
    setLoading(true)
    if(!props.isEdit) await dispatch(postScope2EmissionFactors(dataToSend))
    else await dispatch(patchScope2EmissionFactors(props.currentEF.id, dataToSend))
    setLoading(false)
    props.setOpen(false)
  }

  

  return (
    <BEDrawer
      width='652px'
      open={props.open}
      setOpen={props.setOpen}
      heading={`${props.isEdit ? 'Edit' : 'Add'} Emission Factor: ${props.currentMetric?.title} (${props.currentMetric?.unit})`}
      footer={
        <>
          <BEButton className='primary' size='large'
            loading={loading}
            onClick={() =>form.submit()}
          >
            {props.isEdit ? 'Save' : 'Add'}
          </BEButton>
          <BEButton size='large'
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </BEButton>
          <BEButton className='primary-red' size='large'
            onClick={() => setOpenDelete(true)}
          >
            Delete
          </BEButton>
        </>
      }
     >
        <div className='add-custom-ef-drawer'>
          <Form 
            form={form}
            onFinish={onSubmit} 
          >
            <LableRequired>Emission Factor Name</LableRequired>
            <Form.Item
              name='ef_name'
              rules={[{ required: true, message: 'Please enter Emission Factor Name' }]}
            >
              <BEInput placeholder='Enter Emission Factor Name' />
            </Form.Item>     
            <LableRequired>Location</LableRequired>
              {
                props.open &&
                <GooglePlaces value={location} setValue={setLocation}/>
              }
            <BETabs
              activeKey={activeTab}
              onChange={(key) => setActiveTab(key)}
              size='small'
              items={[
                {
                  key: 'location_based',
                  label: 'Location Based',
                  children:
                  <div>
                    <br/>
                    <div className='duplicate-box'>
                      <Checkbox checked={duplicate} onChange={(e) => {
                        setDuplicate(e.target.checked)
                        if(e.target.checked) setActiveTab('location_based')
                        }}/>
                        <div>
                          <p><strong>Duplicate location-based emission factor </strong></p>
                          <p className='small-disc'>The inputted location-based emission factor will also be taken as the market-based emission factor. This can be used when there is no specific market-based emission factor, particularly for non-renewable forms of purchased energy (e.g., purchased grid electricity).</p>
                        </div>
                    </div>
                    <LableRequired>Emission factor value (kgCO2e per unit)</LableRequired>
                    <Form.Item
                      name='ef_value_lb'
                      rules={[{ required: true, message: 'Please enter Emission Factor' }]}
                    >
                      <BEInput placeholder='Enter Emission Factor' type='number'/>
                    </Form.Item>

                    {
                      props.currentMetric?.unit !== 'kWh' &&
                      <>
                        <LableRequired>Calorific Value (kWh per unit)</LableRequired>
                        <Form.Item
                          name='calorific_value_lb'
                          rules={[{ required: true, message: 'Please enter Calorific Value' }]}
                        >
                          <BEInput placeholder='Enter Calorific Value' type='number'/>
                        </Form.Item>
                      </>
                    }

                    <LableRequired>Emission factor source</LableRequired>
                    <Form.Item
                      name='ef_source_lb'
                      rules={[{ required: true, message: 'Please enter Emission Factor Source' }]}
                    >
                      <BEInput placeholder='Enter Emission Factor Source' />
                    </Form.Item>

                    <LableRequired>Emission factor version</LableRequired>
                    <Form.Item
                      name='ef_version_lb'
                      rules={[{ required: true, message: 'Please enter Emission Factor Version' }]}
                    >
                      <BEInput placeholder='Enter Emission Factor Version' />
                    </Form.Item>

                  </div>
                },
                {
                  key: 'market_based',
                  label: 'Market Based',
                  children: 
                  duplicate ?
                  <></>:<div><br/>
                  <div className='duplicate-box'>
                    <Checkbox checked={duplicate} onChange={(e) => {
                      setDuplicate(e.target.checked)
                      if(e.target.checked) setActiveTab('location_based')
                      }}/>
                      <div>
                        <p><strong>Duplicate location-based emission factor </strong></p>
                        <p className='small-disc'>The inputted location-based emission factor will also be taken as the market-based emission factor. This can be used when there is no specific market-based emission factor, particularly for non-renewable forms of purchased energy (e.g., purchased grid electricity).</p>
                      </div>
                    </div>
                    <LableRequired>Emission factor value (kgCO2e per unit)</LableRequired>
                    <Form.Item
                      name='ef_value_mb'
                      rules={[{ required: true, message: 'Please enter Emission Factor' }]}
                    >
                      <BEInput placeholder='Enter Emission Factor' type='number'/>
                    </Form.Item>

                    <LableRequired>Emission factor source</LableRequired>
                    <Form.Item
                      name='ef_source_mb'
                      rules={[{ required: true, message: 'Please enter Emission Factor Source' }]}
                    >
                      <BEInput placeholder='Enter Emission Factor Source' />
                    </Form.Item>

                    <LableRequired>Emission factor version</LableRequired>
                    <Form.Item
                      name='ef_version_mb'
                      rules={[{ required: true, message: 'Please enter Emission Factor Version' }]}
                    >
                      <BEInput placeholder='Enter Emission Factor Version' />
                    </Form.Item>

                  </div>,
                  disabled: duplicate
                },
              ]}
            />      
          </Form>
        </div>
        <BEConfirmModal type='error' visible={openDelete} setVisible={setOpenDelete} onConfirm={async() => {
          await dispatch(deleteScope2EmissionFactors(props.currentEF.id))
          props.setOpen(false)
        }} title='Delete Emission Factor' message='Are you sure you want to delete this Emission Factor?'
        confirmButtonText='Delete'
        />
        
        
        
        
      </BEDrawer>
  );
}
