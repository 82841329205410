import React, { useContext, useEffect, useRef, useState } from 'react';
import type { FormInstance, InputRef } from 'antd';
import { Form, Input, Select, Table } from 'antd';
import { number } from 'prop-types';
import TextArea from 'antd/es/input/TextArea';
import './style.scss';
import BEDatePicker from '../../../BEDatePicker';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import dayjs from 'dayjs';


interface recordType {
  [key: string]: any;
}

export interface EditableCellRules {
  isRequired?: boolean;
  isNumber?: boolean;
  isDate?: boolean;
  isDropDown?: boolean;
  dropDownOptions?: {
    value: string | number;
    label: string;
  }[];
  isDisabled?: boolean;
}

export interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  errorRecord: recordType;
  record: recordType;
  handleSave: Function;
  EditableContext: React.Context<FormInstance<any> | null>;
  rules?: EditableCellRules;
  allowEmptySave?: boolean;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  errorRecord,
  handleSave,
  EditableContext,
  allowEmptySave,
  rules,
  ...restProps
}) => {
  // const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const selectRef = useRef<any>(null);
  const form = useContext(EditableContext || React.createContext<FormInstance<any> | null>(null))!;
  const currentCategory = useTypedSelector(state => state.reporting.selectedTemplateCategory);
  const currentSection = useTypedSelector(state => state.reporting.selectedTemplateSection);
  const currentDimension = useTypedSelector(state => state.reporting.selectedTemplateDimension);
  const reportData = useTypedSelector(state => state.reporting.reportData);
  const [date, setDate] = useState<any>(null);


  useEffect(() => {
      if (rules?.isDropDown) {
        selectRef.current?.focus();
      } else {
        inputRef.current?.focus();
      }
    },[]);

  useEffect(() => {
    if (record)
      if (rules?.isDate && (record[dataIndex] === '' || record[dataIndex] === 'nan' || !record[dataIndex])) {
        setDate(undefined);
      } else {
        form?.setFieldsValue({ [dataIndex]: record[dataIndex] });
      }
  }, [record, currentCategory, currentSection, currentDimension, reportData]);

  const save = async (value?: any) => {
    try {
      if (rules?.isDate && value === '') {
        form.setFieldsValue({ [dataIndex]: 'nan' });
        let values = await form.validateFields();
        let res = await handleSave({ ...record, ...values }, dataIndex);
      }
      if (value !== undefined &&  rules?.isDate) {
        form.setFieldsValue({ [dataIndex]: value });
      }
      let values = await form.validateFields();
      if (values[dataIndex] !== '' && values[dataIndex]){ 
        let res = await handleSave({ ...record, ...values }, dataIndex);
      }
      console.log('values', values);
      if (values[dataIndex] === '' && allowEmptySave) {
        let res = await handleSave({ ...record, ...values }, dataIndex);
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  useEffect(() => {
    if(record && record[dataIndex])
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  }, [record]);

  let childNode = children;

  if (editable) {
    childNode =
      <Form.Item
        help={errorRecord && errorRecord[dataIndex] ? <span style={{
          color: PrimaryTheme.primaryRedError,
          fontSize: '0.7rem'
      }}>{errorRecord[dataIndex]}</span> : null}
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: rules?.isRequired || false,
            message: `${title} is required.`,
          },
        ]}
      >
        {
          rules?.isDropDown && (
            <Select
              disabled={rules?.isDisabled}
              ref={selectRef}
              onChange={save}
              style={{ minWidth: '10rem', width: 'auto' }}
              dropdownStyle={{ minWidth: '10rem', width: 'auto' }}
            >
              {rules?.dropDownOptions?.map((option, index) => (
                <Select.Option key={index} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          )
        }
        {
          rules?.isDate && (
            <BEDatePicker
              format='YYYY-MM-DD'
              value={date}
              disabled={rules?.isDisabled}
              handleDateChange={(date: Date | null) => {
                if (!date) {
                  save('');
                  return;
                }
                const dateToSave = dayjs(date).format('YYYY-MM-DD')
                save(dateToSave);
                setDate(dateToSave);
              }}
              acceptNullData={true}
            />
          )

        }
        {
          !rules?.isDropDown && !rules?.isDate && (
            <TextArea
              disabled={rules?.isDisabled}
              ref={inputRef}
              autoSize={{ minRows: 1, maxRows: 6 }}
              rows={1}
              onPressEnter={save} onBlur={save}
            />
          )
        }
      </Form.Item>
  }

  return <td {...restProps} className='editable-cell' >{childNode}</td>;
};
