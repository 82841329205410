import * as React from 'react';
import { MyMetricData } from '../../../../Redux/Types/dataManagerTypes';
import './styles.scss';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { ChangeDateFormat } from '../../../../Config/Functions/UsefullFunctions';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import BEStatusTag from '../../../../Components/BEStatusTag';

interface IMetricEntryRowProps {
	index: number;
	myMetricData: MyMetricData | undefined;
	setCurrentMyMetricData: Function;
	setOpenMetricUploadDrawer: Function;
}

export function MetricEntryRow (props: IMetricEntryRowProps) {
	const myMetrics = useTypedSelector(state => state.onBoarding.metrics.myMetrics);
	return (
	  <div className={`mb-metric-entry-row ${props.myMetricData?.status === 3 ? 'rejected':''}`}>
		<div className='row-1'>
			<div style={{display:'flex'}}>
				<h3 style={{fontSize:'1.1rem', fontWeight:'400', marginRight:'0.5rem'}}>{props.index}. {ChangeDateFormat(props.myMetricData?.date_of_entry||'').slice(3, 11)}</h3>
				{props.myMetricData?.status === 3 && 
				<BEStatusTag status={'error'}>
					Rejected
				</BEStatusTag>}
			</div>
			<BEButton
			onClick={()=>{
				props.setCurrentMyMetricData(props.myMetricData);
				props.setOpenMetricUploadDrawer(myMetrics.data.find((item) => item.id === props.myMetricData?.my_metric));
			}}
			size='small' style={{borderColor: PrimaryTheme.primaryGreen, color: PrimaryTheme.primaryGreen}}>
				View
			</BEButton>
		</div>
		<div className='row-2'>
			<div style={{display:'flex', color: PrimaryTheme.primaryGray}}>
				<span>Value: </span>
				<h3> {props.myMetricData?.value} {myMetrics.data.find((item) => item.id === props.myMetricData?.my_metric)?.unit} </h3>
			</div>
			{props.myMetricData?.evidence &&<a href={props.myMetricData?.evidence} target='_blank'>Evidence</a> }
		</div>
	  </div>
	);
}
