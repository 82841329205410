import { useEffect } from "react";
import { useTypedSelector } from "../../../../Config/Hooks/useTypedSelector";
import { setScope1FilteredCalculations,setScope2FilteredCalculations,setScope3FilteredCalculations } from "../../../../Redux/EmmisionsReducer";
import { useAppDispatch } from "../../../../Config/Hooks/useAppDispatch";
import { getMonthsByQuarter } from "../../../../Config/Functions/UsefullFunctions";
import dayjs from "dayjs";

export function SetFilteredData(){
  const yearEnd = useTypedSelector(state => state.companies.yearEnd);
  const businessUnitRefs = useTypedSelector(state => state.entity.BusinessUnitRefs);
	const currentBU = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
  const currentBG = useTypedSelector(state => state.common.homePage.currentBusinessGroup);

  const scope1Calculations = useTypedSelector(state => state.emissions.emissionsCalculations.scope1Calculations);
  const scope2Calculations = useTypedSelector(state => state.emissions.emissionsCalculations.scope2Calculations);
  const scope3Calculations = useTypedSelector(state => state.emissions.emissionsCalculations.scope3Calculations);
  const currentMonth = useTypedSelector((state) => state.dataManager.metrics.dateSlicer.currentMonth);
	const scope2EmissionType = useTypedSelector(state => state.emissions.scope2EmissionType);

  const dispatch = useAppDispatch();

	const filterDataUsingBUBGandMonth = async (data:any) => {
		if(businessUnitRefs.status !== 'success' || !yearEnd) return data;
		// filter data by month ,
		// month = 0 means all months, month < 13 means specific month,
		// month > 12 means quarter 13,14,15,16 for Q1,Q2,Q3,Q4
		let filteredDataByMonth:any = [];
		if(currentMonth === 0) filteredDataByMonth = data;
		else if(currentMonth < 13) filteredDataByMonth = data.filter((obj:any) => obj.month === currentMonth);
		else {
			const monthsOfQuarter = getMonthsByQuarter(currentMonth, dayjs(yearEnd).month());
			filteredDataByMonth = await data.filter((obj:any) => monthsOfQuarter.includes(obj.month));
		}

		// filter data by BU
		// if BU,BG is 0 means all BU,BG
		// if BU is 0 and BG is not 0 means all BU of BG
		// if BU is not 0 and BG is 0 means specific BU
		if(currentBU === 0 && currentBG === 0) return filteredDataByMonth;

		let businessUnits:any = new Set();
		if(currentBU !== 0) businessUnits.add(currentBU);
		else if(currentBG !== 0) businessUnitRefs.data.forEach((obj:any) => obj.business_group === currentBG ? businessUnits.add(obj.business_unit) : null);

		const filteredDataByMonthAndBU = await filteredDataByMonth.filter((obj:any) => businessUnits.has(obj.business_unit));
		return filteredDataByMonthAndBU;
	}

	const setFilteredData = async (type:any) => {
		let dataValMap:any = {
			scope1: scope1Calculations,
			scope2: scope2Calculations,
			scope3: scope3Calculations,
		};

		let dispatchFunMap:any = {
			scope1: setScope1FilteredCalculations,
			scope2: setScope2FilteredCalculations,
			scope3: setScope3FilteredCalculations,
		};

		let data = await filterDataUsingBUBGandMonth(dataValMap[type].data);

		if(type === 'scope2'){
			data = data.filter((obj:any) => obj.emission_type === scope2EmissionType);
		}
		
		if(data == null) return;
			dispatch(dispatchFunMap[type]({
				status:'success',
				data:data
			}))
			
		}

	useEffect(()=>{
		if(scope1Calculations.status === 'success') setFilteredData('scope1');
	},[scope1Calculations,currentBG,currentBU,currentMonth,businessUnitRefs,yearEnd])

	useEffect(()=>{
		if(scope2Calculations.status === 'success') setFilteredData('scope2');
	},[scope2Calculations,currentBG,currentBU,currentMonth,businessUnitRefs,yearEnd,scope2EmissionType])

	useEffect(()=>{
		if(scope3Calculations.status === 'success') setFilteredData('scope3');
	},[scope3Calculations,currentBG,currentBU,currentMonth,businessUnitRefs,yearEnd])


	return <></>
}