import React from 'react';
import { TopSection } from '../Components/TopSection';
import { BETabs } from '../../../Components/BETabs';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import ConnectedIntegrations from './Connected';
import AvailableIntegrations from './Available';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { getAvailableSystems, getIntegrationConfig } from '../Actions';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import { GlobalLoader } from '../../../Components/GlobalLoader';

export interface IntegrationType {
    id: number;
    title: string;
    description: string;
    integrate: boolean;
    logo: string;
    api_key?: string;
    endpoint?: string;
    system?: number;
    comingSoon?: boolean;
}
const Integrations = () => {
  const [availableIntegrations, setAvailableIntegrations] = React.useState<IntegrationType[]>([]);
  const [filteredAvailableIntegrations, setFilteredAvailableIntegrations] = React.useState<IntegrationType[]>([]);
  const [connectedIntegrations, setConnectedIntegrations] = React.useState<IntegrationType[]>([]);
  const [selectedIntegration, setSelectedIntegration] = React.useState<IntegrationType| null>(null);
  const [loading, setLoading] = React.useState(false);
  const integrations = useTypedSelector((state) => state.settings.integrations);
  const dispatch = useAppDispatch();

  function getIntegrations() {
    setLoading(true);
    dispatch(getIntegrationConfig())
    dispatch(getAvailableSystems()).
      then((data) => {
        setAvailableIntegrations(data.body);
    })
    setLoading(false);
  }
  
  React.useEffect(() => {
    getIntegrations();
  },[])
  
  React.useEffect(() => {
    const filteredConnectedIntegrations = integrations.data.map((item)=>{
      const matchingIntegration = availableIntegrations.find((ai) => ai.id === item.system);
      if(!matchingIntegration) return undefined;
      if(matchingIntegration){
        return {
          id: item.id,
          title: matchingIntegration.title,
          description: matchingIntegration.description,
          integrate: item.is_active,
          logo: matchingIntegration.logo,
          api_key: item.api_key,
          endpoint: item.endpoint,
          system: item.system
        }
      }
    }).filter((item) => item !== undefined) as IntegrationType[];
    setConnectedIntegrations(filteredConnectedIntegrations);
  }, [integrations, availableIntegrations])


  React.useEffect(() => {
    const filteredAvailableIntegrations = availableIntegrations.map((item) => {
      return {
        id: item.id,
        title: item.title,
        description: item.description,
        logo: item.logo,
        integrate: connectedIntegrations.find((ci) => ci.system === item.id)?.integrate ? true : false,
        system: item.id,
        comingSoon: item.title === 'Google Drive' || item.title === 'Microsoft one drive' || false,
      }
  })
  setFilteredAvailableIntegrations(filteredAvailableIntegrations);
  },[connectedIntegrations, availableIntegrations, integrations])

    
  return (
    <>
      <TopSection />
      {loading ? <GlobalLoader/> :<div style={{backgroundColor:PrimaryTheme.primaryBlueLight}}>
        <BETabs type='card' tabBarStyle={{ marginLeft: '1.5rem' ,marginTop:'2rem'}}
         onChange={()=>{setSelectedIntegration(null)}}
         items={
          [
            {label:`Connected(${(connectedIntegrations)?.length})`, key:'connected', children: <ConnectedIntegrations selectedIntegration={selectedIntegration} setSelectedIntegration={setSelectedIntegration}  integrations={connectedIntegrations}/> },
            {label:`Available(${(filteredAvailableIntegrations)?.length})`, key:"available", children: <AvailableIntegrations selectedIntegration={selectedIntegration} setSelectedIntegration={setSelectedIntegration} integrations={filteredAvailableIntegrations}/> }
          ]}
         />
      </div>}
    </>
  );
}

export default Integrations