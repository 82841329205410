import * as React from 'react';
import {FrameworkCard} from '../../../Components/FrameworkCard'
import { Frameworks, ItemProps } from '../../../../../data/frameworks';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';

interface IFrameworkProps {
}

export function Framework (props: IFrameworkProps) {
	const allReports = useTypedSelector(state => state.reporting.allReports);
	return (
	  <div>
		<div className='framework-container'>
		<h2>Framework</h2>
		<div className='framework-cards'>
			{Frameworks.map((framework:ItemProps|any)=> 
				 allReports?.data?.filter((report:any) => report.framework === framework.code).map((report:any) =>
					<FrameworkCard
					   reportName={report.name}
					   icon={framework.img} 
					   name={framework.title} 
					   percent={report.progress}
				   />)
				)
			}
			{
				allReports.data?.length < 9 
					? Array(9 - allReports.data?.length).fill({}).map((emptyReports:any) => {
						return <FrameworkCard
								reportName={''}
								icon={''}
								name={''}
								percent={0}
								/>
						}) : null
			}
		</div>
		</div>
	  </div>
	);
}
