import { Col, Progress, ProgressProps, Row } from 'antd';
import * as React from 'react';
import { PrimaryTheme } from '../../Config/Theme/theames';
import './style.scss';

export interface IBESemiProgressCardProps {
    percent: number;
    title: string;
}

export function BESemiProgressCard(props: IBESemiProgressCardProps) {
    const conicColors: ProgressProps['strokeColor'] = {
        '0%': props.percent > 85 ? PrimaryTheme.primaryGreen : props.percent > 40 ? PrimaryTheme.amber : PrimaryTheme.primaryRedError,
        '40%': props.percent > 85 ? PrimaryTheme.primaryGreen : props.percent > 40 ? PrimaryTheme.amber : PrimaryTheme.primaryRedError,
        '85%': props.percent > 85 ? PrimaryTheme.primaryGreen : props.percent > 40 ? PrimaryTheme.amber : PrimaryTheme.primaryRedError,
        '100%': PrimaryTheme.primaryGreen,
    };
    return (
        <div className='tracker-card'>
            <p className='tracker-card-title'>
                {props.title}
            </p>
            <Row className='tracker-card-progress-container'>
                <Progress
                    type="dashboard"
                    size={200}
                    gapDegree={180}
                    strokeWidth={18}
                    percent={props.percent}
                    strokeColor={conicColors}
                />
                <span className='tracker-card-remark'>
                    {props.percent > 85 ? 'Excellent' : props.percent > 40 ? 'Good' : 'Poor'}
                </span>
            </Row>
        </div>
    );
}
