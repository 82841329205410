import * as React from 'react';
import { BEButton } from '../../../../../../../Components/BEFormItems/BEButton';
import { Progress } from 'antd';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import { setCurrentSection } from '../../../../../../../Redux/SupplierReducer';
import { InviteCosupplier } from '../InviteCosupplier';
import ToolTip from '../../../../../../../Components/BEToolTip';
import InfoIcon from '../../../../../../../Components/BEIcons/InfoIcon';
import { PrimaryTheme } from '../../../../../../../Config/Theme/theames';

export interface ILeftSectionCardProps {
  section: any;
  isFillSection?: boolean;
}

export function LeftSectionCard(props: ILeftSectionCardProps) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const currentSection = useTypedSelector(state => state.supplier.CurrentSection);
  const supplierAssessmentQuestions = useTypedSelector(state => state.supplier.SupplierQuestions);
  const supplierAssessmentAnswers = useTypedSelector(state => state.supplier.SupplierAnswers);
  const coSuppliers = useTypedSelector(state => state.supplier.CoSuppliers)
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    if (supplierAssessmentAnswers?.status === 'success' && supplierAssessmentQuestions?.status === 'success') {
      let questionsOfSection = supplierAssessmentQuestions?.data.filter((item: any) => item.attribute === props.section);
      let answersOfSection = [];
      for (let i = 0; i < questionsOfSection?.length; i++) {
        if (supplierAssessmentAnswers?.data[questionsOfSection[i].id]) {
          answersOfSection.push(supplierAssessmentAnswers?.data[questionsOfSection[i].id]);
        }
      }
      setProgress(Math.round((answersOfSection?.length / questionsOfSection?.length) * 100));
    }
  }, [supplierAssessmentAnswers?.data, supplierAssessmentQuestions?.data, props.section]);


  return (
    <div className={`left-section-card ${props.section === currentSection ? 'active' : ''}`}
      onClick={() => dispatch(setCurrentSection(props.section))}
    >
      <div className='left-section-card-top'>
        <div>
          <p className='title'>{props.section}</p>
          <p className='discription'>{
            supplierAssessmentQuestions?.data.filter((item: any) => item.attribute === props.section)?.length
          } questions</p>
        </div>
      
      </div>
      {
        props.section === currentSection &&
        <div className='left-section-card-bottom'>
          <Progress showInfo={false} strokeColor={PrimaryTheme.primaryGreen} strokeWidth={8}
            percent={progress}
            style={{ width: '100%', margin: 0 }} />
          <p className='discription'>
            {
             progress
            }% Complete
          </p>
        </div>
      }
      <InviteCosupplier open={open} setOpen={setOpen} section={props.section}/>
    </div>
  );
}
