import * as React from 'react';
import BEBackButton from '../../../../../Components/BEBackButton';
import { ColumnsType } from 'antd/es/table';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import BEStatusTag from '../../../../../Components/BEStatusTag';
import { Scope2EmissionConfigurationType } from '../../../../../Redux/Types/emisionsTypes';
import { NewBETable } from '../../../../../Components/BETable/newBETable';
import EditIcon from '../../../../../Components/BEIcons/EditIcon';
import { EditConfigDrawer } from '../../../Components/EditScope2CofigDrawer';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { getScope2EmissionConfigurations, getScope2EmissionFactors } from '../../../Actions';

export interface IScope2ConfigurationSummeryProps {
}

export function Scope2ConfigurationSummery (props: IScope2ConfigurationSummeryProps) {
  const dispatch = useAppDispatch();
  const businessUnits = useTypedSelector(state => state.entity.BusinessUnits)
  const scope2EmissionFactors = useTypedSelector(state => state.emissions.emissionFactors.scope2EmissionFactors)
  const myMetrics = useTypedSelector((state) => state.onBoarding.metrics.myMetrics);
  const scope2Configurations = useTypedSelector(state => state.emissions.configurations.scope2EmissionConfigurations)
  const [currentConfig, setCurrentConfig] = React.useState<Scope2EmissionConfigurationType | null>(null)
  const [openEdit, setOpenEdit] = React.useState(false)
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const [configs, setConfigs] = React.useState<any>([])

  const columns:ColumnsType<any> = [
    {
      title: 'SR. NO.',
      render: (text, record, index) => index + 1,
      key: 'srNo',
    },
    {
      title: 'Business Unit',
      key: 'business_unit',
      render: (text, record) => {
        return businessUnits?.data?.find((item) => item.id === record.business_unit)?.name
      },
      filters: businessUnits?.data?.map((item) => ({text: item.name, value: item.id})),
      onFilter: (value, record) => record.business_unit === value,
    },
    {
      title: 'Metric',
      key: 'metric',  
      dataIndex: 'metric',
      filters: Array.from(new Set(configs.map((item: any) => item.metric))).map((item2:any) => ({text: item2, value: item2})),
      onFilter: (value, record) => record.metric === value,
    },
    {
      title: 'Location',
      key: 'location',
      render: (text, record) => {
        return businessUnits?.data?.find((item) => item.id === record.business_unit)?.address
      }
    },
    {
      title: 'Status',
      key: 'status',
      render: (text, record) => {
        return <BEStatusTag 
        status={record.default_emission_factor || record.emission_factor ? 'success' : 'error'}
        size='small'>
          {record.default_emission_factor || record.emission_factor ? 'Applied' : 'Pending'}
        </BEStatusTag>
      }
    },
    {
      title: 'Emission Factor',
      key: 'emission_factor',
      render: (text, record) => {
        return (record.default_emission_factor || record.emission_factor) ?
          scope2EmissionFactors?.data?.find(
            (item) => item.id === (record.default_emission_factor || record.emission_factor) &&
              (record.default_emission_factor ? item.type === 'Default' : item.type === 'Custom')
            )?.ef_name
        : '---'
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        return <div style={{cursor:'pointer'}}
          onMouseEnter={() => setCurrentConfig(record)}
          onClick={() => setOpenEdit(true)}
        ><EditIcon/></div>
      }
    }
  ]

  React.useEffect(() => {
    if (myMetrics.status !== 'success' || !currentYear || scope2Configurations.status !== 'success'){
      setConfigs([]) 
      return
      }
    let tempConfigs:any = []
    scope2Configurations.data.forEach((config) => {
      const metric = myMetrics.data.find((metric) => metric.id === config.my_metric)
      if (metric?.year === currentYear)
        tempConfigs.push({...config, metric: metric.title})
    })
    setConfigs(tempConfigs)
  }, [myMetrics, currentYear])

  React.useEffect(() => {
    if(scope2EmissionFactors.status === 'idle')
      dispatch(getScope2EmissionFactors())
    if(scope2Configurations.status === 'idle')
      dispatch(getScope2EmissionConfigurations())
  }, [])

  return (
    <div><br/>
      <BEBackButton title='Back'/><br/>
      <NewBETable
        columns={columns}
        loading={scope2Configurations.status === 'loading'}
        data={configs}
        pagination
      />
      <EditConfigDrawer open={openEdit} setOpen={setOpenEdit} currentConfig={currentConfig} />
    </div>
  );
}
