import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { Form } from 'antd';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import FileUpload from '../../../../../Components/BEFileUpload/FileUpload';
import SaveIcon from '../../../../../Components/BEIcons/SaveIcon';
import { patchVaultFolders, postVaultFolders } from '../../../Actions';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { BETextArea } from '../../../../../Components/BEFormItems/BETextArea';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';

export interface IEditFileProps {
    open: boolean;
    setOpen: Function;
    isEdit?: boolean;
    folderId?: number;
    setCurrentPage?: Function;
}

export function CreateFolder(props: IEditFileProps) {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = React.useState(false);
    const VaultFolders = useTypedSelector((state) => state.vault.Folders);
    const [uploadedBy, setUploadedBy] = React.useState(null);
    const [form] = Form.useForm();
    const onCancel = () => {
        props.setOpen(false)
    };

    React.useEffect(() => {
        form.resetFields(["foldername", "description"]);
        if(props.isEdit) {
            const folder = VaultFolders?.data?.find((item) => item.id === props.folderId);
            form.setFieldsValue({
                foldername: folder?.title,
                description: folder?.description
            })
        }

        const userInfo = localStorage.getItem("userInfo");
        const userInfoParsed = userInfo ? JSON.parse(userInfo) : null;
        setUploadedBy(userInfoParsed.id);
    }, [props.open]);

    const handleForm = async (values: any) => {
        setLoading(true);
        const data = {
            title: values.foldername,
            description: values.description,
            uploaded_by: uploadedBy
        }
        {
            props.isEdit ?
            await dispatch(patchVaultFolders(props.folderId, data))
            :
            await dispatch(postVaultFolders(data)).then(() => {
                props.setCurrentPage && props.setCurrentPage(Math.floor(VaultFolders?.data?.length / 10) + 1);
            })
        }
        setLoading(false);
        onCancel()
    };

    return (
        <BEDrawer
            open={props.open}
            setOpen={props.setOpen}
            heading={props.isEdit ? 'Edit Folder' : 'Create Folder'}
            width='fit-content'
            footer={
                <>
                    <BEButton
                        loading={loading}
                        icon={
                        !props.isEdit && <div style={{ width: "1rem" }}><SaveIcon inheritSize /></div>}
                        className='primary'
                        size='large'
                        onClick={() => { form.submit() }}
                    >{props.isEdit ? "Edit" : "Save"}
                    </BEButton>
                    <BEButton
                        size='large'
                        onClick={onCancel}
                    >Cancel</BEButton>
                </>
            }
        >
                <Form form={form} onFinish={handleForm}>
                    <LableRequired>Folder Name</LableRequired>
                    <Form.Item
                        name="foldername"
                        rules={[{ required: true, message: "Please enter folder name" }]}
                    >
                        <BEInput size='large' style={{ width: "313px"}} />
                    </Form.Item>
                    <LableRequired>Description</LableRequired>
                    <Form.Item
                        name="description"
                        rules={[{ required: true, message: "Please enter description" }]}
                    >
                        <BETextArea
                            form={form}
                            rows={4}
                            placeholder="Description of folder"
                        />
                    </Form.Item>
                </Form>

        </BEDrawer>
    );
}
