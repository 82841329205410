import React from 'react'
import BEBackButton from '../../../../Components/BEBackButton';
import SettingsBackButton from '../../Components/BackButton';
import ArrowDownIcon from '../../../../Components/BEIcons/ArrowDownIcon';
import MapDataIcon from '../../../../Components/BEIcons/MapDataIcon';
import { Card, Form } from 'antd';
import { IntegrationType } from '..';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { LableRequired } from '../../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { ColumnsType } from 'antd/es/table';
import { NewBETable } from '../../../../Components/BETable/newBETable';
import '../styles.scss'
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { patchIntegrationConfig, postIntegrationConfig } from '../../Actions';
import { MappingDrawer } from '../Drawers/Mapping';
import { BEMessage } from '../../../../Components/BEMessage';
import ConnectedIcon from '../../../../Components/BEIcons/ConnectedIcon';

interface IntegrationConfigProps{
	integration: IntegrationType;
	setSelectedIntegration: Function
}
const IntegrationConfig = (props: IntegrationConfigProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [openDataMappingDrawer, setOpenDataMappingDrawer] = React.useState(false);

  React.useEffect(() => {
	form.setFieldsValue({
		endpoint: props.integration.endpoint,
		api_key: props.integration.api_key
	})
  }, [props.integration])

  const column : ColumnsType<any> =[
	{
		title: 'Sr. No.',
		key: 'sno',
		render: (text, object, index) => index + 1
	  },
	  {
		title: 'Metric Name',
		dataIndex: 'title'
	  },
	  {
		title: 'Category',
		dataIndex: 'category'
	  },
	  {
		title: 'Unit',
		dataIndex: 'unit'
	  },
	  {
		title: 'Action',
		dataIndex: 'action',
	  }
  ]
	function onFinish(values: any): void {
		const data = {
			endpoint: values.endpoint,
			api_key: values.api_key,
			system: props.integration.system
		}
		if(props.integration.api_key && props.integration.endpoint){
			dispatch(patchIntegrationConfig(props.integration.id,data)).then(()=>{
				BEMessage.success('Integration Credentials Updated Successfully')
			}).catch(()=>{
				BEMessage.error('Error while updating Integration Credentials')
			})
		}else{
			dispatch(postIntegrationConfig(data)).then(()=>{
				props.setSelectedIntegration(null)
			})
		}
	}

  return (
	<>
		<div className='config-container'>
			<div className='back-arrow-container' onClick={()=> props.setSelectedIntegration(null)}>
					<div className='back-arrow' >
						<ArrowDownIcon inheritSize/>
					</div>
					<span>Back</span>
			</div>
			<div className='config-details'>
				<Card className='card' style={{padding:'1rem'}}>
					<img className='icon' src={props.integration.logo} alt="" />{}
					<h2>{props.integration.title}</h2>
					<p>{props.integration.description}</p>
					<div style={{display: 'flex', gap:'1rem',marginTop:'1rem'}}>
						<div className='status-box' style={!props.integration.integrate ? {fontSize:'0.8rem'}:{ backgroundColor: PrimaryTheme.primaryGreen ,color: 'white'}}>
							<div style={{height:'1rem', width:'1rem'}}>
								{props.integration.integrate && <ConnectedIcon inheritSize/> }
							</div>
							<span>{!props.integration.integrate ? "Not":" "} Connected</span>
						</div>
						{props.integration.integrate && <div>
							<BEButton size='large' className='secondary' style={{color: PrimaryTheme.primaryGreen, borderColor: PrimaryTheme.primaryGreen,display:'flex',gap:'0.3rem',alignItems:'center',fontSize:'0.8rem'}} onClick={()=> setOpenDataMappingDrawer(true)}>
								<div style={{height:'1rem', width:'1rem'}}>
								<MapDataIcon inheritSize /> 
								</div>
								Map data
							</BEButton>
						</div>}
					</div>
				</Card>
				<Card className='card' >
					<h2>Credentials</h2>
					<Form className='crededential-form' onFinish={onFinish} form={form} style={{display:'flex', flexDirection:'column'}}>
						<div style={{height:'5rem'}}>
							<LableRequired>End Point</LableRequired>
							<Form.Item style={{margin:0}} name={'endpoint'} rules={[{
								required: true,
								message: 'Please enter end point'
							}]}>
								<BEInput type='text' placeholder='Enter End Point'/>
							</Form.Item>
						</div>
						<div style={{height:'5rem'}}>
							<LableRequired>API Key</LableRequired>
							<Form.Item name={'api_key'} rules={[{
								required: true,
								message: 'Please enter API Key'
							}]}>
								<BEInput type='text' placeholder='Enter API KEY'/>
							</Form.Item>
						</div>
						<div>
							<BEButton size='large' onClick={()=> form.submit()} className='primary'>Submit</BEButton>
						</div>
					</Form>
				</Card>
				{/* <div className='audit'>
					<h2>Audit logs</h2>
					<NewBETable columns={column} data={[]}/>
				</div> */}
			</div>
		</div>
		<MappingDrawer 
			integrationId={props.integration.id}
			open={openDataMappingDrawer}
		 	setOpen={setOpenDataMappingDrawer}
		/>
	</>
  )
}

export default IntegrationConfig