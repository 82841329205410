import * as React from 'react';
import { useTypedSelector } from '../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../Config/Hooks/useAppDispatch';
import { Form, Button, Input, Row, Col } from 'antd';
import { useState } from 'react';
import { RightComponentLogin } from './RightComponentLogin\'';
import { LeftComponentLogin } from './LeftComponentLogin';
import './style.scss';

export interface ILoginProps { }

export function Login(props: ILoginProps) {
  const [otpValue, setOtpValue] = React.useState('');
  const [step, setStep] = React.useState(1);

  return (
    <div className='login'>
      {window.innerWidth > 768 ?
        <Row style={{ width: '100%' }} >
          <Col span={14} >
            <LeftComponentLogin step={step} />
          </Col>
          <Col span={10}>
            <RightComponentLogin step={step} setStep={setStep} />
          </Col>
        </Row>
        :
        <RightComponentLogin step={step} setStep={setStep} />
      }
    </div>
  );
}
