import React from 'react'
import IntegrationCard from '../Components/IntegrationCard'
import { Col, Row } from 'antd'
import { BEInput } from '../../../../Components/BEFormItems/BEInput'
import IntegrationConfig from '../IntegrationConfig'
import { IntegrationType } from '..'
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector'
interface AvailableIntegrationsProps{
	integrations: IntegrationType[];
	selectedIntegration: IntegrationType | null;
	setSelectedIntegration: Function;
}
const AvailableIntegrations = (props: AvailableIntegrationsProps) => {
  const allIntegrations = useTypedSelector((state) => state.settings.integrations);
  return (
	!props.selectedIntegration ?
		<div style={{ margin: "2rem" }}>
		<Row>
		<Col span={8}>
			<BEInput search placeholder="Search for integrations" size="large" />
		</Col>
		</Row>
		<div className='integration-containter'>
			{props.integrations.map((integration,index) => {
				const connectedIntegration = allIntegrations.data.find((item) => item.system === integration.id);
				return <IntegrationCard 
					key={index}
					onClick={()=> !integration?.comingSoon && !connectedIntegration && props.setSelectedIntegration(integration)}
					{...integration}
					available
				 />
			}
			)}
		</div>
		</div>
	: <IntegrationConfig setSelectedIntegration={props.setSelectedIntegration} integration={props.selectedIntegration}/>
  )
}

export default AvailableIntegrations