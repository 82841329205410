import * as React from 'react';
import { BETabs } from '../../../../../Components/BETabs'
import {DataStatusDetails} from './DataStatusDetails'
import '../styles.scss';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { getDashoardInsightsAlertMessages } from '../../../Actions';


interface IDataStatusPanelProps {
}

export function DataStatusPanel (props: IDataStatusPanelProps) {
	const dispatch = useAppDispatch();
	const [tabs, setTabs] = React.useState<{ label: any; key: any; data: any; }[]>([])
	
	const groupDataBySection = (data:any) => data.reduce((acc:any, item:any) => {
		if (!acc[item.section]) {
			acc[item.section] = [];
		}
		acc[item.section].push({
			message: item.message,
			status: item.status,
			resolve: item.user_to_remind ? true : false
		});
		return acc;
	}, {});

	const getDataStatusPanel =  async() =>{
		const dataResponse = await dispatch(getDashoardInsightsAlertMessages());
		if(dataResponse.data){
			const groupedata = groupDataBySection(dataResponse.data)
			console.log(groupedata)
			const tabs = Object.keys(groupedata)?.map((section:any) => ({
				label: section,
				key: section,
				data: groupedata[section]
			}))
			setTabs(tabs)
		}
	}

	React.useEffect(()=> {
		getDataStatusPanel()
	},[])
	// const tabs =[{
	// 	label:'Data Manager',
	// 	key:'data_manager',
	// 	data: [
	// 		{ message: 'Business unit 1 hasn’t filled data for 50% of its metrics.Business unit 1 hasn’t filled data for 50% of its metrics.Business unit 1 hasn’t filled data for 50% of its metrics.Business unit 1 hasn’t filled data for 50% of its metrics.Business unit 1 hasn’t filled data for 50% of its metrics.', status: 'error', resolve: true },
	// 		{ message: 'Data processing complete.', status:true, resolve: false },
	// 	],
	//   },{
	// 	label:'Reports',
	// 	key:'reports',
	// 	data: [
	// 		{ message: 'Business unit 1 hasn’t filled data for 50% of its metrics.', status: 'error', resolve: true },
	// 		{ message: 'Data processing complete.', status: false, resolve: false },
	// 	],
	//   }]
	  return (
		<div className='data-status-panel'>
			<BETabs
			items={tabs.map((tab:any) =>{
				return {
					label:tab.label,
					key:tab.key,
					children: <DataStatusDetails data={tab.data}/>
				}
			})}
			/>
		</div>
	  )
}
