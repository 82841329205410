import SEBI from "../assets/images/frameworks/sebi.png";
import BRSR from "../assets/images/frameworks/brsr.png";
import CDP from "../assets/images/frameworks/cdp.png";
import GRI from "../assets/images/frameworks/gri.png";
import SASB from "../assets/images/frameworks/sasb.png";
import TCFD from "../assets/images/frameworks/tcfd.png";
import WEF from "../assets/images/frameworks/wef.png";
import GRESB from "../assets/images/frameworks/gresb.png";
import ESRS from "../assets/images/frameworks/esrs.png";
import SGX from '../assets/images/frameworks/sgx.png';
import CDSB from '../assets/images/frameworks/cdsb.png';
import IFRS from '../assets/images/frameworks/ifrs.png';
import IFC from '../assets/images/frameworks/ifc.jpg';
import ISOIWA48 from '../assets/images/frameworks/iso.png';
import SFDR from '../assets/images/frameworks/sfdr.png';
import { title } from "process";

export interface ItemProps {
  code: string;
  title: string;
  fullName: string;
  img: string;
  description: string[];
  //   onClick?: MouseEventHandler<HTMLDivElement>;
  selected?: boolean;
  additionalInfo?: string;
  allSelected?: boolean;
  // industries: string[];
}
export const Frameworks: ItemProps[] = [
  
  {
    title: "SASB",
    code: "SASB",
    fullName: "Sustainability Accounting Standards Board",
    img: SASB,
    description: [
      "Industry-specific ESG reporting standards.",
      "Focuses on financial ESG factors.",
      "Helps investors compare ESG performance across companies in the same industry.",
    ],
    // industries: []
  },
  {
    code: "BRSR",
    title: "BRSR",
    fullName: "Business Responsibility and Sustainability Reporting",
    img: BRSR,
    description: [
      "It enforces a standardised, transparent reporting format for all listed companies in India.",
      "Places a strong emphasis on addressing climate change and fostering sustainable economic growth, aligning with national priorities.",
      "Enhances corporate accountability by requiring comprehensive reporting, facilitating better decision-making.",
    ],
    additionalInfo: "Please note, for this framework, all metrics are required to be reported on, by default.",
    allSelected: true,
    // industries: ["All"],
  },
  {
    title: "CDP",
    code: "CDPSME",
    fullName: "Carbon Disclosure Project",
    img: CDP,
    description: [
      "Global platform for environmental disclosure.",
      "Focuses on climate change, water security, and deforestation.",
      "Helps companies and cities measure, manage, and disclose environmental impacts.",
    ],
    // industries: ["All"],
  },
  {
    title: "GRI",
    code: "GRI",
    fullName: "The Global Reporting Initiative (GRI) Standards",
    img: GRI,
    description: [
      "Comprehensive ESG reporting framework.",
      "Covers economic, environmental, social, and governance aspects.",
      "Provides general guidelines adaptable to various industries.",
    ],
    additionalInfo: "Please note, it is ideal to complete a) Materiality Assessment to aid in metric selection for GRI b) Refer the data labels as well, for better understanding.",
    // industries: ['Agriculture Aquaculture and Fishing', 'All', 'Coal', 'Oil & Gas'],
  },
  {
    title: "TCFD",
    code: "TCFD",
    fullName: "Task Force on Climate-Related Financial Disclosures",
    img: TCFD,
    description: [
      "Focuses on climate-related risk and opportunity disclosures.",
      "Promotes transparency in assessing climate impacts on financial performance.",
      "Aims to standardize climate reporting for better investor decision-making.",
    ],
    additionalInfo: "Please note, for this framework, all metrics are required to be reported on, by default.",
    allSelected: true,
    // industries: ["All"],
  },
  {
    title: "WEF",
    code: "WEF",
    fullName: "World Economic Forum",
    img: WEF,
    description: [
      "Offers various sustainability and ESG initiatives.",
      "Promotes public-private cooperation for sustainable development.",
      "Includes efforts like ESG metrics and Sustainable Development Impact Summit.",
    ],
    // industries: ["All"],
  },
  {
    code: "GRESB",
    title: "GRESB",
    fullName: "Global Real Estate Sustainability Benchmark",
    img: GRESB,
    description: [
      "Specialized in assessing ESG performance in the real estate sector.",
      "Measures environmental, social, and governance aspects of assets.",
      "Used by real estate investors to benchmark sustainability efforts.",
    ],
  },
  {
    title: "ESRS",
    code: "ESRS",
    fullName: "European Sustainablity Reporting Standards",
    img: ESRS,
    description: [
      "The ESRS standards are reporting standards for sustainability within the EU:",
      "The ESRS standards are an integral part of the CSRD",
      "The Corporate Sustainability Reporting Directive of the European Parliament and the Council .This means that the ESRS reporting standards are mandatory and will be applicable for the first wave of comapnies from the reporting year of 2024.",
    ],
    // industries: ["General"],
  },
  {
    code: "SGX",
    title: "SGX",
    fullName: "Singapore Exchange",
    img: SGX,
    description: [
      'SGX, i.e Singapore Exchange, requires listed companies to publish sustainability reports on a "comply or explain" basis, covering environmental, social, and governance (ESG) factors annually',
      "The framework emphasizes material ESG issues, setting targets, performance tracking, and providing a board statement on the sustainability report.",
      "SGX encourages alignment with international reporting frameworks like GRI, SASB, and TCFD for comprehensive disclosure.",
    ],
  },
  {
    code: "CDSB",
    title: "CDSB",
    fullName: "Climate Disclosure Standards Board",
    img: CDSB,
    description: [
      "The CDSB framework integrates environmental and climate-related information into mainstream financial reports, focusing on the impact of climate change on a company's financial performance.",
      "CDSB has now been consolidated into the IFRS Foundation. The CDSB technical guidance will form part of the evidence base as the ISSB develops its IFRS Sustainability Disclosure Standards.",
      "CDSB’s Framework and technical guidance on Climate, Water and Biodiversity disclosures will remain useful for companies until such time as the ISSB issues its IFRS Sustainability Disclosure Standards on such topics.",
    ],
  },
  {
    code: "IFRS",
    title: "IFRS",
    fullName: "International Sustainability Standards Board",
    img: IFRS,
    description: [
      "The  International Sustainability Standards Board-ISSB’s standards,also known as the IFRS Sustainability Disclosure Standards (IFRS SDS), are the global baseline for sustainability-related disclosures. They are designed to be consistent, comparable, and decision-useful.",
      "The ISSB's goal is to provide investors with the information they need to make better economic and investment decisions.",
      "The International Sustainability Standards Board (ISSB) standards apply to any private or public company that chooses to apply them voluntarily, or if jurisdictional authorities require them to.",
      "Currently, it includes two global standards — General Requirements for Disclosure of Sustainability-related Financial Information (IFRS S1) and Climate-related Disclosures (IFRS S2) (“ISSB Standards”)."
    ],
  },
  {
    code: "IFC",
    title: "IFC",
    fullName: "International Finance Corporation",
    img: IFC,
    description: [
      "Part of IFC’s Sustainability Framework , the Performance Standards are directed towards clients, providing guidance on how to identify risks and impacts, and are designed to help avoid, mitigate, and manage risks and impacts as a way of doing business in a sustainable way.",
      "They include stakeholder engagement and disclosure obligations of the client in relation to project-level activities.",
    ],
  },
  {
    code: "ISO",
    title: "ISO IWA 48",
    fullName: "International Organisation for Standardisation",
    img: ISOIWA48,
    description: [
      "Provides a framework for integrating and embedding ESG factors into organizational decision-making and culture.",
      "Acts as a universal language for ESG practices aiding organizations and boosting adoption of sustainable business practices.",
      "Aims to standardize ESG practices across industries, ensuring consistency and comparability for better transparency and stakeholder confidence."
    ],
  },
  {
    code: "SFDR",
    title: "SFDR (PAIs)",
    fullName: "Sustainable Finance Disclosure Regulation",
    img: SFDR,
    description: [
      "SFDR is a European Union regulation designed to provide greater transparency on how financial market participants and financial advisers consider sustainability risks and impacts",
      "Under SFDR, financial institutions are required to disclose their PAI (Principle Adverse Impacts) through a statement detailing the potential negative sustainability effects of their investments",
    ],
  }
];

export const GRESBEntitySections = [
  {
    section: "Entity & Reporting Characteristics",
    description: "Information provided in the Entity and Reporting Characteristics aspect identifies the reporting entity's characteristics that remain constant across different reporting years.The section is mandatory. Therefore it is preselected for you.",
    alwaysChecked: true
  },
  {
    section: "Performance",
    description: "The Performance Component measures the entity's asset portfolio performance, comprising of information collected at the asset and at the portfolio level. It is suitable for any real estate company or fund with operational assets.",
    alwaysChecked: false
  },
  {
    section: "Management",
    description: "The Management Component measures the entity's strategy and leadership management, policies and processes, risk management and stakeholder engagement approaches, comprising information collected at the organizational level.",
    alwaysChecked: true
  },
  {
    section: "Development",
    description: "The Development Component assesses the entity's efforts to tackle ESG issues in building design, construction, and renovation. It applies to entities involved in new construction or major renovation projects, with ongoing or completed projects during the reporting period.",
    alwaysChecked: false
  }
];