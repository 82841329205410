import * as React from 'react';
import Scope1Table from '../Configurations/Scope1Table';

export interface IScope1ConfigurationsProps {
}

export function Scope1Configurations (props: IScope1ConfigurationsProps) {
  return (
    <div>
      <Scope1Table />
    </div>
  );
}
