import * as React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { AddCommaToNumber } from '../../../../Config/Functions/UsefullFunctions';
import { graphStrokeColorArray } from '../../../../Config/Data/dashboardData';

export interface IBEFilterableAreaChartProps {
    data: any[];
}

export function BEFilterableAreaChart(props: IBEFilterableAreaChartProps) {
    const [keys, setKeys] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (!props.data || props.data?.length === 0) {
            return;
        }
        const dataUnit = props.data[0];
        let keys = [];
        for (let key in dataUnit) {
            if (key !== 'name') {
                keys.push(key);
            }
        }
        setKeys(keys);
    }, [props.data]);

    return (
        <ResponsiveContainer width="100%" height={400}>
            <AreaChart
                width={500}
                height={400}
                data={props.data}
                margin={{
                    right: 10
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={(value) => {
                    return AddCommaToNumber(value, false, true, true, 0);
                }} />
                <Tooltip />
                <Legend />
                {
                    keys.map((key, index) => {
                        const color = graphStrokeColorArray[index % graphStrokeColorArray?.length];
                        return (
                            <Area
                                type="monotone"
                                dataKey={key}
                                stackId={index}
                                stroke={color}
                                fill={color}
                                key={index}
                            />
                        );
                    })
                }
            </AreaChart>
        </ResponsiveContainer>
    );
}
