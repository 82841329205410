import React, { useEffect } from "react";
import GeneralSettings from "./GeneralSettings";
import Users from "./Users";
import Notifications from "./Notifications";
import './styles.scss'
import { useTypedSelector } from "../../Config/Hooks/useTypedSelector";
import { Outlet } from "react-router-dom";
import { navigateTo } from "../../Navigation/NavigationManager";
import { Logs } from "./Logs";
import { Permissions } from "./Permissions";
import SettingsIcon from "../../Components/BEIcons/SettingsIcon";
import { PrimaryTheme } from "../../Config/Theme/theames";
import UsersIcon from "../../Components/BEIcons/UsersIcon";
import NotificationIcon from "../../Components/BEIcons/NotificationIcon";
import LabelIcon from "../../Components/BEIcons/labelcon";
import { setSettingsActiveTab } from "../../Redux/SettingsReducer";
import { useAppDispatch } from "../../Config/Hooks/useAppDispatch";
import IntegrationIcon from "../../Components/BEIcons/IntegrationIcon";
import Layers from "../../Components/BEIcons/Layers";
import { Labels } from "./Labels";
import { DataDownload } from "./DataDownload";
import DownloadIcon from "../../Components/BEIcons/DownloadIcon";
import { TopSection } from "./Components/TopSection";
import { Limits } from "./Limits";
import LimitsIcon from "../../Components/BEIcons/LimitsIcon";
import EntityManager from "./EntityManager";
import EntityManagerIcon from '../../Components/BEIcons/EntityManager'
import { BEInput } from "../../Components/BEFormItems/BEInput";
import { SetTableAndGraphDataDM } from "../DataManager/Components/SetTableAndGraphDataFunctionDM";
import Integrations from "./Integrations";

const Settings = () => {
  const activeState = useTypedSelector((state) => state.settings.activeTab);
  const dispatch = useAppDispatch();
  const role = useTypedSelector((state) => state.user.userRole);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [restrictedTabs, setRestrictedTabs] = React.useState<string[]>(['Permissions', 'Data Download','Entity Manager','Integration']);
  const permissions = useTypedSelector((state) => state.user.permissions.data);

  useEffect(() => {
    permissions.forEach((permission) => {
      if(permission.section === 3 && permission.role === role && permission.permission)
        // remove entity manager from restricted tabs
        setRestrictedTabs(restrictedTabs.filter((tab) => tab !== 'Entity Manager'));
    });
  }, [permissions]);

  useEffect(() => {
    if (window.location.pathname.includes('/home/settings/vault')) {
      dispatch(setSettingsActiveTab(3));
    } else if (window.location.pathname === '/home/settings') {
      dispatch(setSettingsActiveTab(0));
    }
  }, [window.location.pathname])

  const settingsTabs = [
    {
      key: 1,
      name: "General Settings",
      icon: (
        <SettingsIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/general-settings',
      description:"Manage your company's basic details and Customize ESG metric selections for tailored reporting and analysis"
    },
    {
      key: 2,
      name: "Users",
      icon: (
        <UsersIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/users',
      description:'Manage platform users with ease. Add new users individually or through bulk upload for efficient onboarding.'
    },
    {
      key: 3,
      name: "Vault",
      icon: (
        <Layers
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/vault',
      description:"Access and manage your files securely in the system and user folders, all stored in one place."
    },
    {
      key: 4,
      name: "Logs",
      icon: (
        <IntegrationIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/logs',
      description:"Track and review system activities and user actions for complete transparency and auditing."
    },
    {
      key: 5,
      name: "Notifications",
      icon: (
        <NotificationIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/notifications',
      description: "Set your notification preferences by adjusting frequency, and toggle notifications on or off as needed."
    },
    {
      key: 6,
      name: "Permissions",
      icon: (
        <IntegrationIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/permissions',
      description:'Control access by allowing or restricting user permissions to various sections of the platform, ensuring security & functionality.'
    },
    {
      key: 7,
      name: "Labels",
      icon: (
        <LabelIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/labels',
      description: "Manage all labels in use, view their usage frequency, and delete labels as needed for better organization."
    },
    {
      key: 8,
      name: "Data Download",
      icon: (
        <DownloadIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/data-download',
      description:'Easily export your data in XLS format for offline analysis and reporting.'
    },
    {
      key: 9,
      name: "Limits",
      icon: (
        <LimitsIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/limits',
      description:"Manage and configure limits for metrics. Add new limits to ensure accurate tracking and control."
    },
    {
      key: 10,
      name: "Entity Manager",
      icon: (
        <EntityManagerIcon
          stroke={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/entity-manager',
      description: "Add and organize business groups and units seamlessly in one place for efficient management and structure."
    },
    {
      key: 11,
      name: "Integration",
      icon: (
        <EntityManagerIcon
          stroke={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/integrations',
      description: "Easily export your data in XLS format for offline analysis and reporting."
    }
  ];

  return (
    <div className="settings">
      {/*<TopSection />*/}
      {activeState === 1 && <GeneralSettings />}
      {/* {activeState === 1 && <Integrations />} */}
      {activeState === 2 && <Users />}
      {/* {activeState === 4 && <BreatheSuite />} */}
      {activeState === 3 && <Outlet />}
      {activeState === 4 && <Logs />}
      {activeState === 5 && <Notifications />}
      {activeState === 6 && <Permissions />}
      {activeState === 7 && <Labels />}
      {activeState === 8 && <DataDownload />}
      {activeState === 9 && <Limits />}
      {activeState === 10 && <EntityManager />}
      {activeState === 11 && <Integrations />}
      {activeState === 0 &&
        <div>
          <TopSection/> <br/>
          <div style={{ display: 'flex' }}>
              <BEInput
                style={{ width: '313px', margin: '0 0 1rem 1.5rem' }}
                search size='large' placeholder='Search here' value={searchValue} onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          <div className="settings-container">
            {
              (role === 'SUPER_ADMIN' ? settingsTabs: settingsTabs.filter(tab => !restrictedTabs.includes(tab.name) || (role ==='ADMIN' && tab.name ==='Integration'))).filter((tab)=>tab.name.toLowerCase().includes(searchValue.toLowerCase())).
              map((tab, index) => (
                <div
                  key={index}
                  className='new-folder'
                  onClick={() => {
                    dispatch(setSettingsActiveTab(tab.key));
                    navigateTo(tab.path);
                  }}
                >
                  <div className='circle'>
                    <div className='settings-icon'>
                    {tab.icon}
                    </div>
                  </div>
                  <span style={{fontSize:'14px',lineHeight: "21px", fontWeight: "600" }}>{tab.name}</span>
                  <span style={{fontSize:'13px', color:PrimaryTheme.primaryGray}}>{tab.description}</span>
                </div>
              ))
            }
            
          </div>
        </div>
        }
        <SetTableAndGraphDataDM />
    </div>
  );
};

export default Settings;
