import * as React from 'react';
import { DataStatusMessage } from '../../../../Components/DataStatusMessageCard'


interface IDataStatusDetailsProps {
	data:any[]
}
export function DataStatusDetails (props: IDataStatusDetailsProps) {
	return <div>
		{props.data.map((message)=> <DataStatusMessage {...message}/>)}
	</div>
}
