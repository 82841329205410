import * as React from 'react';
import StackedColumnCharts from '../../../../../../Components/BECharts/StackedColumnCharts';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { AddCommaToNumber, changeSnakeCaseToSentence, rotateArray } from '../../../../../../Config/Functions/UsefullFunctions';
import { shortMonths } from '../../../../../../Config/Data/genralData';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { ColumnsType } from 'antd/es/table';
import { BEWrapper } from '../../../../../../Components/BEWrapper';
import DonutChart from '../../../../../../Components/BECharts/DonutCharts';
import { BETable } from '../../../../../../Components/BETable';
import { NewBETable } from '../../../../../../Components/BETable/newBETable';

export interface ITotalEmissionsByScopeProps {
}

export function TotalEmissionsByTypeGraph(props: ITotalEmissionsByScopeProps) {
  const dispatch =useAppDispatch();
  const yearEnd = useTypedSelector(state => state.companies.yearEnd);
  const filteredScope1Calculations = useTypedSelector(state => state.emissions.filteredEmissionCalculations.scope1Calculations);
  const filteredScope2Calculations = useTypedSelector(state => state.emissions.filteredEmissionCalculations.scope2Calculations);
  const filteredScope3Calculations = useTypedSelector(state => state.emissions.filteredEmissionCalculations.scope3Calculations);
  const [chartData, setChartData] = React.useState<any>([]);
 
  const columns:ColumnsType<any> = [
    {
      title: 'SOURCE',
      render: (record: any) => changeSnakeCaseToSentence(record.name,false,true),
      key: 'name',
    },
    {
      title: '% OF EMISSIONS',
      render: (record: any) => record.percentage + '%',
      key: 'percentage',
    },
    {
      title: 'TOTAL EMISSIONS (tCO2e)',
      render: (record: any) => AddCommaToNumber(record.value, false, true, true),
      key: 'value',
      sorter: (a: any, b: any) => (a.value - b.value)>0?1:-1,
    },
  ];

 const getDataByType = () => {
  let total = [...filteredScope1Calculations.data,...filteredScope2Calculations.data, ...filteredScope3Calculations.data]
              .reduce((acc: any, item: any) => item.tco2e?acc + item.tco2e:acc, 0)
  let data = chartData.map((item:any) => {
    return {
      name: item.name,
      value: item.data.reduce((acc: any, item: any) => acc + item, 0),
      percentage: AddCommaToNumber((item.data.reduce((acc: any, item: any) => acc + item, 0)/total)*100, false, true, true)
    }
  })
  return data;
 }

  React.useEffect(() => {
      const allData = [...filteredScope1Calculations.data,...filteredScope2Calculations.data, ...filteredScope3Calculations.data];

      let allEmissionCategoryData:any = {} // {emission_category: []*12}
      allData.forEach((item:any) => {
        try{
          allEmissionCategoryData[item.emission_category][item.month-1] += item.tco2e
        }
        catch{
          if(!item.emission_category) return
          allEmissionCategoryData[item.emission_category] = new Array(12).fill(0)
          allEmissionCategoryData[item.emission_category][item.month-1] = item.tco2e
        }
      })
      let allEmissionCategoryArray:any = []
      Object.keys(allEmissionCategoryData).forEach((key:any) => {
        allEmissionCategoryArray.push({
          name:key,
          data:allEmissionCategoryData[key]
        })
      })
      allEmissionCategoryArray.forEach((item:any) => {
        item.data = rotateArray(item.data, yearEnd, true)
      })

      setChartData(allEmissionCategoryArray);
  }, [filteredScope1Calculations,filteredScope2Calculations,filteredScope3Calculations]);

  return (
    <div style={{ margin: "3rem 0" }} >
    <p className='chart-title'>Total emissions by Source (tCO2e)</p>
     <BEWrapper>
     <div className='table-container'>
          <BEWrapper>
            <DonutChart
              data={getDataByType()}
            />
          </BEWrapper>
          <BEWrapper>
            <NewBETable
              pagination
              columns={columns}
              data={getDataByType().sort((a:any,b:any)=>b.value-a.value)}
            />
          </BEWrapper>
        </div>
        <div style={{ padding: "1rem" }} className='bordered-container'>
          <StackedColumnCharts
            colorTheme={3}
            data={chartData}
            xAxisLabels={rotateArray(shortMonths, yearEnd, true)}
            // chartTitle="Total Emissions by Source (tCO2e)"
            yAxisTitle="tCO2e"
            yAxisLabel="Tonnes of Co2 equivalent"
          />
        </div>
     </BEWrapper>
    </div>
  );
}
