import * as React from 'react';
import '../styles.scss';
import { BESemiProgressCard } from '../../../../../Components/BESemiProgressCard';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';

export interface IESGProgressProps {
	data: any;
}

export function ESGProgress (props: IESGProgressProps) {
  return (
	<div className='esg-progress'>
		{
			props?.data ?
		
		props.data?.map((item: any)=> <BESemiProgressCard {...item}/>)

		:
		<GlobalLoader height='10vh'/>
	
	}
	</div>
  );
}
