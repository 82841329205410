import * as React from 'react';
import { BETable } from '../../../../../Components/BETable';
import { BusinessGroupcard } from '../../../../../Components/Cards/BusinessGroupCard';
import { getBusinessGroups } from '../../../../Actions/BusinessGroupActions';
import { getBusinessUnits } from '../../../../Actions/BusinessUnitActions';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { ColumnType } from 'antd/es/table';

export interface IReviewStep2Props {
}

export default function ReviewStep2 (props: IReviewStep2Props) {
  const dispatch = useAppDispatch();
  const businessGroups = useTypedSelector(state => state.entity.BusinessGroups);
  const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);

  React.useEffect(() => {
    dispatch(getBusinessGroups());
    dispatch(getBusinessUnits());
  }, []);

  if(businessGroups?.status === 'loading' || businessUnits?.status === 'loading') {
    return <GlobalLoader />
  }

  const columns: ColumnType<any>[] = [
    {
        title:'Name',
        dataIndex:'name',
        sorter: (a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase())
    },{
        title:'Description',
        dataIndex:'description',
    },{
        title:'Business Group',
        dataIndex:'business_group',
        render:(text,record)=>businessGroups?.data?.find((item:any)=>item.id===record.business_group)?.name
    },
    // ,{
    //     title:'Industry',
    //     dataIndex:'industry',
    //     filters: Array.from(new Set(
    //       businessUnits?.data?.map((item:any)=>item.industry)
    //     )).map((item,index)=>({text:item,value:item})),
    //     onFilter: (value, record) => record.industry.indexOf(value) === 0,
    //     filterSearch: true,
    // },
    {
      title:'Location',
      dataIndex:'address',
    }
  ]

  return (
    <div className='step-2'>
      <div className='outer-box'>
        <p className='heading'>Business Groups ({
            businessGroups?.data?.length
          })</p>
        <div className='business-groups'>  
          {
            businessGroups?.data.map((businessGroup) => {
              return (
                <BusinessGroupcard
                  key={businessGroup.id}
                  businessGroup={businessGroup}
                  noEdit
                />
              )
            })
          }
        </div>
      </div>
      <div className='outer-box'>
        <p className='heading'>Business Units ({
            businessUnits?.data?.length
          })</p>
        <div className='business-units'>
          <BETable
            columns={columns}
            data={businessUnits?.data}
            pagination={true}
          />
        </div>
      </div>

    </div>
  );
}
