import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BEInput } from "../../../../Components/BEFormItems/BEInput";
import { BEButton } from "../../../../Components/BEFormItems/BEButton";
import BEBackButton from "../../../../Components/BEBackButton";
import { Dropdown, MenuProps, Row, Spin, message } from "antd";
import TrackerCard from "../../../../Components/Cards/TrackerCard";
import EditIcon from "../../../../Components/BEIcons/EditIcon";
import { PrimaryTheme } from "../../../../Config/Theme/theames";
import { useAppDispatch } from "../../../../Config/Hooks/useAppDispatch";
import { deleteStakeholderById, getAllRespondantsForAssessment, getAllStakeholdersForAssessment, getMaterialitySurveyAnswers, getMaterialitySurveyTopics, getMaterialitySurveys, sendInvitations, sendMatAssReminder } from "../../Actions/matirialityActions";
import { useTypedSelector } from "../../../../Config/Hooks/useTypedSelector";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { BETable } from "../../../../Components/BETable";
import BEStatusTag from "../../../../Components/BEStatusTag";
import DeleteIcon from "../../../../Components/BEIcons/DeleteIcon";
import ShareIcon from "../../../../Components/BEIcons/ShareIcon";
import AssignmentIcon from "../../../../Components/BEIcons/AssignmentIcon";
import RespondantsIcon from "../../../../Components/BEIcons/RespondantsIcon";
import PendingResponseIcon from "../../../../Components/BEIcons/PendingResponseIcon";
import { setSelectedRow } from "../../../../Redux/CommonReducer";
import { navigateTo } from "../../../../Navigation/NavigationManager";
import { AddStakeHolder } from "../Drawers/AddStakeholder";
import BEConfirmModal from "../../../../Components/BEConfirmModal";
import EditAssessment from "../Drawers/EditAssessment";
import { BEMessage } from "../../../../Components/BEMessage";
import { setCurrentMaterialitySurvey, setRespondantsToAssessment, setStakeholdersToAssessment } from "../../../../Redux/MatirialityReducer";
import AddStakeHolderToAssessment from "../Drawers/AddStakeholderToAssessment";
import { SelectDeadlineModal } from "./SelectDeadlineModal";
import DownloadMatirialityResponse from "./DownloadPDF";

const AssessmentDetails = () => {
  const dispatch = useAppDispatch();
  const { assessment_id } = useParams();
  const stakeholdersToAssessment = useTypedSelector((state) => state.matiriality.stakeholdersForAssessment)
  const respondantsToAssessment = useTypedSelector((state) => state.matiriality.respondentsForAssessment)
  const surveyAnswers = useTypedSelector(state => state.matiriality.surveyAnswers);
  const surveyTopics = useTypedSelector(state => state.matiriality.surveyTopics);
  const allSurveys = useTypedSelector((state) => state.matiriality.AllSurveys)
  const [pendingResponses, setPendingResponses] = useState<number>(0);
  const [currentRespondant, setCurrentRespondant] = useState<any>(null)
  const [openAddStakeholder, setOpenStakeholder] = useState<boolean>(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<boolean>(false);
  const [openEditAssessment, setOpenEditAssessment] = useState<boolean>(false);
  const selectedRow = useTypedSelector((state) => state.common.selectedRow)
  const [loadingSendReminder, setLoadingSendReminder] = useState<boolean>(false);
  const [openSelectDeadline, setOpenSelectDeadline] = useState<boolean>(false);
  const [invitationData, setInvitationData] = useState<any>(null)

  // check if the stakeholder has already responded
  const checkIfStakeholderHasResponded = (email: string) => {
    let responded = true;
    respondantsToAssessment?.data.forEach((res) => {
      if (res.email === email && !res?.status) {
        responded = false;
      }
    })
    return responded
  }

  const handleSendRemainder = () => {
    dispatch(
      sendMatAssReminder({
        survey_id: selectedRow?.survey,
        email: selectedRow.email,
      })
    );

  }

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <div onClick={handleSendRemainder}>Send reminder</div>,
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            let link = "";
            respondantsToAssessment?.data.forEach((res) => {
              if (res.email === selectedRow.email) link = res.link;
            });
            navigator.clipboard.writeText(link);
            BEMessage.success("Link Copied");
          }}
        >
          Copy Link
        </div>
      ),
    }
  ];

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Job Role",
      dataIndex: "role",
    },
    {
      title: "Type",
      dataIndex: "internalExternal",
      render: (value, record) => {
        return value === true ? "External" : "Internal";
      },
    },
    {
      title: "Deadline",
      dataIndex: "expires_at",
      render: (value, record) => {
        const res = respondantsToAssessment?.data.filter((res) => res.email === record.email)
        console.log(res)
        return <div>{res?.length ? dayjs(res[0].expires_at).format("DD MMM, YYYY") : '-'}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "email",
      render: (value, record) => {
        const responded = respondantsToAssessment?.data.filter((res) => {
          return res.email === value;
        });
        if (!responded || responded?.length === 0) {
          return <BEStatusTag status="warning">NOT INVITED</BEStatusTag>;
        } else if (responded[0]?.status) {
          return <BEStatusTag status="success">COMPLETE</BEStatusTag>;
        } else return <BEStatusTag status="error">PENDING</BEStatusTag>;
      },
    },
    {
      title: "Actions",
      dataIndex: "email",
      width: "40px",
      render: (value, record) => {
        const responded = respondantsToAssessment?.data.filter((res) => {
          return res.email === value;
        });
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
            onMouseEnter={() => {
              dispatch(setSelectedRow(record));
            }}
          >
            {/* {
              responded && responded?.length > 0 && responded[0]?.status &&
              <div>
                {
                  surveyAnswers?.status === 'success' && surveyTopics?.status === 'success' ?
                  <DownloadMatirialityResponse data={
                    surveyTopics?.data.map((topic: any) => {
                      const ans = surveyAnswers?.data.filter((ans: any) => {
                        return ans.topic === topic.id && ans.email === responded[0].email
                      })
                      return {
                        topic_name: topic.topic,
                        business_priority: ans[0]?.priority,
                      }
                    })
                  } /> 
                  :<Spin size="small"/>
                }
              </div>
            } */}
            {!checkIfStakeholderHasResponded(value) && (
              <Dropdown menu={{ items }} trigger={["click"]}>
                <div style={{ height: "1rem", cursor: "pointer" }}>
                  <ShareIcon inheritSize stroke={PrimaryTheme.primaryGray} />
                </div>
              </Dropdown>
            )}
            <div
              style={{ height: "1rem", cursor: "pointer" }}
              onClick={() => {
                setOpenDeleteConfirm(true);
              }}
            >
              <DeleteIcon inheritSize stroke={PrimaryTheme.primaryGray} />
            </div>
          </div>
        );
      },
    },
  ];

  // invite stakeholders who are not yet invited
  const handleInviteStakeholders = async () => {
    const filteredStakeholders = stakeholdersToAssessment?.data.filter((res) => {
      return currentRespondant.some((id: number) => {
        return res.id === id;
      });
    });

    const respondants = new Set<any>()
    const NotRespondants = new Set<any>();

    filteredStakeholders.forEach((stk) => {
      let found = false;
      respondantsToAssessment?.data.forEach((res) => {
        if (stk.email === res.email) {
          respondants.add(stk);
          found = true;
        }
      })
      if (!found) {
        NotRespondants.add(stk)
      }
    })
    const resArray = Array.from(respondants)
    const notResArray = Array.from(NotRespondants)
    if (resArray?.length > 0) {
      BEMessage.warning(
        "Invitation can be sent only to stakeholders who have not yet been invited"
      );
      return;
    }
    let emails: any = [];
    notResArray.forEach((item) => emails.push(item.email))
    setInvitationData({
      emails,
      survey_id: stakeholdersToAssessment?.data[0]?.survey,
    })
    setOpenSelectDeadline(true)
  }

  useEffect(() => {
    if (!openSelectDeadline) {
      dispatch(setStakeholdersToAssessment({ status: "idle", data: [] }));
      dispatch(setRespondantsToAssessment({ status: "idle", data: [] }))
    }
  }, [openSelectDeadline])

  // delete stakeholder
  const handleDelete = async () => {
    let responded = false;
    respondantsToAssessment?.data.forEach((res) => {
      if (res.email === selectedRow.email) {
        BEMessage.warning(
          "Cannot delete stakeholder who has already been invited to the survey!"
        );
        responded = true;
      }
    });
    if (responded) return;
    else {
      await dispatch(deleteStakeholderById(selectedRow.id));
      dispatch(
        setStakeholdersToAssessment({
          status: "idle",
          data: [],
        })
      );
    }
  };

  useEffect(() => {
    if (stakeholdersToAssessment?.status === "idle")
      dispatch(getAllStakeholdersForAssessment(Number(assessment_id)));
    if (respondantsToAssessment?.status === "idle")
      dispatch(getAllRespondantsForAssessment(Number(assessment_id)));
    if (surveyAnswers?.status === "idle")
      dispatch(getMaterialitySurveyAnswers(Number(assessment_id)));
    if (surveyTopics?.status === "idle")
      dispatch(getMaterialitySurveyTopics(Number(assessment_id)));
  }, [stakeholdersToAssessment, respondantsToAssessment]);

  useEffect(() => {
    if (respondantsToAssessment?.status === 'success') {
      const arr = respondantsToAssessment?.data.filter((ele) => {
        return !ele?.status
      })
      setPendingResponses(arr?.length)
    }
  }, [respondantsToAssessment]);

  useEffect(() => {
    if (allSurveys?.status === 'idle')
      dispatch(getMaterialitySurveys());
  }, [])

  return (
    <div style={{ padding: "1rem 0" }}>
      <BEBackButton title={`Viewing the assessment for ${
        allSurveys?.data.filter((survey: any) => survey.id === Number(assessment_id))[0]?.title
      }`} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1rem",
          justifyContent: "space-between",
        }}
      >
        <SelectDeadlineModal open={openSelectDeadline} setOpen={setOpenSelectDeadline}
          data={invitationData}
        /><br/>
        <BEButton
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            padding: "1.2rem 1rem",
          }}
          size="large"
          onClick={() => setOpenEditAssessment(true)}
        >
          <span style={{ height: "1rem" }}>
            <EditIcon inheritSize stroke={PrimaryTheme.primaryGray} />
          </span>
          Edit Assessment
        </BEButton>
      </div>

      <AddStakeHolderToAssessment open={openAddStakeholder} setOpen={setOpenStakeholder} />

      <BEConfirmModal
        title="Confirm Delete"
        message="Are you sure you want to delete the stakeholder?"
        visible={openDeleteConfirm}
        setVisible={setOpenDeleteConfirm}
        onConfirm={handleDelete}
      />

      <EditAssessment
        open={openEditAssessment}
        setOpen={setOpenEditAssessment}
        onCancle={() => setOpenEditAssessment(false)}
        currentStakeholder={assessment_id}
      />

      <Row style={{ margin: "1rem auto" }}>
        <TrackerCard
          title="TOTAL SURVEYED"
          body={`${respondantsToAssessment?.data?.length
            ? respondantsToAssessment?.data?.length
            : 0
            }`}
          loading={respondantsToAssessment?.status === "loading"}
          icon={
            <AssignmentIcon inheritSize stroke={PrimaryTheme.secondaryGray} />
          }
        />
        <TrackerCard
          title="RESPONSES"
          body={`${respondantsToAssessment?.data?.length
            ? respondantsToAssessment?.data?.length - pendingResponses
            : 0
            }`}
          loading={respondantsToAssessment?.status === "loading"}
          icon={
            <RespondantsIcon inheritSize stroke={PrimaryTheme.secondaryGray} />
          }
        />
        <TrackerCard
          title="PENDING"
          body={pendingResponses}
          loading={respondantsToAssessment?.status === "loading"}
          icon={
            <PendingResponseIcon
              inheritSize
              stroke={PrimaryTheme.secondaryGray}
            />
          }
        />
      </Row>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1rem",
          justifyContent: "space-between",
        }}
      >
        <BEInput
          style={{ width: "313px" }}
          search
          placeholder="Search for an assessment"
          size="large"
        />
        <div
          style={{
            width: "45%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <BEButton
            style={{ marginLeft: "auto" }}
            size="large"
            disabled={
              currentRespondant === null ||
              currentRespondant === undefined ||
              currentRespondant?.length === 0
            }
            onClick={handleInviteStakeholders}
            loading={loadingSendReminder}
          >
            Invite Stakeholder
          </BEButton>
          <BEButton
            style={{ marginLeft: "1rem" }}
            size="large"
            onClick={() => {
              setOpenStakeholder(true);
              console.log(allSurveys?.data);
              const currAssessment = allSurveys?.data.filter((survey) => {
                return survey.id === Number(assessment_id);
              });
              // console.log(currAssessment[0]);
              dispatch(setCurrentMaterialitySurvey(currAssessment[0]));
            }}
          >
            + Add stakeholder
          </BEButton>
          {respondantsToAssessment?.data.filter((res) => res?.status)?.length > 0 && (
            <BEButton
              allowAuditorClick
              className="primary"
              style={{ marginLeft: "1rem" }}
              size="large"
              onClick={() => {
                navigateTo(
                  `/home/materiality/assessments/${assessment_id}/result/`
                );
              }}
            >
              View Result
            </BEButton>
          )}
        </div>
      </div>

      <div style={{ margin: "1rem auto" }}>
        <BETable
          columns={columns}
          data={stakeholdersToAssessment?.data}
          loading={respondantsToAssessment?.status === "loading"}
          handleRowSelection={(key: any) => {
            setCurrentRespondant(key);
          }}
          rowKey="id"
          pagination
        />
      </div>
    </div>
  );
};

export default AssessmentDetails;
