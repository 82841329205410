import { AppDispatch } from "../../Redux/store";
import { setIsLoggedIn } from "../../Redux/UserReducer";

export const AutoLogin = (navigate:Function,location:any) => async (dispatch:AppDispatch) => {
  const access = localStorage.getItem("access");
  if(access) dispatch(setIsLoggedIn(true))
  if(access){
        if(location.pathname?.includes('/login') || location.pathname === '/') {
          if (window.innerWidth > 768) navigate('/home/dashboard');
          else navigate('/home/data-manager');
        }
        return true;
  }
  else{
    console.log('no access token found');
    if(location.pathname.includes('/home') || location.pathname === '/' )  navigate('/login');
  }
}