import RootRoutes from "./Navigation/RootRoutes";
import './Config/GlobalCss/index.scss'
import './App.css'
import { useEffect, useState } from "react";
import { MobileComponentRoutes } from "./mobile-comp";

function App() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return width > 768 ? <RootRoutes /> : <MobileComponentRoutes />;
}

export default App;
 