import { Card, Col, Dropdown, MenuProps, Row } from 'antd'
import React from 'react'
import ArrowDownIcon from '../../BEIcons/ArrowDownIcon';
import EditIcon from '../../BEIcons/EditIcon';
import { PrimaryTheme } from '../../../Config/Theme/theames';

interface EmissionCardProps {
    value: React.ReactNode | string | number;
    unit: string;
  description: React.ReactNode | string;
  loading?: boolean;
  disabled?:boolean
}

const EmissionCard = (props: EmissionCardProps) => {
    const items: MenuProps["items"] = [
      {
        key: "1",
        label: <p>Edit</p>,
        icon: (
          <div style={{height:'1rem'}}>
            <EditIcon inheritSize stroke={PrimaryTheme.primaryGray} />
          </div>
        ),
      },
    ];
  return (
    <Card
      style={{
        cursor: props.disabled ? "not-allowed" : "auto",
        width: "24%",
        opacity: props.disabled ? 0.45 : 1,
        height: "8rem",
        padding: "1.5rem",
      }}
      loading={props.loading}
    >
      {props.disabled && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
          }}
        ></div>
      )}
      <Row>
        <Col span={20}>
          <span
            style={{
              fontSize: "1.8rem",
              fontWeight: 600,
              marginRight: "0.5rem",
              color: PrimaryTheme.primaryGreen,
            }}
          >
            {props.value}
          </span>
          <span style={{ color: PrimaryTheme.primaryGreenDark }}>
            {props.unit}
          </span>
          <div
            style={{
              marginTop: "0.5rem",
              fontWeight: 500,
              color: PrimaryTheme.primaryGray,
            }}
          >
            {props.description}
          </div>
        </Col>
        <Col span={4}>
          <div style={{ height: "1rem" }}>
            {/* <ArrowDownIcon inheritSize /> */}
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default EmissionCard