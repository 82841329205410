import React from 'react'
import IconProps from './Interface/IconProps';

const MobileUserIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          d="M17.0407 15.5627C15.8508 13.5056 14.0172 12.0306 11.8774 11.3314C12.9358 10.7013 13.7582 9.74117 14.2182 8.59845C14.6781 7.45573 14.7503 6.19361 14.4235 5.00592C14.0968 3.81823 13.3892 2.77064 12.4094 2.02402C11.4296 1.2774 10.2318 0.873047 9.00003 0.873047C7.76821 0.873047 6.57044 1.2774 5.59067 2.02402C4.6109 2.77064 3.90331 3.81823 3.57654 5.00592C3.24978 6.19361 3.32193 7.45573 3.78189 8.59845C4.24186 9.74117 5.06422 10.7013 6.12268 11.3314C3.98284 12.0299 2.14925 13.5049 0.959403 15.5627C0.91577 15.6338 0.886828 15.713 0.874285 15.7955C0.861743 15.878 0.865854 15.9622 0.886376 16.0431C0.906897 16.124 0.943414 16.2 0.993772 16.2665C1.04413 16.3331 1.10731 16.3889 1.17958 16.4306C1.25185 16.4724 1.33175 16.4992 1.41457 16.5096C1.49738 16.5199 1.58143 16.5136 1.66176 16.491C1.74209 16.4683 1.81708 16.4298 1.88228 16.3777C1.94749 16.3256 2.00161 16.261 2.04143 16.1877C3.51331 13.6439 6.11487 12.1252 9.00003 12.1252C11.8852 12.1252 14.4867 13.6439 15.9586 16.1877C15.9985 16.261 16.0526 16.3256 16.1178 16.3777C16.183 16.4298 16.258 16.4683 16.3383 16.491C16.4186 16.5136 16.5027 16.5199 16.5855 16.5096C16.6683 16.4992 16.7482 16.4724 16.8205 16.4306C16.8927 16.3889 16.9559 16.3331 17.0063 16.2665C17.0566 16.2 17.0932 16.124 17.1137 16.0431C17.1342 15.9622 17.1383 15.878 17.1258 15.7955C17.1132 15.713 17.0843 15.6338 17.0407 15.5627ZM4.62503 6.50017C4.62503 5.63488 4.88162 4.78902 5.36235 4.06955C5.84308 3.35009 6.52636 2.78933 7.32579 2.4582C8.12522 2.12707 9.00488 2.04043 9.85355 2.20924C10.7022 2.37805 11.4818 2.79473 12.0936 3.40658C12.7055 4.01843 13.1222 4.79799 13.291 5.64665C13.4598 6.49532 13.3731 7.37499 13.042 8.17441C12.7109 8.97384 12.1501 9.65712 11.4306 10.1379C10.7112 10.6186 9.86532 10.8752 9.00003 10.8752C7.84009 10.8739 6.72801 10.4126 5.90781 9.59239C5.0876 8.77219 4.62627 7.66011 4.62503 6.50017Z"
          fill={props.stroke ? props.stroke : "white"}
        />
      </g>
    </svg>
  );
}

export default MobileUserIcon