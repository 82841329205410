import * as React from 'react';
import './style.scss';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import {
  getSupplierAnswers,
  getSupplierOptions,
  getSupplierQuestions,
  getSupplierResult,
  getSupplierStatus,
  getSupplierSurveyRefs,
  getSupplierSurveys,
  getSuppliers,
  sendRemainderToSupplier
} from '../../Actions/supplierAction';
import dayjs from 'dayjs';
import { SupplierStatusTypes } from '../../../../Redux/Types/suppliersTypes';
import { BETable } from '../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import { Col, Row, Spin } from 'antd';
import BEStatusTag from '../../../../Components/BEStatusTag';
import { SupplierDetailCards } from './SupplierDetailCards';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { inviteSuppliers, deleteSupplierRef } from '../../Actions/supplierAction';
import { BEMessage } from '../../../../Components/BEMessage';
import { SelectDeadlineModal } from './SupplierDetailCards/SelectDeadlineModal';
import AddSupplierAssessment from '../Drawers/AddSupplierAssessment';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import AddSuppliers from '../Drawers/AddSuppliers';
import { useParams } from 'react-router-dom';
import ShareIcon from '../../../../Components/BEIcons/ShareIcon';
import DeleteIcon from '../../../../Components/BEIcons/DeleteIcon';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { ChangeDateFormat } from '../../../../Config/Functions/UsefullFunctions';
import { getSupplierScore } from '../../Actions/supplierAction';
import { SupplierScoreTypes } from '../../../../Redux/Types/suppliersTypes';
import { Dropdown, MenuProps } from 'antd';
import DownloadSupplierResponse from './DownloadPDF';
import BEBackButton from '../../../../Components/BEBackButton';

export interface IAllSuppliersAssessmentsProps {
}

export function AssessmentsDetails(props: IAllSuppliersAssessmentsProps) {
  const { assessment_id } = useParams();
  const dispatch = useAppDispatch();
  const AllAsessments = useTypedSelector(state => state.supplier.SuppliersSurveys);
  const supplierAnswers = useTypedSelector(state => state.supplier.allAnswers);
  const supplierQuestions = useTypedSelector(state => state.supplier.SupplierQuestions);
  const suppliers = useTypedSelector(state => state.supplier.Suppliers);
  const supplierSurveyRefs = useTypedSelector(state => state.supplier.SuppliersSurveyRefs);
  const supplierStatus = useTypedSelector(state => state.supplier.SupplierStatus);
  const suppliersScore = useTypedSelector(state => state.supplier.SupplierScore);
  const [supplierAssessmentData, setSupplierAssessmentData] = React.useState<any>([]);
  const [selectedSuppliers, setSelectedSupplier] = React.useState<any>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [openAddSupAss, setOpenSupAss] = React.useState<any>(false)
  const [openAddSupplier, setOpenAddSupplier] = React.useState<any>(false)
  const [averageScore, setAverageScore] = React.useState<any>(null);
  const [currentSupplier, setCurrentSupplier] = React.useState<any>(null);

  const sendRemainder = () => {
    dispatch(sendRemainderToSupplier([{
      assessment: Number(assessment_id),
      email: currentSupplier.email,
    }]));
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <div
        onClick={() => sendRemainder()}
      >Send Reminder</div>
    },
    {
      key: '2',
      label: <div
        onClick={() => {
          navigator.clipboard.writeText(`${process.env.REACT_APP_CLIENT_URL}/brsr-supplier-assessment/?secret=` + currentSupplier.secret)
          BEMessage.success('Link copied to clipboard');
        }}
      >Copy Link</div>
    }

  ]

  React.useEffect(() => {
    dispatch(getSuppliers());
    dispatch(getSupplierSurveyRefs(Number(assessment_id)));
    dispatch(getSupplierStatus(Number(assessment_id)));
    dispatch(getSupplierScore(Number(assessment_id)));
    dispatch(getSupplierQuestions());
    dispatch(getSupplierAnswers(Number(assessment_id)));
    if (AllAsessments?.status === 'idle') dispatch(getSupplierSurveys());
  }, [])

  const deleteSuppleirRef = (id: number) => {
    dispatch(deleteSupplierRef(id, Number(assessment_id)));
  }


  React.useEffect(() => {
    let tempData: any = [];
    let totalScore: number = 0;
    let totalSupplierFilled: number = 0;
    suppliers?.data.forEach((data: any) => {
      let refData = supplierSurveyRefs?.data?.find((item: any) => item.supplier === data.id);
      if (refData) {
        let statusData = supplierStatus?.data?.find((item: any) => item.assessment_supplier === refData?.id);
        let supplierScore = suppliersScore?.data?.find((item: SupplierScoreTypes) => item?.supplier === refData?.supplier);
        tempData.push({
          ...data,
          status: statusData ? statusData?.status : 0,
          deadline: statusData ? statusData?.expires_at : '-',
          secret: statusData ? statusData?.secret : '-',
          score: supplierScore && statusData?.status === 3 ? supplierScore?.score : '-',
          refID: refData?.id,
          total_questions: suppliersScore?.data[0]?.total_questions
        }
        )
        if (supplierScore && statusData?.status === 3) {
          totalScore += supplierScore?.score;
          totalSupplierFilled++;
        }
      }
    })
    console.log(tempData);
    setSupplierAssessmentData(tempData);
    setAverageScore((totalScore * 100) / (totalSupplierFilled * suppliersScore?.data[0]?.total_questions));
  }, [suppliers, supplierSurveyRefs, supplierStatus, suppliersScore])

  const columns: ColumnsType<any> = [
    {
      title: "Partner name",
      dataIndex: "name",
    },
    {
      title: "Industry",
      dataIndex: "industry",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: any, record: any) => {
        if (supplierStatus?.status === "loading") return <Spin />;
        if (record?.status === 0) {
          return <BEStatusTag status="error">Not Invited</BEStatusTag>;
        } else if (record?.status === 1) {
          return <BEStatusTag status="warning">Pending</BEStatusTag>;
        } else if (record?.status === 2) {
          return <BEStatusTag status="warning">In progress</BEStatusTag>;
        } else {
          return <BEStatusTag status="success">Completed</BEStatusTag>;
        }
      },
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      render: (text: any, record: any) => {
        if (supplierStatus?.status === "loading") return <Spin />;
        if (record?.status === 0) {
          return <p style={{ margin: "0" }}>-</p>;
        }
        return <p style={{ margin: "0" }}>{ChangeDateFormat(text)}</p>;
      },
    },
    {
      title: "Score",
      dataIndex: "score",
      render: (text: any, record: any) => {
        if (suppliersScore?.status === "loading") return <Spin />;
        return (
          <span>
            {record?.status === 3 && record
              ? ((text * 100) / record.total_questions).toFixed(2) + "%"
              : "-"}
          </span>
        );
      },
    },
    //     {
    //   title: "Last compliance",
    //   dataIndex: "last_compliance_fy",
    //   render: (text: any, record: any) => {
    //     if (text === 0) {
    //       return <p style={{ margin: '0' }}>-</p>
    //     }
    //     return <p style={{ margin: '0' }}>{text}</p>
    //   }
    // },
    {
      title: "Action",
      render: (text, record) => (
        <div
          style={{ display: "flex", gap: "1rem" }}
          onMouseEnter={() => {
            setCurrentSupplier(record);
          }}
        >{
            record?.status === 3 &&
            <>
              {
                supplierAnswers?.status === 'success' && supplierQuestions?.status === 'success' ?
                  <DownloadSupplierResponse
                    supplierDetails={record}
                    questions={supplierQuestions?.data.filter((data: any) =>
                      supplierAnswers?.data?.find((ans: any) => ans.question === data.id)
                    )}
                    answers={supplierAnswers?.data.filter((data: any) => data.supplier === record.id)}
                  /> :
                  <Spin size='small' />
              }
            </>
          }
          {record?.status === 1 && (
            <Dropdown menu={{ items }}>
              <div>
                <ShareIcon />
              </div>
            </Dropdown>
          )}
          {record?.status === 0 && (
            <div
              style={{ height: "1rem" }}
              onClick={() => {
                deleteSuppleirRef(record.refID);
              }}
            >
              <DeleteIcon inheritSize stroke={PrimaryTheme.primaryGray} />
            </div>
          )}
        </div>
      ),
    },
  ];

  const handleInviteSuppliers = () => {
    console.log(selectedSuppliers);
    if (selectedSuppliers?.length === 0) {
      BEMessage.error('Please select atleast one partner');
      return;
    }
    let flag: boolean = false;
    selectedSuppliers.forEach((supplier: any) => {
      if (supplier?.status !== 0) flag = true;
    })
    if (flag) {
      BEMessage.error('Please only select partners which are not invited');
      return;
    }
    setOpen(true);
  }

  return (
    <div className="supplier-assessment-details">
      <BEBackButton title={'Viewing the assessment for ' +
        AllAsessments?.data?.find((data: any) => data.id === Number(assessment_id))?.title
      } /><br />
      <Row>
        <Col span={16}>
          <SupplierDetailCards
            averageScore={averageScore}
          />
        </Col>
        <Col span={4}></Col>

      </Row>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <BEInput
          style={{ width: "313px" }}
          size="large"
          placeholder="Search for partners"
          search
        />
        <BEButton
          size="large"
          className="primary"
          style={{ marginLeft: "auto" }}
          onClick={() => setOpenAddSupplier(true)}
        >
          Add Partners
        </BEButton>
        <BEButton
          size="large"
          className="primary"
          onClick={handleInviteSuppliers}
        >
          Invite Partners
        </BEButton>
        {supplierStatus?.status === 'success' && supplierStatus?.data.filter((data: any) => data?.status === 3)?.length > 0 &&
          <BEButton
            allowAuditorClick
            size="large"
            onClick={() => navigateTo(`/results/supplier/${assessment_id}`, true)}
          >
            View Result
          </BEButton>
        }
      </div>

      <AddSupplierAssessment open={openAddSupAss} setOpen={setOpenSupAss} />
      <AddSuppliers open={openAddSupplier} setOpen={setOpenAddSupplier} />

      <BETable
        columns={columns}
        data={supplierAssessmentData}
        loading={
          suppliers?.status === "loading" ||
          supplierSurveyRefs?.status === "loading"
        }
        pagination
        rowKey="id"
        handleRowSelection={(record: any) =>
          setSelectedSupplier(
            supplierAssessmentData.filter((data: any) =>
              record.includes(data.id)
            )
          )
        }
      />
      <SelectDeadlineModal
        data={selectedSuppliers}
        open={open}
        setOpen={setOpen}
        supplierSurveyRefs={supplierSurveyRefs}
      />
    </div>
  );
}
