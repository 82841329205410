import * as React from 'react';
import { NewBETable } from '../../../../../../Components/BETable/newBETable';
import { ColumnsType } from 'antd/es/table';
import '../../styles.scss';
import { Select } from 'antd';
import CheckFilledIcon from '../../../../../../Components/BEIcons/CheckFilledIcon';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { BusinessUnitType } from '../../../../../../Redux/Types/entityTypes';
import { BEInput } from '../../../../../../Components/BEFormItems/BEInput';
import { BEMessage } from '../../../../../../Components/BEMessage';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { patchBusinessUnit } from '../../../../Actions';

interface IBusinessUnitMappingDrawerProps {
	setOpen: Function
}

interface BusinessUnitMap {
	[key: string]: {
	   system_business_unit_name: string;
	   business_unit_id: number;
	};
 }

const BusinessUnitMappingDrawer: React.ForwardRefRenderFunction<{ onButtonClick: () => void }, IBusinessUnitMappingDrawerProps> = (props, ref) => {
	const dispatch = useAppDispatch();
	const businessUnits = useTypedSelector((state)=> state.entity.BusinessUnits);
	const [selectedBusinessUnitMap, setSelectedBusinessUnitMap] = React.useState<BusinessUnitMap>({});

	React.useEffect(() => {
		if(businessUnits.data?.length > 0){
			const map:any = {};
			businessUnits.data.forEach((item:BusinessUnitType)=>{
				map[item.name] = {
					system_business_unit_name: item.system_business_unit_name || '',
					business_unit_id: item.id
				}
			});
			setSelectedBusinessUnitMap(map);
		}
	}
	, [businessUnits.data]);

	const columns: ColumnsType<any> = [
		{
			title: 'Business Unit',
			dataIndex: 'business_unit',
			key: 'business_unit'
		},
		{
			title: 'Data',
			dataIndex: 'data',
			key: 'data',
			render: (text:any, record:any) => (
				<BEInput 
					value={selectedBusinessUnitMap?.[record.business_unit]?.system_business_unit_name}
					onChange={(e)=>{ setSelectedBusinessUnitMap({...selectedBusinessUnitMap, [record.business_unit]: {
						system_business_unit_name:e.target.value,
						business_unit_id: record.id
					}})
					
				}}
				/>
			)
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text:any, record:any) =>  selectedBusinessUnitMap?.[record.business_unit]?.system_business_unit_name ? <CheckFilledIcon /> : '---'
		}
	]

	const checkAtleastOneBusinessUnitMapped = () => {
		const atleastOneMapped = Object.values(selectedBusinessUnitMap).some((item:any)=>{
			return item.system_business_unit_name !== ''; 
		});
		return atleastOneMapped;
	}

	const onButtonClick = () => {
		if(!checkAtleastOneBusinessUnitMapped()) return BEMessage.error('Please map atleast one business unit');
		// Update all business units system_business_unit_name
		businessUnits.data.filter((item:BusinessUnitType)=>{
			const selectedItem = selectedBusinessUnitMap[item.name];
			return selectedItem.system_business_unit_name !== item.system_business_unit_name;
		}
		).forEach(async(item:BusinessUnitType)=>{
			await dispatch(patchBusinessUnit(item.id, {system_business_unit_name: selectedBusinessUnitMap[item.name].system_business_unit_name}));
		});
		BEMessage.success('Business unit updated successfully');
		props.setOpen(false);
	}

	React.useImperativeHandle(ref, () => ({ onButtonClick }), [selectedBusinessUnitMap, businessUnits.data]);
	return (
		<NewBETable 
			formatTitle
			loading={businessUnits.status === 'loading'}
			columns={columns}
			className='data-mapping-table'
			data={businessUnits.data.map((item:BusinessUnitType)=>(
				{
					key: item.id,
					id: item.id,
					business_unit: item.name
				}
			))}
		/>
	);
}

export default React.forwardRef(BusinessUnitMappingDrawer);