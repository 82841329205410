import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import CheckIcon from '../../../../../Components/BEIcons/CheckIcon';

interface ITopicDescriptionDrawerProps {
	open: boolean;
	setOpen: Function;
	descriptionData: any;
}

export function TopicDescriptionDrawer (props: ITopicDescriptionDrawerProps) {
	return (
	  <BEDrawer width='500px' heading={"Topic info"} open={props.open} setOpen={props.setOpen}>
		<p style={{ fontWeight: '600', fontSize: '1rem' }}>
			{props.descriptionData.title}
		</p>
		<p style={{marginTop:'1rem'}}>{props.descriptionData.description}</p>
	  </BEDrawer>
	);
}
