import * as React from 'react';
import StackedColumnCharts from '../../../../../../Components/BECharts/StackedColumnCharts';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { AddCommaToNumber, rotateArray } from '../../../../../../Config/Functions/UsefullFunctions';
import { shortMonths } from '../../../../../../Config/Data/genralData';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { ColumnsType } from 'antd/es/table';
import { BEWrapper } from '../../../../../../Components/BEWrapper';
import DonutChart from '../../../../../../Components/BECharts/DonutCharts';
import { NewBETable } from '../../../../../../Components/BETable/newBETable';
import { getScope2TypeName } from '../../../../Actions';

export interface ITotalEmissionsByScopeProps {
}

export function TotalEmissionsByScopeGraph(props: ITotalEmissionsByScopeProps) {
  const dispatch =useAppDispatch();
  const yearEnd = useTypedSelector(state => state.companies.yearEnd);
  const filteredScope1Calculations = useTypedSelector(state => state.emissions.filteredEmissionCalculations.scope1Calculations);
  const filteredScope2Calculations = useTypedSelector(state => state.emissions.filteredEmissionCalculations.scope2Calculations);
  const filteredScope3Calculations = useTypedSelector(state => state.emissions.filteredEmissionCalculations.scope3Calculations);
  const scope2EmissionType = useTypedSelector(state => state.emissions.scope2EmissionType)
  const [chartData, setChartData] = React.useState<any>([]);
  
  const columns:ColumnsType<any> = [
    {
      title: 'SCOPE',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '% OF EMISSIONS',
      render: (record: any) => `${((record.value / (filteredScope1Calculations.data.reduce((acc: any, item: any) => acc + item.tco2e, 0) + filteredScope2Calculations.data.reduce((acc: any, item: any) => acc + item.tco2e, 0) + filteredScope3Calculations.data.reduce((acc: any, item: any) => acc + item.tco2e, 0)))*100).toFixed(2)}%`,
      key: 'percentage',
    },
    {
      title: 'TOTAL EMISSIONS (tCO2e)',
      render: (record: any) => AddCommaToNumber(record.value, false, true, true),
      key: 'value',
      sorter: (a: any, b: any) => (a.value - b.value)>0?1:-1,
    }
  ];
  
  const getDataByScope = () => {
    let data:any = [
      {
        name: 'Scope 1',
        value: filteredScope1Calculations.data.reduce((acc: any, item: any) => acc + item.tco2e, 0)
      },
      {
        name: `Scope 2 ${getScope2TypeName(scope2EmissionType)}`,
        value: filteredScope2Calculations.data.reduce((acc: any, item: any) => acc + item.tco2e, 0)
      },
      {
        name: 'Scope 3',
        value: filteredScope3Calculations.data.reduce((acc: any, item: any) => acc + item.tco2e, 0)
      }
    ]
    return data;
  }

  React.useEffect(() => {
    let scope1MonthsAggrigateArey: any = new Array(12).fill(0);
    let scope2MonthsAggrigateArey: any = new Array(12).fill(0);
    let scope3MonthsAggrigateArey: any = new Array(12).fill(0);
    
    filteredScope1Calculations.data.forEach((element: any) => {
      scope1MonthsAggrigateArey[element.month-1] += element.tco2e;
    });
    filteredScope2Calculations.data.forEach((element: any) => {
      scope2MonthsAggrigateArey[element.month-1] += element.tco2e;
    });
    filteredScope3Calculations.data.forEach((element: any) => {
      scope3MonthsAggrigateArey[element.month-1] += element.tco2e;
    });
    scope1MonthsAggrigateArey = scope1MonthsAggrigateArey.map((element: any) => {
      return Number(element.toFixed(2))
    })
    scope2MonthsAggrigateArey = scope2MonthsAggrigateArey.map((element: any) => {
      return Number(element.toFixed(2))
    })
    scope3MonthsAggrigateArey = scope3MonthsAggrigateArey.map((element: any) => {
      return Number(element.toFixed(2))
    })
    let tempChartData: any = [
      {
        name: 'Scope 1',
        data: scope1MonthsAggrigateArey
      },
      {
        name: `Scope 2 ${getScope2TypeName(scope2EmissionType)}`,
        data: scope2MonthsAggrigateArey
      },
      {
        name: 'Scope 3',
        data: scope3MonthsAggrigateArey
      }
    ]
    tempChartData.forEach((item: any) => {
      item.data = rotateArray(item.data, yearEnd, true)
    })
    setChartData(tempChartData)
  }, [filteredScope1Calculations,filteredScope2Calculations,filteredScope3Calculations,scope2EmissionType]);

  return (
    <div style={{ margin: "2rem 0" }}>
      <p className='chart-title'>Total emissions by Scope (tCO2e)</p>
      <BEWrapper>
      <div className='table-container'>
          <BEWrapper>
            <DonutChart
              data={getDataByScope()}
            />
          </BEWrapper>
          <BEWrapper>
            <NewBETable
              columns={columns}
              data={getDataByScope().sort((a:any,b:any)=>b.value-a.value)}
            />
          </BEWrapper>
        </div>
        <div style={{ padding: "1rem" }} className='bordered-container'>
          <StackedColumnCharts
            data={chartData}
            xAxisLabels={rotateArray(shortMonths, yearEnd, true)}
            // chartTitle="Total emissions by Scope (tCO2e)"
            yAxisLabel="Tonnes of Co2 equivalent"
          />
        </div>
      </BEWrapper>
    </div>
  );
}
