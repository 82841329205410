import * as React from 'react';
import { Framework } from './Framework'
import { DataStatusPanel } from './DataStatusPanel'
import { EmissionPieChart } from './EmissionPieChart'
import './styles.scss'
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector'
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch'
import { getDashoardInsights } from '../../Actions'
import { ESGProgress } from './ESGProgress'
import { getAllReports, getAllTemplateDrafts } from '../../../Reporting/Actions/template';


interface IInsightsDashboardProps {
}

export function InsightsDashboard(props: IInsightsDashboardProps) {
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const allReports = useTypedSelector(state => state.reporting.allReports);
  const templates = useTypedSelector(state => state.reporting.TemplateDrafts);
  const [progressData, setProgressData] = React.useState<any>(null);
  const dispatch = useAppDispatch();

  const getInsightsData = async (year: Number) => {
    const data = await dispatch(getDashoardInsights(currentYear));
    if (data) {
      const esgProgressData = [
        { title: "ESG Overall", percent: data?.total_progress?.toFixed(2) },
        { title: "Environmental", percent: data?.env_progress?.toFixed(2) },
        { title: "Social", percent: data?.social_progress?.toFixed(2) },
        { title: "Governance", percent: data?.gov_progress?.toFixed(2) },
        // { title: "Target", percent: 25 }
      ];
      setProgressData(esgProgressData);
    }
  }

  React.useEffect(() => {
    if (currentYear)
      getInsightsData(currentYear);
  }, [currentYear]);

  React.useEffect(() => {
    if (allReports.status === "idle") {
      dispatch(getAllReports());
    }
    if (templates.status === "idle") {
      dispatch(getAllTemplateDrafts());
    }
    console.log(allReports, templates);
  }, [allReports, templates]);

  return (
    <div className='insights-dashboard'>
      <ESGProgress data={progressData} />
      <div className='body' >
        <div>
          <EmissionPieChart />
          <Framework />
        </div>
        <div>
          <DataStatusPanel />
        </div>
      </div>
    </div>
  )
}
