import { Form, Modal } from 'antd';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { BEMessage } from '../../../../../Components/BEMessage';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { postAssignedReportToUsers } from '../../../Actions/template';
import React from 'react';
import BEMultipleSelect from '../../../../../Components/BEMultipleSelect';

export interface IInviteCollaboratorProps {
    open: boolean;
    setOpen: Function;
    uniqueCode: string;
}

export function InviteCollaborator(props: IInviteCollaboratorProps) {
    const currentReport = useTypedSelector(state => state.reporting.currentReport);
    const users = useTypedSelector(state => state.userMgmt);
    const assignReportToUsers = useTypedSelector(state => state.reporting.assignReportToUsers)
    const [loading, setLoading] = React.useState(false);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();


    const onFinish = async (values: any) => {
        console.log(values);
        const name = values.name;
        let firstName = '';
        let lastName = '';
        if(name){
            const nameParts = name.trim().split(' ');
            firstName = nameParts[0] || '';
            lastName = nameParts[1] || '';
        }
        const user = users.data.find((user) => user.email === values.email && (name ? user.first_name === firstName && user.last_name === lastName : true));
        if (user) {
            if (user.role !== 'ADMIN' && user.role !== 'SUPER_ADMIN' && user.role !== 'ESG_CONSULTANT') {
                BEMessage.error('User does not have permission to access reports. Only Admins, Super Admins and ESG Consultants can access reports');
                return;
            }
            setLoading(true);
            const resp = await dispatch(postAssignedReportToUsers({
                report: currentReport?.id,
                user: user.id,
                unique_code: props.uniqueCode
            }));
            setLoading(false);
            props.setOpen(false);
        } else {
            BEMessage.error('User does not exist');
        }
    };

    React.useEffect(() => {
        form.resetFields();
    },[props.open])

    return (
        <Modal
            title={<h3>Invite Collaborator</h3>}
            onCancel={() => props.setOpen(false)}
            open={props.open}
            footer={
                <BEButton
                    loading={loading}
                    className='primary'
                    size='large'
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Invite
                </BEButton>
            }
        >
            <Form onFinish={onFinish} form={form}>
                <LableRequired>Email</LableRequired>
                <Form.Item
                    name='email'
                    rules={[{ required: true, message: 'Please enter email' }]}
                >
                    <BEMultipleSelect
                        single
                        placeholder='Email'
                        isDataStrArray
                        name='email'
                        data={users.data.filter((user) => (user.role === 'ADMIN' || user.role === 'SUPER_ADMIN' || user.role === 'ESG_CONSULTANT') &&
                             !assignReportToUsers.data.some(assignedUser => assignedUser.user == user.id && assignedUser.unique_code === props.uniqueCode && assignedUser.report === currentReport?.id)
                            )}
                        labelFeild='email'
                        valueFeild='email'
                    />
                </Form.Item>
                Name
                <Form.Item
                    name='name'
                    rules={[{ required: false}]}
                >
                    <BEInput
                        placeholder='Enter name'
                    />
                </Form.Item>
            </Form>

        </Modal>
    );
}
