
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip, Legend  } from 'recharts';
import { useEffect, useState } from 'react';
import { graphColors, graphColors2, graphColors3 } from '../../../Config/Theme/graphColors';
import { GlobalLoader } from '../../GlobalLoader';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { AddCommaToNumber, changeSnakeCaseToSentence } from '../../../Config/Functions/UsefullFunctions';


interface ChartProps {
  xAxisLabels?: string[];
  data: any[];                          /// data should be in this format [{name:'name',value:123}]
  xAxisTitle?: string;
  yAxisTitle?: string;
  chartTitle?: string;
  loading?: boolean;
  colorTheme?: number;
  size?: number;       // this is the relative size of the chart
  disableSort?: boolean;

}

const DonutChart = (props: ChartProps) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    let tempData: any = [];
    // add top 9 and rest as others
    
    let data = !props.disableSort ? props.data.sort((a,b) => b.value - a.value) : props.data;
    data = props.data.slice(0,9);
    
    data.map((item: any) => {
      tempData.push({
        name: changeSnakeCaseToSentence(item?.name,false,true),
        value: Number(item?.value),
      });
    });

    if(props.data.length > 9){
      let others = props.data.slice(9).reduce((acc:any, item:any) => acc + item.value, 0);
      tempData.push({
        name: 'Others',
        value: others,
      });
    }
    setData(tempData);
  }, [props.data]);
  
  const CustomLegand = (props:any) => {
    const { payload } = props;
    return (
      <div style={{display:'flex',gap:'0.3rem 1rem',justifyContent:'center',alignItems:'center',flexWrap:'wrap'}}>
        {
          payload.map((entry:any, index:number) => (
            <div style={{display:'flex',alignItems:'center',gap:'0.3rem'}}>
              <span style={{width:'10px',height:'10px',backgroundColor:entry.color}}></span>
              <span style={{fontSize:'0.7rem',color:entry.color}}>
                { entry.value }
              </span>
            </div>
          ))
        }

      </div>
      // <ul style={{listStyleType:'none',padding:0,margin:0}}>
      //   {payload.map((entry:any, index:number) => (
      //     <li key={`item-${index}`} style={{display:'flex',alignItems:'center',marginBottom:'5px'}}>
      //       <span style={{width:'10px',height:'10px',backgroundColor:entry.color,borderRadius:'50%',marginRight:'5px'}}></span>
      //       <span>{entry.value}</span>
      //     </li>
      //   ))}
      // </ul>
    )
  }

  if(props.loading)return <GlobalLoader height='600px' />

  return (
    <div style={{width:'100%',
    height:`${props?.size ? props.size*4 : 500}px`,
    }}>
      <h2>{props.chartTitle}</h2>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart 
          width={props?.size ? props.size*2 : 200}
          height={props?.size ? props.size*2 : 200}
         >
          <Pie
            data={data}
            innerRadius={props?.size ? props.size*0.4 : 40}
            outerRadius={props?.size ? props.size*0.9 : 90}
            fill="#8884d8"
            dataKey="value"
            // width={600}
            // height={300}
            label={(data) => `${data.name}`}
            minAngle={5}
            paddingAngle={2}
          >
            {data.map((entry:any, index:number) => (
              <Cell key={`cell-${index}`} fill={
                !props.colorTheme ? graphColors[index % graphColors.length] :
                props.colorTheme === 2 ? graphColors2[index % graphColors.length] :
                props.colorTheme === 3 ?graphColors3[index % graphColors.length] :
                'white'
              } 
              fontSize={'0.7rem'}
              />
            ))}
          </Pie>
          <Tooltip
            formatter={(value:any) => AddCommaToNumber(value,false,true) + ' tCO2e' + ' (' + (value*100/props.data.reduce((acc:any, item:any) => acc + item.value, 0)).toFixed(2) + '%)'}
          />
          <Legend
              content={<CustomLegand/>}
             />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default DonutChart