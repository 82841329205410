import * as React from 'react';
import NotificationIcon from '../../../../Components/BEIcons/NotificationIcon';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { getNotifications } from '../../../Actions/HomeActions';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { NotificationsBox } from './NotificationsBox';
import './style.scss';
import useOutsideClick from '../../../../Config/Hooks/useOutsideClick';

export interface INotificationProps {
}

export function Notification(props: INotificationProps) {
  const dispatch = useAppDispatch()
  const [open, setOpen] = React.useState(false);
  const wrapperRef = React.useRef(null);
  const notifications = useTypedSelector(state => state.common.notification);
  useOutsideClick(wrapperRef, () => setOpen(false));

  React.useEffect(() => {
    dispatch(getNotifications())
  }, [])



  return (
    <div 
      ref={wrapperRef}
      className='notification-container'
    >
      <div 
      onClick={() => setOpen(!open)}  
      className='notification-icon'>
        <NotificationIcon inheritSize fill={PrimaryTheme.primaryGray} />
        {
          notifications?.data.filter((notification) => !notification.read)?.length > 0 &&
          <div className='notification-count'>
            <>
              {
              notifications?.data.filter((notification) => !notification.read)?.length < 100 ?
              notifications?.data.filter((notification) => !notification.read)?.length :
              '99+'
              }
            </>
          </div>
        
        }
      </div>
      {
        open &&
        <div className='notifications-box-outer' >
          <NotificationsBox />
        </div>
      }
    </div>
  );
}
