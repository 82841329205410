import * as React from 'react';
import { DerivedMetricDataTable } from './MetricDataTable/DerivedMetricDataTable';
import { DerivedMetricTrendGraaph } from './TrendGraph/DerivedMericTrendGraph';
import { BETabs } from '../../../../../../Components/BETabs';
import { BECollapse } from '../../../../../../Components/BECollapse';
import BEStatusTag from '../../../../../../Components/BEStatusTag';
import ToolTip from '../../../../../../Components/BEToolTip';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { AddCommaToNumber } from '../../../../../../Config/Functions/UsefullFunctions';
import { MyDerivedMetric } from '../../../../../../Redux/Types/dataManagerTypes';
import QuestionMark from '../../../../../../Components/BEIcons/QuestionMarkIcon';
import { PrimaryTheme } from '../../../../../../Config/Theme/theames';

export interface IDerivedMetricDataProps {
  metric: MyDerivedMetric;
  setOpenMetricDescription: Function;
}

export function DerivedMetricData(props: IDerivedMetricDataProps) {
  const [totalValue, setTotalValue] = React.useState<number>(0);
  const tableDerivedMetricsDataDM = useTypedSelector(state => state.dataManager.metrics.tableDerivedMetricsDataDM);
  React.useEffect(() => {
    const total = tableDerivedMetricsDataDM?.data[props.metric.id]?.reduce((acc: number, item: any) => {
      return acc + item.value;
    }, 0);
    setTotalValue(total ? total : 0);
  }, [props.metric, tableDerivedMetricsDataDM?.data]);

  return (
    <div style={{ marginTop: '1rem' }}>
      <BECollapse
        headingRightContent={
          <BEStatusTag status="info">
            {AddCommaToNumber(totalValue, false, true, true, 2, true)}{' '}{props.metric.unit}
          </BEStatusTag>
        }
        headingStyle={{ height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        heading={
          <div style={{ display: 'flex', gap: '1rem' }}>
            <p style={{display:'flex', gap:'0.5rem', fontWeight: '600', fontSize: '1rem' }}>
              {props.metric.title}
              { props.metric.description !== '' && props.metric.description !== "nan"  && 
                <span style={{display:'flex', alignItems:'center'}} 
                  onClick={()=> 
                      props.setOpenMetricDescription(
                        {
                          title: props.metric.title,
                          desc: props.metric.description}
                      )}
                  >
                    <QuestionMark fill={PrimaryTheme.primaryGray}/>
                  </span>
              }
            </p>
            <ToolTip title='The values for C metrics are calculated.'>
              <BEStatusTag status='success'>C</BEStatusTag>
            </ToolTip>
          </div>
        }
      >

        <BETabs
          tabBarStyle={{ marginLeft: 20, marginTop: '1rem' }}
          items={[
            {
              key: 'data-table',
              label: 'Data Table',
              children: <DerivedMetricDataTable
                metric={props.metric}
              />
            },
            // {
            //   key: 'trend-graph',
            //   label: 'Trend Graph',
            //   children: <DerivedMetricTrendGraaph 
            //     //metric = {props.metric}
            //   />
            // }
          ]}
        />
      </BECollapse>
    </div>
  );
}
