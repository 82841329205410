import * as React from 'react';
import { MyMetric, MyMetricData } from '../../../Redux/Types/dataManagerTypes';
import { BEDrawer } from '../../../Components/BEDrawer';
import { BEButton } from '../../../Components/BEFormItems/BEButton';
import ArrowDownIcon from '../../../Components/BEIcons/ArrowDownIcon';
import { TotalMetricsSummary } from '../../DataManager/Components/TotalMetricsSummary';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import { MetricEntryRow } from '../../DataManager/Components/MetricEntryRow';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { DataEntryDrawer } from '../DataEntryDrawer';
import { BEPagination } from '../../../Components/BEPagination';

interface IMetricDataDrawerProps {
	metric: MyMetric | null;
	setOpen: Function;
}

export function MetricDataDrawer(props: IMetricDataDrawerProps) {
	const myMetricData = useTypedSelector(state => state.onBoarding.metrics.myMetricsData);
	const myMetrics = useTypedSelector(state => state.onBoarding.metrics.myMetrics);
	const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
	const [openMetricUploadDrawer, setOpenMetricUploadDrawer] = React.useState<MyMetric | null>(null);
	const [currentMyMetricData, setCurrentMyMetricData] = React.useState<MyMetricData| null>(null);
	const [filteredMyMetricData, setFilteredMyMetricData] = React.useState<MyMetricData[]>([]);
	const [page, setPage] = React.useState(1);

	React.useEffect(() => {
	if (props.metric !== null && myMetricData.data) {
			setFilteredMyMetricData(myMetricData.data.filter((item) => item.my_metric === props.metric?.id  && item.business_unit === currentBusinessUnit));
		}
	}, [props.metric, myMetricData.data]);
	
	return (
		<>
			<BEDrawer
				open={props.metric !== null}
				setOpen={() => props.setOpen(null)}
				width='100%'
				isMobile={true}
				closeIcon
				footer={
					<BEButton  onClick={()=>{
						setOpenMetricUploadDrawer(props.metric);
						setCurrentMyMetricData(null);
					}} style={{ width: '95%' }} className='primary'>
						Add entry
					</BEButton>
				}
			>
				<div style={{ padding: '1rem 0', }}>
					<div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }} onClick={() => props.setOpen(null)}>
						<span style={{ transform: 'rotate(90deg)' }}><ArrowDownIcon /></span>
						<span>Back</span>
					</div>
					<h2 style={{ margin: '1rem 0', fontWeight: '500', color: PrimaryTheme.primaryGray }}>{props.metric?.title}</h2>
				</div>
				<TotalMetricsSummary
					totalValue={myMetricData.data.filter((item) => item.my_metric === props.metric?.id && item.business_unit === currentBusinessUnit && (item.status === 1 || item.status ===2)).reduce((acc, item) => acc + item.value, 0)}
					unit={myMetrics.data.find((item) => item.id === props.metric?.id)?.unit || ''}
					approvedValue={myMetricData.data.filter((item) => item.my_metric === props.metric?.id && item.business_unit === currentBusinessUnit && (item.status ===2)).reduce((acc, item) => acc + item.value, 0)}
				/>
				<hr className='hr' />
				{
					filteredMyMetricData?.slice((page-1) * 5, page * 5).map((item, index) => (
						<MetricEntryRow
							index={index + 1}
							myMetricData={item}
							setCurrentMyMetricData={setCurrentMyMetricData}
							setOpenMetricUploadDrawer={setOpenMetricUploadDrawer}
						/>
					))
				}
				{filteredMyMetricData?.length > 5 && <div style={{display:'flex',justifyContent:'center',padding:'1rem'}}>
					<BEPagination total={filteredMyMetricData?.length} pageSize={5} onChange={(page) => setPage(page)} />
				</div>}
			</BEDrawer>
			<DataEntryDrawer
				metric={openMetricUploadDrawer}
				setOpen={setOpenMetricUploadDrawer}
				myMetricData={currentMyMetricData}
			/>
		</>
	);
}
