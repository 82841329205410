export const graphColors = [
  'rgba(31, 119, 180, 1)',//blue 1
  'rgba(255, 127, 14, 1)',//orange 1
  'rgba(44, 160, 44, 1)',//green 1
  'rgba(214, 39, 40, 1)',//red 1
  'rgba(148, 103, 189, 1)',//purple 1
  'rgba(140, 86, 75, 1)',//brown 1
  'rgba(227, 119, 194, 1)',//pink 1
  'rgba(127, 127, 127, 1)',//grey 1
  'rgba(188, 189, 34, 1)',//yellow 1
  'rgba(23, 190, 207, 1)',//blue 2
  'rgba(255, 187, 120, 1)',//orange 2
  'rgba(152, 223, 138, 1)',//green 2
  'rgba(197, 176, 213, 1)',//purple 2
  'rgba(196, 156, 148, 1)',//brown 2
  'rgba(247, 182, 210, 1)',//pink 2
  'rgba(199, 199, 199, 1)',//grey 2
  'rgba(219, 219, 141, 1)',//yellow 2
  'rgba(158, 218, 229, 1)',//blue 3
  'rgba(255, 152, 150, 1)',//orange 3
  'rgba(197, 176, 213, 1)',//green 3
  'rgba(196, 156, 148, 1)',//red 3
  'rgba(247, 182, 210, 1)',//purple 3
  'rgba(199, 199, 199, 1)',//brown 3
  'rgba(219, 219, 141, 1)',//pink 3
  'rgba(158, 218, 229, 1)',//grey 3
  'rgba(255, 152, 150, 1)',//yellow 3
  'rgba(158, 218, 229, 1)',//blue 4
  'rgba(255, 152, 150, 1)',//orange 4
  'rgba(197, 176, 213, 1)',//green 4
  'rgba(196, 156, 148, 1)',//red 4
  'rgba(247, 182, 210, 1)',//purple 4
  'rgba(199, 199, 199, 1)',//brown 4
  'rgba(219, 219, 141, 1)',//pink 4
  'rgba(158, 218, 229, 1)',//grey 4
  'rgba(255, 152, 150, 1)',//yellow 4
  'rgba(158, 218, 229, 1)',//blue 5
  'rgba(255, 152, 150, 1)',//orange 5
  'rgba(197, 176, 213, 1)',//green 5
]

export const graphColors2 = [
  'rgba(242, 174, 114, 1)',//brown
  'rgba(129, 199, 212, 1)',//blue1
  'rgba(109, 142, 184, 1)',//blue2
  'rgba(195, 247, 222, 1)'//blue3
]

export const graphColors3 = [
  'rgba(107, 175, 153, 1)',//green1
  'rgba(155, 223, 196, 1)',//green2
  'rgba(196, 242, 227, 1)',//green3
  'rgba(255, 204, 209, 1)',//pink
]