import { Card, Col, Row } from "antd";
import React from "react";
import { PrimaryTheme } from "../../../../../Config/Theme/theames";
import { BEButton } from "../../../../../Components/BEFormItems/BEButton";
import BESwitch from "../../../../../Components/BEFormItems/BESwitch";
import { navigateTo } from "../../../../../Navigation/NavigationManager";
import { Outlet } from "react-router-dom";
import '../../styles.scss'
import { useAppDispatch } from "../../../../../Config/Hooks/useAppDispatch";
import { patchIntegrationConfig } from "../../../Actions";

interface IntegrationCardProps {
    id: number,
    title: string;
    description: string;
    integrate: boolean;
    logo: string;
    features?: string[];
    onClick: Function;
    available?: boolean;
    comingSoon?: boolean;
}

const features = ['Water']

const IntegrationCard = (props:IntegrationCardProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const handleToggleSwitch = async() => {
    setLoading(true);
    await dispatch(patchIntegrationConfig(props.id, {is_active: !props.integrate}))
    setLoading(false);
  }
  return (
    // <Card style={{ width: "540px" }} hoverable>
    //   <Row>
    //     <Col span={6}></Col>
    //     <Col>
    //       <span
    //         style={{
    //           fontSize: "10px",
    //           color: PrimaryTheme.primaryGreen,
    //           backgroundColor: PrimaryTheme.primaryGreenLight,
    //           padding: "5px",
    //           borderRadius: "4px",
    //           margin: "0.2rem 0",
    //         }}
    //       >
    //         ERP
    //       </span>
    //     </Col>
    //   </Row>
    //   <Row>
    //     <Col span={6}>
    //       <img
    //         src="https://placehold.co/400?text=Company+Logo"
    //         alt=""
    //         style={{
    //           objectFit: "contain",
    //           height: "6rem",
    //           width: "6rem",
    //           marginTop: "10px",
    //         }}
    //       />
    //     </Col>
    //     <Col span={18}>
    //       <div
    //         style={{ fontSize: "16px", fontWeight: 600, margin: "0.2rem 0" }}
    //       >
    //         {props.title}
    //       </div>
    //       <div
    //         style={{ color: PrimaryTheme.secondaryGray, margin: "0.2rem 0" }}
    //       >
    //         {props.desc}
    //       </div>
    //     </Col>
    //   </Row>
    //   <Row style={{ marginTop: "1rem" }}>
    //     <Col span={6}></Col>
    //     <Col>
    //       <div style={{ display: "flex", gap: "2rem" }}>
    //         {props.integrate ? (
    //           <BEButton className="primary" size="large">
    //             Request Integration
    //           </BEButton>
    //         ) : (
    //           <BEButton
    //             className="primary"
    //             style={{ backgroundColor: PrimaryTheme.primaryRedError }}
    //             size="large"
    //           >
    //             Disconnect
    //           </BEButton>
    //         )}
    //         <BEButton size="large">View Details</BEButton>
    //       </div>
    //     </Col>
    //   </Row>
    // </Card>
    <Card hoverable>
      <div style={{padding:'1rem', cursor: 'pointer'}} onClick={() => !props.comingSoon && props.onClick()}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px',width:'390px' }}>
            <img className="icon" src={props.logo} alt="" />
            <h3>{props.title}</h3>
            <p style={{color:PrimaryTheme.primaryGray, fontSize:'0.9rem', height: '22px'}}>{props.description}</p>
           </div>
      </div>
       { !props.comingSoon &&
       <div style={{display:'flex',borderTop:`1px solid ${PrimaryTheme.primaryGreyLight}`,justifyContent:'end',padding:'0.8rem',gap:'0.5rem'}}>
          {props.integrate && <span>Connected</span> }
            <BESwitch 
                loading={loading} 
                onClick={handleToggleSwitch} 
                checked={props.integrate} 
                disabled={props.available}
            />
       </div>}
       {props.available && <div style={{display:'flex', gap:'0.5rem', justifyContent:'space-between'}}>
          {props.features && props.features.length > 0 && props.features?.map((feature, index) => <span key={index} style={{backgroundColor:PrimaryTheme.primaryGreyLight,padding:'0.3rem 1rem', borderRadius:'3px', margin:'0.8rem'}}>{feature}</span>)}
          {props.comingSoon && <span style={{backgroundColor:PrimaryTheme.primaryGreen, color:'white',padding:'0.3rem 1rem', borderRadius:'3px', margin:'0.8rem'}}>Coming Soon</span>}
       </div>}
    </Card>
  );
};

export default IntegrationCard;
