import axios from "axios"
import { BEMessage } from "../../../Components/BEMessage"
import { AppDispatch, RootState } from "../../../Redux/store"
import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { DELETE_GRESB_ASSETS, DELETE_GRESB_ENTITIES, DELETE_GRESB_ENTITY_EVIDENCE, GET_GRESB_ASSETS, GET_GRESB_ENTITIES, GET_GRESB_ENTITY_EVIDENCE, GET_GRESB_PORTAL_ENTITY_DATA, GRESB_PORTAL_ENTITY, GRESB_SLUG_DATA, PATCH_GRESB_ASSETS, PATCH_GRESB_ENTITIES, PATCH_GRESB_ENTITY_EVIDENCE, PATCH_GRESB_PORTAL_ASSET_DATA, POST_GRESB_ASSETS, POST_GRESB_AUTH, POST_GRESB_ENTITIES, POST_GRESB_ENTITY_EVIDENCE, POST_GRESB_PORTAL_ASSET_DATA, POST_GRESB_PORTAL_ENTITY_DATA, POST_TEMPLATE_TOPICS } from "../../../Utils/Routes/ReportingRoutes";
import { setAllSelectedTemplateTopics, setGresbAssetData, setGresbAssetErrors, setGresbEnityEvidences, setGresbEntityAccordionStatus, setGresbEntityData, setGresbSlugData, setSelectedGresbAsset, setSelectedTemplateTopics } from "../../../Redux/ReportingReducer";
import { createTemplateTopics, editReportTemplate, fillReportData, getAllTemplateDrafts, getTemplateTopics, postReportData } from "./template";
import { GRESB_ASSET_DETAILS_VARIABLE_CODES, GRESB_ASSET_DETAILS_VARIABLE_CODES_MAPPING } from "../../../Config/Data/reportingData";

export const scopeCodeMapping = ["entities", "read:assets", "write:assets", "read&write:responses"];
const CLIENT_ID = process.env.REACT_APP_GRESB_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_GRESB_CLIENT_SECRET;
const REDIRECT_URI = process.env.REACT_APP_GRESB_REDIRECT_URI;
const GRESB_PORTAL_URL = process.env.REACT_APP_GRESB_PORTAL_URL;

export const isGresbAuthorized = () => {
    const gresbToken = localStorage.getItem('gresb_token');
    const gresbExpiresAt = localStorage.getItem('gresb_expires_at') as string;
    const gresbScope = localStorage.getItem('gresb_scope');
    const expiresAt = parseInt(gresbExpiresAt);
    if (gresbToken && gresbExpiresAt && gresbScope && new Date().getTime() < expiresAt - 60) {
        return true;
    } else {
        localStorage.removeItem('gresb_token');
        localStorage.removeItem('gresb_expires_at');
        localStorage.removeItem('gresb_scope');
    }
    return false;
}

export const getGresbAuthorizationCode = (scopeCode: number, reportId: number) => async (dispatch: AppDispatch) => {

    const SCOPE = "write:responses write:assets read:assets entities";
    const gresbToken = localStorage.getItem('gresb_token');
    const urlToRedirect = `${GRESB_PORTAL_URL}/oauth/authorize?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&scope=${SCOPE}`;

    if (isGresbAuthorized()) {
        console.log('Token already exists');
        BEMessage.success('You are authorized to access the GRESB portal.');
        return gresbToken;
    } else {
        localStorage.setItem('gresb_report_id', reportId.toString());
        window.location.href = urlToRedirect;
    }
}

export const onGresbAuthorization = (location: any) => async (dispatch: AppDispatch) => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const error = params.get('error');
    const error_description = params.get('error_description');
    if (error) {
        BEMessage.error(error_description || 'Something went wrong, please try again later.');
        return true;
    } else if (code) {
        if (isGresbAuthorized()) {
            return true;
        }
        const authorizationCode = code;
        const body = {
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            code: authorizationCode,
            grant_type: 'authorization_code',
            redirect_uri: REDIRECT_URI
        }
        const [res, error] = await handleAPICall(POST_GRESB_AUTH(body));
        if (res) {
            if (res?.data.error) {
                return false;
            }
            const token = res?.data.access_token;
            const expiresIn = res?.data.expires_in;
            const scope = res?.data.scope;
            // expires_in is in seconds, get the time at which the token will expire
            const expiresAt = new Date().getTime() + expiresIn * 1000;

            localStorage.setItem('gresb_token', token);
            localStorage.setItem('gresb_expires_at', expiresAt.toString());
            localStorage.setItem('gresb_scope', scope);
            BEMessage.success('You are authorized to perform GRESB actions on this report.');
            return true;
        } else if (error) {
            return false;
        }
    } else {
        return false;
    }
}

// function to arrange gresb data on basis of unique topics
export const arrangeGresbData = (data: any[]) => {
    const dataSet = new Set();
    const arrangedData = data.reduce((acc, item) => {
        if (!dataSet.has(item.topic)) {
            dataSet.add(item.topic);
            acc.push({
                topic: item.topic,
                children: data.filter((child) => child.topic === item.topic)
            });
        }
        return acc;
    }, []);
    return arrangedData;
}

// function to segregate the data on basis of response type
export const segregateGresbDataByResponseType = (data: any[]) => {
    const dataToReturn: any[] = [];
    const resSet = Array.from(new Set(data.map((item) => item.response_type)));
    resSet.forEach((res) => {
        const respData = data.filter((item) => item.response_type === res);
        dataToReturn.push({
            responseType: res,
            data: respData
        });
    });
    return dataToReturn;
}

export const arrangeGresbDataForParentsAtSameLevel = (data: any[], allTopicsData: any[]) => {
    let dataToAppend: any[] = [];
    data.forEach((item: any) => {
        const childrenData: any[] = [];
        const disclosureDetailCode = item.disclosure_detail_code;
        const allImmediateChildrenData = allTopicsData.filter((topic) => topic.parent_code === disclosureDetailCode);

        // segregate tabular and non-tabular data
        const tabularData = allImmediateChildrenData.filter((topic) => topic.disclosure_detail_code.includes('TBL'));
        const tableType = tabularData?.find((topic) => topic.table_type !== -1)?.table_type;
        const nonTabularData = allImmediateChildrenData.filter((topic) => !tabularData.includes(topic));
        if (tabularData?.length > 0) {
            childrenData.push({
                responseType: 'Table' + tableType,
                children: tabularData
            });
        }

        // segregate non-tabular data on basis of response type
        const segregatedData = segregateGresbDataByResponseType(nonTabularData);

        segregatedData.forEach((data) => {
            const childDataToAppend = arrangeGresbDataForParentsAtSameLevel(data?.data, allTopicsData);
            childrenData.push({
                responseType: data.responseType,
                children: childDataToAppend
            });
        });

        dataToAppend.push({
            ...item,
            children: childrenData
        });
    });

    return dataToAppend;
}

export const arrangeGresbDataSectionWise = (byUniqueCode?: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const currentGRESBSection = getState().reporting.currentGRESBSection;
    const currentAsset = getState().reporting.selectedGRESBAsset;
    const allSelectedTopics = getState().reporting.allSelectedTemplateTopics?.data;
    const selectedTopics = currentGRESBSection === 'entity' ?
        allSelectedTopics.filter((topic) => topic.dimension !== 'nan') :
        allSelectedTopics.filter((topic) => topic.dimension === 'nan' && currentAsset?.id === topic.asset);
    const gresbData = currentGRESBSection === 'entity' ? getState().reporting.selectedGresbEntityTopics?.data : getState().reporting.selectedGresbAssetTopics?.data;
    const dataToHandle: any[] = selectedTopics.map((topic) => {
        const data: any = gresbData?.find((obj) => obj.id === topic.topic_id);
        const dataToAppend = data ? {
            response_type: data.response_type,
            optional: data.optional,
            parent_code: data.parent_code,
            variable_code: data.variable_code,
            word_limit: data.word_limit,
            max_value: data.max_value,
            min_value: data.min_value,
            score: data.score,
            value: data.value,
            metric_variable_code: data.metric_variable_code,
            data_label_2_variable_code: data.data_label_2_variable_code,
        } : {};
        return {
            ...topic,
            ...dataToAppend
        }
    });
    if (currentGRESBSection === 'asset') {
        return dataToHandle;
    }
    if (byUniqueCode) {
        return dataToHandle;
    }
    const rootTopicsData = dataToHandle.filter((topic) => topic.parent_code === 'nan');
    return arrangeGresbDataForParentsAtSameLevel(rootTopicsData, dataToHandle);
}


export const getGRESBSlugData = () => async (dispatch: AppDispatch) => {
    dispatch(setGresbSlugData({
        data: {},
        status: 'idle'
    }))
    const token = localStorage.getItem('gresb_token');
    if (!token) {
        return;
    }
    const [data, error] = await handleAPICall(GRESB_SLUG_DATA({ token: token }));
    if (data) {
        dispatch(setGresbSlugData({
            data: data?.data,
            status: 'success'
        }))
    } else {
        dispatch(setGresbSlugData({
            data: {},
            status: 'error'
        }))
    }
}


export const getDropDownOptionsForGRESB = (dataRow: any, gresbSlugData: any) => {
    const slug = dataRow.response_type?.split('<')[1]?.split('>')[0]?.toLowerCase();
    if (slug === 'yesno' || slug === 'yes/no') {
        return [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' }
        ]
    }
    // Add target type
    if (slug === 'ncmrstatus' || slug === 'ncmr_status') {
        return [
            { label: 'Standing Investment', value: 'Standing Investment' },
            { label: 'Major Renovation Project', value: 'Major Renovation Project' },
            { label: 'New Construction Project', value: 'New Construction Project' }
        ]
    }
    return gresbSlugData[slug!] ? gresbSlugData[slug] : [];
}

export const getGresbAssetData = () => async (dispatch: AppDispatch) => {
    dispatch(setGresbAssetData({
        data: [],
        status: 'idle'
    }))
    const [data, error] = await handleAPICall(GET_GRESB_ASSETS());
    if (data) {
        dispatch(setGresbAssetData({
            data: data?.data,
            status: 'success'
        }))
    } else {
        console.log(error);
        dispatch(setGresbAssetData({
            data: [],
            status: 'error'
        }))
    }
}

// function to calculate progress for a single asset
export const calculateAssetProgress = (asset_id: number) => (dispatch: AppDispatch, getState: () => RootState) => {
    const allSelectedTopics = getState().reporting.allSelectedTemplateTopics?.data;
    const reportData = getState().reporting.reportData?.data;
    const templateTopics = allSelectedTopics.filter((topic) => topic.asset === asset_id);
    const filledTopics = templateTopics.filter((topic) => Object.keys(reportData).includes(topic.id.toString()));
    const progress = filledTopics?.length / templateTopics?.length * 100;
    return Number(progress.toFixed(2));
}

export const postGresbAssetData = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(POST_GRESB_ASSETS(body));
    if (data) {
        dispatch(getGresbAssetData());
        return data?.data;
    } else {
        BEMessage.error(error?.error || 'Something went wrong, please try again later.');
        return null;
    }
}

export const patchGresbAssetData = (id: number, body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(PATCH_GRESB_ASSETS(id, body));
    if (data) {
        dispatch(getGresbAssetData());
    } else {
        BEMessage.error(error?.error || 'Something went wrong, please try again later.');
    }
}

export const deleteGresbAssetData = (id: number) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(DELETE_GRESB_ASSETS(id));
    if (data) {
        BEMessage.success('Asset deleted successfully.');
        dispatch(getGresbAssetData());
    } else {
        BEMessage.error(error?.error || 'Something went wrong, please try again later.');
    }
}

export const getGresbEntities = () => async (dispatch: AppDispatch) => {
    dispatch(setGresbEntityData({
        data: [],
        status: 'idle'
    }))
    const [data, error] = await handleAPICall(GET_GRESB_ENTITIES());
    if (data) {
        dispatch(setGresbEntityData({
            data: data?.data,
            status: 'success'
        }))
    } else {
        console.log(error);
        dispatch(setGresbEntityData({
            data: [],
            status: 'error'
        }))
    }
}

export const postGresbEntities = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(POST_GRESB_ENTITIES(body));
    if (data) {
        BEMessage.success('Entity saved successfully.');
        dispatch(getGresbEntities());
        return data?.data;
    } else {
        BEMessage.error(error?.error || 'Something went wrong, please try again later.');
        return null;
    }
}

export const patchGresbEntities = (id: number, body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(PATCH_GRESB_ENTITIES(id, body));
    if (data) {
        dispatch(getGresbEntities());
    } else {
        BEMessage.error(error?.error || 'Something went wrong, please try again later.');
    }
}

export const deleteGresbEntities = (id: number) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(DELETE_GRESB_ENTITIES(id));
    if (data) {
        BEMessage.success('Entity deleted successfully.');
        dispatch(getGresbEntities());
    } else {
        BEMessage.error(error?.error || 'Something went wrong, please try again later.');
    }
}

export const populateAssetDataFromPortalToReport = (data: any[], asset_id: number, isFirstTime: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const currentReport = getState().reporting.currentReport;
    const selectedAllAssetsTemplateTopics = await dispatch(arrangeGresbDataSectionWise());

    if (isFirstTime) {
        await Promise.all([...data.map(async (item) => {
            const topicData = selectedAllAssetsTemplateTopics?.find((topic) =>
                topic.asset === asset_id &&
                topic.metric_variable_code === item.metric_varible_code
            );
            const dataToSend = {
                data: item.value
            }
            await dispatch(postReportData({
                ...dataToSend,
                esg_report: currentReport?.id,
                topic: topicData?.id,
            }));
        })]);
    } else {
        await Promise.all([...data.map(async (item) => {
            const topicData = selectedAllAssetsTemplateTopics?.find((topic) =>
                topic.asset === asset_id &&
                topic.metric_variable_code === item.metric_varible_code
            );
            const dataToSend = {
                data: item.value
            }
            await dispatch(fillReportData({
                ...dataToSend,
                esg_report: currentReport?.id,
                topic: topicData?.id,
            }));
        })]);
    }


    // Sample data to populate
    // const data = [
    //     {
    //         metric_varible_code: 'asset_name',
    //         value: 'Asset 1'
    //         data_label_2_variable_code?: 'asset_name'
    //          fy
    //     }
    // ]
}


export const addGresbAssetData = (data: any) => async (dispatch: AppDispatch, getState: () => RootState) => {

    // this has to be mapped manually due to the diff in structure of the data in our db and gresb portal
    const dataToPopulate = [
        {
            metric_varible_code: 'asset_name',
            value: data.name
        },
        {
            metric_varible_code: 'property_type_code',
            value: data.property_type
        },
        {
            metric_varible_code: 'construction_year',
            value: data.construction_year
        },
        {
            metric_varible_code: 'asset_ownership',
            value: data.ownership_percentage
        },
        {
            metric_varible_code: 'asset_size',
            value: data.size
        },
        {
            metric_varible_code: 'country',
            value: data.country
        },
        {
            metric_varible_code: 'city',
            value: data.city
        },
        {
            metric_varible_code: 'state_province',
            value: data.state
        },
        {
            metric_varible_code: 'address',
            value: data.address
        },
        {
            metric_varible_code: 'asset_gav',
            value: data.value
        },
        {
            metric_varible_code: 'optional_information',
            value: data.additional_information
        }
    ];

    const selectedGresbAssetTopics = getState().reporting.selectedGresbAssetTopics?.data;
    const currentReport = getState().reporting.currentReport;
    const resp = await dispatch(postGresbAssetData(data));
    if (resp) {
        const newAssetId = resp?.id;
        dispatch(setSelectedGresbAsset(resp));
        dispatch(setSelectedTemplateTopics({
            data: selectedGresbAssetTopics,
            status: 'success',
        }));
        const dataToSend = selectedGresbAssetTopics.map((item) => {
            return { ...item, topic_id: item.id, template: currentReport?.template, fy: (item.fy && item.fy !== "nan") ? Number(item.fy) : 0, asset: newAssetId }
        });
        let i, j, temparray: any = [], chunk = 100, dataToDispatch: any = [], noError = true;
        for (i = 0, j = dataToSend?.length; i < j; i += chunk) {
            temparray = dataToSend.slice(i, i + chunk);
            dataToDispatch.push(temparray);
        }
        await Promise.all([...dataToDispatch.map(async (item: any) => {
            noError = await dispatch(createTemplateTopics(item)) && noError;
        })]);
        await dispatch(getAllTemplateDrafts());
        const res = await dispatch(getTemplateTopics(currentReport?.template, false));
        if (res)
            dispatch(setAllSelectedTemplateTopics({
                status: 'success',
                data: res
            }))
        await dispatch(populateAssetDataFromPortalToReport(dataToPopulate, newAssetId, true));
        if (currentReport?.stage !== 2) {
            const body = {
                stage: 2
            }
            await dispatch(editReportTemplate(currentReport?.id, body));
        }
    }
}

export const postGresbEntityDataToPortal = (variableCode: string, value: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!isGresbAuthorized()) {
        BEMessage.error('Please authorize GRESB to perform this action.');
        return false;
    }
    const currentEntity = getState().reporting.currentEntity;
    const body = {
        token: localStorage.getItem('gresb_token'),
        real_estate_assessment_id: currentEntity?.real_estate_assessment_id,
        variable_code: variableCode,
        value: value,
        gresb_entity_id: currentEntity?.gresb_entity_id
    }
    const [data, error] = await handleAPICall(POST_GRESB_PORTAL_ENTITY_DATA(body));
    dispatch(getGresbEntities());
    if (data) {
        return true;
    } else {
        BEMessage.error(error?.error || 'Something went wrong, please try again later.');
    }
    return false;
}

export const createGRESBEntity = (name: any) => async (dispatch: AppDispatch) => {
    const body = {
        entity_name: name,
        token: localStorage.getItem('gresb_token')
    }
    const [data, error] = await handleAPICall(GRESB_PORTAL_ENTITY(body));

    if (error) {
        BEMessage.error(error.error || 'Something went wrong, please try again later.');
        return false;
    }

    if (data) {
        const dataToSend = {
            gresb_entity_id: data?.data.id,
            gresb_entity_name: name,
            real_estate_assessment_progress: data?.data.percent_completed
        }
        return dataToSend;
    }
}

export const postGresbPortalAssetData = (entity_id: number, asset_id: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const gresbAssetData = getState().reporting.gresbAssetData?.data;
    const gresbAssetId = gresbAssetData?.find((asset) => asset.id === asset_id)?.gresb_asset_id;
    const body = {
        token: localStorage.getItem('gresb_token')
    }
    let data, error;
    if (gresbAssetId) {
        [data, error] = await handleAPICall(PATCH_GRESB_PORTAL_ASSET_DATA(entity_id, gresbAssetId, body));
    } else {
        [data, error] = await handleAPICall(POST_GRESB_PORTAL_ASSET_DATA(entity_id, asset_id, body));
        const gresb_asset_id = data?.asset_data?.gresb_asset_id;
        if (gresb_asset_id) {
            await dispatch(patchGresbAssetData(asset_id, { gresb_asset_id: gresb_asset_id }));
            await dispatch(populateAssetDataFromPortalToReport(data?.data?.data, asset_id, false));
        }
    }
    if (data) {
        dispatch(setGresbAssetErrors(data?.errors));
        await dispatch(getGresbAssetData());
    } else {
        BEMessage.error(error?.error || 'Something went wrong, please try again later.');
        return;
    }
    BEMessage.success('Data transferred successfully.');
}

export const getGresbPortalEntityData = (report_id: number) => async (dispatch: AppDispatch) => {
    if (!isGresbAuthorized()) {
        BEMessage.error('Please authorize GRESB to perform this action.');
        return;
    }
    const token = localStorage.getItem('gresb_token');
    const body = {
        token: token
    }
    const [data, error] = await handleAPICall(GET_GRESB_PORTAL_ENTITY_DATA(report_id, body));
    if (error) {
        BEMessage.error(error.error || 'Something went wrong, please try again later.');
        return;
    }
    return data.task_ids;
}

// Function to check if the topic belongs to any of the asset details if yes update it
export const updateAssetDetails = (topic_id: number, value: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    console.log('updateAssetDetails', topic_id, value);
    const currentAsset = getState().reporting.selectedGRESBAsset;
    const selectedAllAssetsTemplateTopics = await dispatch(arrangeGresbDataSectionWise());
    const assetDetailsTopics = selectedAllAssetsTemplateTopics?.find((topic) => topic.asset === currentAsset?.id && topic.id === topic_id);
    const metricVariableCode = assetDetailsTopics?.metric_variable_code;

    if (GRESB_ASSET_DETAILS_VARIABLE_CODES.includes(metricVariableCode!)) {
        // Update the asset details in the Report for same metric variable code
        const topicToUpdate = selectedAllAssetsTemplateTopics.filter((topic) => topic.metric_variable_code === metricVariableCode && topic.asset === currentAsset?.id);
        await Promise.all([...topicToUpdate.map(async (topic) => {
            const dataToSend = {
                data: value
            }
            await dispatch(fillReportData({
                ...dataToSend,
                esg_report: getState().reporting.currentReport?.id,
                topic: topic.id,
            }));
        })]);
        const varCode: string = GRESB_ASSET_DETAILS_VARIABLE_CODES_MAPPING[metricVariableCode!];
        const body = {
            [varCode]: value
        }
        await dispatch(patchGresbAssetData(currentAsset?.id || 0, body));
    }
}

export const getGresbEntityEvidences = (report_id: number) => async (dispatch: AppDispatch) => {
    dispatch(setGresbEnityEvidences({
        data: [],
        status: 'idle'
    }))
    const [data, error] = await handleAPICall(GET_GRESB_ENTITY_EVIDENCE(report_id));
    if (data) {
        dispatch(setGresbEnityEvidences({
            data: data?.data,
            status: 'success'
        }))
    } else {
        console.log(error);
        dispatch(setGresbEnityEvidences({
            data: [],
            status: 'error'
        }))
    }
}

export const postGresbEntityEvidence = (body: any) => async (dispatch: AppDispatch) => {
    body = {
        ...body,
        token: localStorage.getItem('gresb_token')
    }
    const [data, error] = await handleAPICall(POST_GRESB_ENTITY_EVIDENCE(body));
    if (data) {
        BEMessage.success('Evidence saved successfully.');
        dispatch(getGresbEntityEvidences(body.report));
    } else {
        BEMessage.error(error?.error || 'Something went wrong, please try again later.');
    }
}

export const patchGresbEntityEvidence = (id: number, body: any) => async (dispatch: AppDispatch) => {
    body = {
        ...body,
        token: localStorage.getItem('gresb_token')
    }
    const [data, error] = await handleAPICall(PATCH_GRESB_ENTITY_EVIDENCE(id, body));
    if (data) {
        BEMessage.success('Evidence saved successfully.');
        dispatch(getGresbEntityEvidences(body.report));
    } else {
        BEMessage.error(error?.error || 'Something went wrong, please try again later.');
    }
}

export const deleteGresbEntityEvidence = (id: number, report_id: number) => async (dispatch: AppDispatch) => {
    const token = localStorage.getItem('gresb_token');
    const [data, error] = await handleAPICall(DELETE_GRESB_ENTITY_EVIDENCE(token, id));
    if (data) {
        BEMessage.success('Evidence deleted successfully.');
        dispatch(getGresbEntityEvidences(report_id));
    } else {
        BEMessage.error(error?.error || 'Something went wrong, please try again later.');
    }
}

// function to get nested data status of GRESB report accordion
export const entityChildrenAccordianStatus = (children: any[], reportData: any) => {
    let status: boolean = true;
    if (children?.length === 0) {
        return status;
    }
    console.log('children', children);
    children?.forEach((child) => {
        // segregate the children on basis of unique topics
        let uniqueTopicSet = new Set();
        child?.children?.forEach((topic: any) => {
            if (!uniqueTopicSet.has(topic.topic)) {
                uniqueTopicSet.add(topic.topic);
            }
        });
        const dataByTopics = Array.from(uniqueTopicSet).map((topic: any) => {
            return child.children.filter((item: any) => item.topic === topic);
        });
        dataByTopics.forEach(async (data) => {
            let filledChildren = [];
            if (child.responseType === 'Checkbox' || child.responseType === 'Radio') {
                filledChildren = data.filter((topic: any) => Object.keys(reportData).includes(topic.id.toString()) && reportData[topic.id!][0]?.data !== 'nan'&& reportData[topic.id!][0]?.data != 0);
                console.log('filledChildren', filledChildren);
                if (filledChildren?.length === 0) {
                    status = false;
                }
            } else if (child.responseType.includes('Evidence')) {
                status = true && status;
            } else {
                filledChildren = data.filter((topic: any) => Object.keys(reportData).includes(topic.id.toString()) && reportData[topic.id!][0]?.data !== 'nan');
                if (filledChildren?.length !== data?.length) {
                    status = false;
                }
            }
            if (status) {
                filledChildren.forEach((filledChild: any) => {
                    status = entityChildrenAccordianStatus(filledChild.children, reportData) && status;
                });
            }
        });
    });
    return status;
}


export const entityAccordianStatusByUniqueCode = (uniqueCode: string, arrangedEntityTopics: any[], reportData: any) => {
    const entityTopics = arrangedEntityTopics.filter((topic) => topic.unique_code === uniqueCode);
    if (entityTopics[0]?.response_type === 'Dummy Label') {
        return true;
    }
    // For the top level find the children and check if all the children are filled
    const focusedTopic = entityTopics?.find((topic) => Object.keys(reportData).includes(topic.id.toString()) && reportData[topic.id!][0]?.data !== 'nan');
    if (focusedTopic) {
        const status = entityChildrenAccordianStatus(focusedTopic.children, reportData);
        return status;
    }
    return false;
}

export const allEntityAccordianStatus = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const arrangedEntityTopics = await dispatch(arrangeGresbDataSectionWise());
    const reportData = getState().reporting.reportData?.data;
    const uniqueCodes = Array.from(new Set(arrangedEntityTopics.map((topic) => topic.unique_code)));
    let gresbUniqueCodeWiseStatus: any = {};
    uniqueCodes.forEach((uniqueCode) => {
        const status = entityAccordianStatusByUniqueCode(uniqueCode, arrangedEntityTopics, reportData);
        gresbUniqueCodeWiseStatus = {
            ...gresbUniqueCodeWiseStatus,
            [uniqueCode]: status
        };
    });
    dispatch(setGresbEntityAccordionStatus({
        data: gresbUniqueCodeWiseStatus,
        status: 'success'
    }));
}

export const getGresbReportProgress = (report_id: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const entities = getState().reporting.gresbEntityData?.data;
    const entity = entities?.find((entity) => entity.report === report_id);
    const entityProgress = entity?.real_estate_assessment_progress || 0;
    const assets = getState().reporting.gresbAssetData?.data;
    const reportAssets = assets.filter((asset) => asset.report === report_id);
    let assetProgress = 0;
    reportAssets.forEach((asset) => {
        assetProgress += dispatch(calculateAssetProgress(asset.id));
    });
    const totalProgress = (entityProgress + assetProgress) / (reportAssets?.length + 1);
    return totalProgress;
}
